import React, { useRef } from "react";
import ActionSheetMenu from "./ActionSheetMenu";
import { Menu } from "antd";
import { List, Toast } from "antd-mobile";
import { v4 as uuidv4 } from "uuid";
import {
   CompassOutlined,
   CopyOutlined,
   CloseCircleFilled,
} from "@ant-design/icons";
import "./ShareActionSheet.css";

const Item = List.Item;

export default function ShareActionSheet({
   pageTitle,
   url,
   visible,
   onCloseClick,
}) {
   const openLinkRef = useRef();

   async function copyLinkText(linkUrl) {
      onCloseClick();
      if (navigator.clipboard) {
         await navigator.clipboard.writeText(linkUrl);
         Toast.show({ content: "Link copied", duration: 3000, icon: "success" });
      } else {
         Toast.show({
            content: "Copy is not permitted",
            duration: 3000,
            icon: "success",
         });
      }
   }

   return (
      <ActionSheetMenu
         title=""
         className="ShareActionSheet"
         visible={visible}
         onCloseClick={onCloseClick}
      >
         <a
            ref={openLinkRef}
            href={url}
            target="_blank"
            rel="noreferrer"
            className="openLink"
            style={{ display: "none" }}
         >
            {url}
         </a>
         <List>
            <Item
               className="AppListItem"
               key={uuidv4()}
               align="middle"
               arrow={null}
               clickable={true}
               extra={<CloseCircleFilled />}
               prefix={
                  <img
                     src={require(`../icons/shield_icon_48x48.png`)}
                     className="ShieldIcon"
                     alt="Shield"
                  />
               }
               multipleLine={true}
               onClick={(e) => onCloseClick(e)}
               description={window.location.origin}
            >
               <span className="pageTitle">{pageTitle}</span>
            </Item>
         </List>
         <Menu
            items={[
               {
                  key: uuidv4(),
                  icon: <CopyOutlined className="CloseIcon" />,
                  onClick: (e) => copyLinkText(openLinkRef.current.innerHTML),
                  disabled: !navigator.share && !navigator.clipboard,
                  label: "Copy",
                  title: url,
               },
               {
                  key: uuidv4(),
                  icon: <CompassOutlined />,
                  onClick: (e) => {
                     openLinkRef.current.click();
                  },
                  label: "Open in Browser",
               },
            ]}
         >
            {/*
            <Menu.Item
               key={uuidv4()}
               icon={<CopyOutlined className="CloseIcon" />}
               onClick={(e) => copyLinkText(openLinkRef.current.innerHTML)}
               disabled={!navigator.share && !navigator.clipboard}
            >
               Copy
            </Menu.Item>

            <a
               ref={openLinkRef}
               href={url}
               _target="_blank"
               className="openLink"
               style={{ display: "none" }}
            >
               {url}
            </a>

            <Menu.Item
               key={uuidv4()}
               icon={<CompassOutlined />}
               onClick={(e) => {
                  openLinkRef.current.click();
               }}
            >
               Open in Browser
            </Menu.Item>
            */}
         </Menu>
      </ActionSheetMenu>
   );
}
