import React from "react";
import CloudBackground from "../images/clouds.png";

import "./CategoryFrufru.css";

function CategoryFrufru({ inset = false, category, onCategoryClick }) {
   return (
      <div
         className="CategoryFrufru"
         onClick={(e) => onCategoryClick(e, category.slug)}
      >
         {category.imageUrlArray && category.imageUrlArray.length > 0 ? (
            <img
               key={category.seq}
               src={category.imageUrlArray[0].url}
               alt={category.slug}
            ></img>
         ) : (
            <img
               key={category.seq}
               src={CloudBackground}
               alt={category.slug}
            ></img>
         )}
         <h3>{category.name}</h3>
      </div>
   );
}

export default CategoryFrufru;
