import React, { useState } from "react";
import { getDB } from "../utils/UseLocalDataStore";
import { useLoaderData, useNavigate } from "react-router-dom";
import ArticleCallbacks from "../utils/ArticleCallbacks";
import CategoryCallbacks from "../utils/CategoryCallbacks";
import {
   UnorderedListOutlined,
   CopyOutlined,
   PauseCircleFilled,
   StepForwardOutlined,
   StepBackwardOutlined,
   PlayCircleFilled,
} from "@ant-design/icons";
import ReactPlayer from "react-player";
import DancingBarsIcon from "../images/dancing-bars4.gif";
import FrozenBarsIcon from "../images/dancing-bars5.gif";
import { Toast } from "antd-mobile";

import "./ArticleAudioPage.css";

/** loader function for this page */
export async function loader({ params }) {
   console.log(`ArticleAudioPage loader() called, slug=${params.slug}`);
   const db = getDB();

   const article = await ArticleCallbacks.loadArticleDataPromise(
      params.slug,
      db
   );

   const categoryArticleList =
      await CategoryCallbacks.loadArticleArrayForCategory(article.category, db);

   const coverArtArticle =
      categoryArticleList != null && categoryArticleList.length > 0
         ? categoryArticleList[0]
         : null;

   const coverArtImage =
      coverArtArticle && coverArtArticle.imageSeqArray.length > 0
         ? coverArtArticle.imageSeqArray[0]
         : null;

   const trackArray =
      article.audioUrlArray && article.audioUrlArray.length > 0 ? article.audioUrlArray.toString().split(",").map((item)=>{
        return { url: item, label: item.substring(item.lastIndexOf("/")+1)}
      }) : null;
      console.log("DEBUG: split trackArray=", trackArray);

   return [article, trackArray, coverArtImage];
}

export default function ArticleAudioPage() {
   const [article, trackArray, coverArtImage] = useLoaderData();
   const [playIndex, setPlayIndex] = useState(0);
   const [playing, setPlaying] = useState(false);
   const [showTracks, setShowTracks] = useState(false);

   function toggleShowTracks() {
      setShowTracks(!showTracks);
   }

   function createImage(seq, url, filename, className, onClick) {
      return (
         <img
            key={seq}
            src={url}
            alt={filename}
            className={className}
            onClick={onClick}
         ></img>
      );
   }

   function createAlbumCoverImage() {
      return coverArtImage ? (
         createImage(
            coverArtImage.seq,
            coverArtImage.base64data,
            coverArtImage.filename,
            "album-cover"
         )
      ) : (
         <span className="default-cover">&#x1F3A7;</span>
      );
   }

   function jumpToTrack(index) {
      //console.log(`DEBUG: jumpToTrack index=${index}`);
      if (index >= 0 && index < trackArray.length) {
         setPlayIndex(index);
         setPlaying(true);
      }
   }

   function createTrackListing() {
      return showTracks && trackArray ? (
         <ol>
            {trackArray.map((url, index) => {
               return (
                  <li key={index}>
                     {index === playIndex ? (
                        <img
                           src={playing ? DancingBarsIcon : FrozenBarsIcon}
                           alt="Track is Playing"
                           className="dancing-bars-icon"
                        />
                     ) : (
                        <span className="dancing-bars-icon">&nbsp;</span>
                     )}
                     <button
                        onClick={() => {
                           jumpToTrack(index);
                        }}
                     >
                        Part {index + 1} - {trackArray[index].label}
                     </button>
                  </li>
               );
            })}
         </ol>
      ) : (
         ""
      );
   }

   function prevTrack() {
      if (playIndex > 0) {
         setPlayIndex(playIndex - 1);
      }
   }

   function nextTrack() {
      if (playIndex < trackArray.length) {
         setPlayIndex(playIndex + 1);
      } else {
         setPlaying(false);
      }
   }

   function play() {
      setPlaying(true);
   }

   function pause() {
      setPlaying(false);
   }

   async function copyUrlToClipboard() {
      const url = trackArray[playIndex].url;

      if (navigator.clipboard) {
         await navigator.clipboard.writeText(url);
         Toast.show({
            content: "Track Link copied",
            duration: 3000,
            icon: "success",
         });
      } else {
         Toast.show({
            content:
               "Your device does not allow access to Copy to the Clipboard",
            duration: 4000,
            icon: "fail",
         });
      }
   }

   function createPlayerControlSpace() {
      if (trackArray && trackArray.length > 0) {
         return (
            <div className="control-space">
               <h3>{article.name}</h3>
               {!showTracks ? <p>{trackArray[playIndex].label}</p> : null}
               {createTrackListing()}

               <div className="transportControls">
                  <UnorderedListOutlined
                     title="Track Listing"
                     aria-describedby="Track Listing"
                     onClick={toggleShowTracks}
                  />

                  <button disabled={playIndex === 0} onClick={prevTrack}>
                     <StepBackwardOutlined
                        title="Previous Track"
                        aria-describedby="Previous Track"
                     />
                  </button>

                  {!playing ? (
                     <PlayCircleFilled
                        title="Play"
                        aria-describedby="Play"
                        onClick={play}
                     />
                  ) : (
                     ""
                  )}

                  {playing ? (
                     <PauseCircleFilled
                        title="Pause"
                        aria-describedby="Pause"
                        onClick={pause}
                     />
                  ) : (
                     ""
                  )}

                  <button
                     disabled={playIndex === trackArray.length - 1}
                     onClick={nextTrack}
                  >
                     <StepForwardOutlined
                        title="Next Track"
                        aria-describedby="Next Track"
                     />
                  </button>

                  <button
                     disabled={!navigator.clipboard}
                     onClick={copyUrlToClipboard}
                  >
                     <CopyOutlined
                        title="Copy audio link to clipboard"
                        aria-describedby="Copy audio link to clipboard"
                     />
                  </button>
               </div>

               <ReactPlayer
                  className="audio-player"
                  width="90%"
                  height="100%"
                  light={false}
                  playing={playing}
                  volume={0.3}
                  controls={true}
                  url={trackArray[playIndex].url}
                  onEnded={nextTrack}
               />
            </div>
         );
      } else {
         return <span>There are no audio file links for this article</span>;
      }
   }

   return (
      <div className="ArticleAudioPage">
         {createAlbumCoverImage()}
         {createPlayerControlSpace()}
      </div>
   );
}
