import { Modal } from "antd-mobile";
import { setActiveToolbarTab } from "../features/mainPageSlice";
import { replaceSettings } from "../features/settingsSlice";
import { CardType } from "../models/CardType";
import { PageType } from "../models/PageType";
import { ToolBarButtonType } from "../models/ToolBarButtonType";
import { ExclamationCircleFill } from "antd-mobile-icons";
import {
   appendFavorite,
   removeFavorite,
   CardExplorerInitialState,
   CardIdListInitialState,
   RecentSearchArrayInitialState,
} from "../features/settingsSlice";
import { callSaveSettingAPI } from "../utils/BackendAPI";
import DbRefreshUtils from "../utils/DbRefreshUtils";
import ArticleCallbacks from "./ArticleCallbacks";

const alert = Modal.alert;
const confirm = Modal.confirm;

export const GUEST_USER_ID = 0;

/** This function does NOT save */
export function makeNewSettingsWithFavorites(
   userId,
   newFavoritesList,
   settings
) {
   return Object.assign({}, settings, {
      userId: userId,
      favorites: newFavoritesList,
   });
}

/** This function does NOT save */
export function makeNewSettingsWithRecentSearch(
   newRecentSearchList,
   settings
) {
   return Object.assign({}, settings, {
      recentSearchArray: newRecentSearchList,
   });
}

export function confirmRemoveItem(itemName, onConfirm) {
   confirm({
      header: (
         <ExclamationCircleFill
            style={{
               fontSize: 64,
               color: "var(--adm-color-warning)",
            }}
         />
      ),
      title: "Remove",
      content: `Are you sure you want to delete ${itemName}?`,
      confirmText: "Yes Remove",
      cancelText: "",
      showCloseButton: true,
      onConfirm: onConfirm(),
   });
}

export function showRemoveFavoritesOfflineAlert() {
   alert({
      header: (
         <ExclamationCircleFill
            style={{
               fontSize: 64,
               color: "var(--adm-color-warning)",
            }}
         />
      ),
      title: "Add to Favorites",
      content: `You are currently offline. Please Remove from  Favorites once you are online again.`,
      confirmText: "OK",
      cancelText: "",
   });
}

export function showAddFavoriteOfflineAlert() {
   alert({
      header: (
         <ExclamationCircleFill
            style={{
               fontSize: 64,
               color: "var(--adm-color-warning)",
            }}
         />
      ),
      title: "Add to Favorites",
      content: `You are currently offline. Please Add to Favorites once you are online again.`,
      confirmText: "OK",
      cancelText: "",
   });
}

export function showEditFavoritesOfflineAlert() {
   alert({
      header: (
         <ExclamationCircleFill
            style={{
               fontSize: 64,
               color: "var(--adm-color-warning)",
            }}
         />
      ),
      title: "Edit Favorites",
      content: `You are currently offline. Please edit favorites once you are online again.`,
      confirmText: "OK",
      cancelText: "",
   });
}

export function callUpdateSettingsOfflineAlert() {
   alert({
      header: (
         <ExclamationCircleFill
            style={{
               fontSize: 64,
               color: "var(--adm-color-warning)",
            }}
         />
      ),
      title: "Update Settings",
      content: `You are not logged in. Login or choose Guest to save locally..`,
      confirmText: "OK",
      cancelText: "",
   });
}

export function appendFavoriteFull(
   slug,
   settings,
   currentUser,
   db,
   dispatch,
   online
) {
   dispatch(appendFavorite(slug));

   const newFavoritesSlugList = [...settings.favorites, slug];
   const newSettings = makeNewSettingsWithFavorites(
      (currentUser && currentUser.loggedIn) ? currentUser.userId : GUEST_USER_ID,
      newFavoritesSlugList,
      settings
   );

   if (currentUser && currentUser.loggedIn) {
      if (online) {
         //call API to save, and then resync with server
         callSaveSettingAPI(newSettings).then(() => {
            DbRefreshUtils.resyncSettingTable(db, dispatch);
         });
      } else {
         showAddFavoriteOfflineAlert();
      }
   } else {
      /* GUEST */
      updateLocalStoreSettings(newSettings, db, dispatch, undefined);
   }
}

export function removeFavoriteFull(
   slug,
   settings,
   currentUser,
   db,
   dispatch,
   online
) {
   // make a new copy of the settings
   dispatch(removeFavorite(slug));
   const newFavoritesSlugList = settings.favorites.filter((item) => item !== slug);
   let newSettings = makeNewSettingsWithFavorites(
      (currentUser && currentUser.loggedIn) ? currentUser.userId : GUEST_USER_ID,
      newFavoritesSlugList,
      settings
   );

   if (currentUser && currentUser.loggedIn) {
      if (online) {
         callSaveSettingAPI(newSettings).then(() => {
            DbRefreshUtils.resyncSettingTable(db, dispatch);
         });
      } else {
         showRemoveFavoritesOfflineAlert();
      }
   } else {
      //remove from state / local storage
      updateLocalStoreSettings(newSettings, db, dispatch, undefined);
   }
}

export function toggleFavorite(
   slug,
   settings,
   currentUser,
   db,
   dispatch,
   online
) {
   if (settings.favorites.includes(slug) === false) {
      appendFavoriteFull(slug, settings, currentUser, db, dispatch, online);
   } else {
      removeFavoriteFull(slug, settings, currentUser, db, dispatch, online);
   }
}

export function updateLocalStoreSettings(settings, db, dispatch, navigate) {
   //save to local storage only (no API for guest)
   db.insertOrUpdateOneSetting(settings)
      .then((r) => {
         //console.log("LocalDataStore says success", r, settings);
         // loadFavoritesPage(settings, db, dispatch, navigate);
      })
      .catch((e) => {
         console.log("LocalDataStore reported an error from insert:", e);
      });
}

/* returns promise */
export function loadFavoritesData(settings, db) {
   return new Promise(async (resolve, reject) => {
      if (settings.favorites.length > 0) {
         // we need to maintain the order sequence of the contents of settings.favorites

         console.log("DEBUG: settings.favorites=",settings.favorites);

         let lookupTable = {};

         let articleArray = await db.queryArticleHeadings(
            settings.favorites,
            undefined,
            true,
            true
         );

         console.log("DEBUG: articleArray=",articleArray);

         articleArray.forEach((a) => {
            a.type = "A";
            a.id = a._id;
            delete a._id;
            lookupTable[a.slug] = a;
         });

         let categoryArray = await db.getCategoriesBySlugArray(
            settings.favorites
         );

         console.log("DEBUG: categoryArray=",categoryArray);

         categoryArray.forEach((c) => {
            c.type = "C";
            c.id = c._id;
            delete c._id;
            lookupTable[c.slug] = c;
         });

         let favoritesContents = [];

         settings.favorites.forEach((slug) => {
            favoritesContents.push(lookupTable[slug]);
         });

         console.log("DEBUG: favoritesContents=", favoritesContents);

         let promises = [];

         articleArray.forEach((i) => {
            promises.push(ArticleCallbacks.fetchFeaturedImageData(i, db));
         });

         Promise.all(promises)
            .then((result) => {
               // dispatch(
               //    setFavoritesList(favoritesContents.filter((item) => item))
               // );

               //FIXME ~~~ is this .filter() necessary?
               
               const favoritesList = favoritesContents.filter((item) => item);

               console.log("DEBUG: favoritesList=", favoritesList);

               resolve(favoritesList);
            })
            .catch((error) => {
               console.log("Error fetching image information: ", error);
               reject(error);
            });
      } else {
         // dispatch(setFavoritesList([]));
         resolve();
      }
   });
}

export function loadFavoritesPage(settings, db, dispatch, navigate) {
   loadFavoritesData(settings, db, dispatch).then(() => {
      dispatch(setActiveToolbarTab(ToolBarButtonType.FAVORITES));
      if (navigate) {
         navigate("/favorites");
      }
   });
}

/** parameters are mandatory */
export function saveSettingsRemoteOrLocal(online, settings, currentUser, db) {
   if (currentUser && currentUser.loggedIn) {
      if (online) {
         //call API to save, and then resync with server
         callSaveSettingAPI(settings).then(() => {
            DbRefreshUtils.processDbSync(db);
         });
      } else {
         showAddFavoriteOfflineAlert();
      }
   } else {
      callUpdateSettingsOfflineAlert();
   }
}

export function replaceLocalSettings(settings, db, dispatch) {
   dispatch(replaceSettings(settings));
   updateLocalStoreSettings(settings, db, dispatch, undefined);
}

export function isCardEnabled(settings, cardId) {
   let enabled = true;

   for (let i = 0; i < settings.cardExplorer.cardIdList.length; i++) {
      if (settings.cardExplorer.cardIdList[i].id === cardId) {
         enabled = settings.cardExplorer.cardIdList[i].on;
         break;
      }
   }
   return enabled;
}

export function applyDefaultsForMissingSettings(settings) {
   if (settings.favorites) {
      //fixes missing / empty records
      settings.favorites = settings.favorites.filter((item) => item);
   }

   if (settings.startPage === undefined) {
      settings.startPage = PageType.EXPLORER_PAGE;
   }

   if (settings.fasterStartup === undefined) {
      settings.fasterStartup = false;
   }

   if (settings.simplifiedUI === undefined) {
      settings.simplifiedUI = false;
   }

   if (!settings.cardExplorer) {
      settings.cardExplorer = CardExplorerInitialState;
   }

   if (!settings.cardExplorer.cardIdList) {
      settings.cardExplorer.cardIdList = CardIdListInitialState;
   } else {
      //insert after LOOKING_UP_CALENDAR_CARD
      let idx = indexOf(
         CardType.LOOKING_UP_CALENDAR_CARD,
         settings.cardExplorer.cardIdList
      );

      if (
         !containsObject(
            CardType.SOLAR_CYCLE_CALENDAR_CARD,
            settings.cardExplorer.cardIdList
         )
      ) {
         settings.cardExplorer.cardIdList.splice(idx + 1, 0, {
            id: CardType.SOLAR_CYCLE_CALENDAR_CARD,
            on: true,
         });
      }

      // rename
      idx = indexOf("Article of the Day", settings.cardExplorer.cardIdList);
      if (idx !== -1 && idx <= settings.cardExplorer.cardIdList.length) {
         settings.cardExplorer.cardIdList[idx].id =
            CardType.ARTICLE_OF_MOMENT_CARD;
      }

      // rename
      idx = indexOf("Image of the Day", settings.cardExplorer.cardIdList);
      if (idx !== -1 && idx <= settings.cardExplorer.cardIdList.length) {
         settings.cardExplorer.cardIdList[idx].id =
            CardType.IMAGE_OF_MOMENT_CARD;
      }
   }

   if (!settings.recentSearchArray) {
      settings.recentSearchArray = RecentSearchArrayInitialState;
   }
}

function containsObject(id, list) {
   var i;
   for (i = 0; i < list.length; i++) {
      if (list[i].id === id) {
         return true;
      }
   }

   return false;
}

function indexOf(id, list) {
   var i;
   for (i = 0; i < list.length; i++) {
      if (list[i].id === id) {
         return i;
      }
   }

   return undefined;
}
