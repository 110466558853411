import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { CardType } from "../models/CardType";
import PodcastLogoImage from "../images/Blue-Flame-Morning-Tea-scaled.jpg";
import { isIPhone } from "../utils/BrowserUtils";
import { Toast } from "antd-mobile";
import { ReactComponent as PodcastOutlined } from "../icons/podcast-icon.svg";
import Card from "./Card";
import "./MorningTeaCard.css";

export default function MorningTeaCard() {
   const online = useSelector((state) => state.mainPage.online);
   const navigate = useNavigate();
   const smoresRef = useRef();

   const ApplePodcastShowLink =
      "https://podcasts.apple.com/us/podcast/tuegather-podcast/id1416198527?mt=2&uo=4";
   const WebsitePodcastShowLink = "https://morningtea.blueflame47.com";

   function failToast() {
      Toast.show({
         content: "You must be online to perform this action",
         duration: 5000,
         icon: "fail",
      });
   }

   function onMenuClick() {
      console.log("onMenuClick()");
      navigate("/settings/cardexplorer");
   }

   function onClickNavLink() {
      console.log("onClickNavLink()");

      if (online) {
         smoresRef.current.click();
      } else {
         failToast();
      }
   }

   return (
      <Card
         key="MorningTeaCard"
         className="MorningTeaCard"
         headerIcon={<PodcastOutlined className="headerIcon" />}
         titleText={CardType.MORNING_TEA_CARD}
         onMenuIconClick={onMenuClick}
         onMoreClick={onClickNavLink}
      >
         <>
            <div className="cardContent">
               {isIPhone() ? (
                  <a
                     className="imageButton"
                     target="_new"
                     href={ApplePodcastShowLink}
                  >
                     <img src={PodcastLogoImage} alt={"Morning Tea Podcast"} />
                  </a>
               ) : online ? (
                  <a
                     className="imageButton"
                     target="_new"
                     href={WebsitePodcastShowLink}
                  >
                     <img src={PodcastLogoImage} alt={"Morning Tea Podcast"} />
                  </a>
               ) : (
                  <button onClick={(e) => failToast()} className="imageButton">
                     <img src={PodcastLogoImage} alt={"Morning Tea Podcast"} />
                  </button>
               )}
            </div>
            <a
               ref={smoresRef}
               target="_new"
               className="hiddenLink"
               href={WebsitePodcastShowLink}
            >Hidden link</a>
         </>
      </Card>
   );
}
