import { configureStore } from "@reduxjs/toolkit";
import loggerMiddleware from "../middleware/logger";

import accountReducer from "../features/accountSlice";
import cardExplorerPageReducer from "../features/cardExplorerPageSlice";
import mainPageReducer from "../features/mainPageSlice";
import settingsReducer from "../features/settingsSlice";
import solarCycleCalendarReducer from "../features/solarCycleCalendarSlice";

const store = configureStore({
   reducer: {
      account: accountReducer,
      cardExplorerPage: cardExplorerPageReducer,
      mainPage: mainPageReducer,
      settings: settingsReducer,
      solarCycleCalendar: solarCycleCalendarReducer,
   },
   middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(loggerMiddleware),
});

export default store;