import React, { useRef, useEffect, useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLoaderData, Form } from "react-router-dom";
import { Button, ImageUploader, List, Modal, Popover } from "antd-mobile";
import {
   ExclamationCircleFill,
   ExclamationCircleOutline,
} from "antd-mobile-icons";
import { CloseCircleFill } from "antd-mobile-icons";
import ArticleListItem from "../components/ArticleListItem";
import { EditorMode } from "../components/EditorMode";
import ArticleUtils from "../utils/ArticleUtils";
import WikiTextConverter from "../utils/WikiTextConverter";
import DbRefreshUtils from "../utils/DbRefreshUtils";
import ArticleCallbacks from "../utils/ArticleCallbacks";
import {
   callSaveArticleAPI,
   callDeleteArticleAPI,
   callDeleteRelationsAPI,
   callDeleteRelationListAPI,
   callSaveRelationsAPI,
   callDeleteImagesAPI,
   callSaveImagesAPI,
} from "../utils/BackendAPI";
import { Toast } from "antd-mobile";
import { useLocalDataStore, getDB } from "../utils/UseLocalDataStore";
//import "antd-mobile/dist/antd-mobile.css";
import "../components/Form.css";
import "./AdminArticlePage.css";

/* default data loader for this page */
export async function loader({ request, params }) {
   let slug = params.slug ? params.slug : "";
   const editorMode = request.url.endsWith("/new")
      ? EditorMode.ADD
      : EditorMode.EDIT;

   // console.log(
   //    `AdminArticlePage: loader() called slug=${slug}, editorMode=${editorMode}`
   // );

   let audioString = "";
   let videoString = "";
   let imageUrlArray = [];
   let article = null;
   let relationArray = [];

   const db = getDB();
   if (editorMode === EditorMode.EDIT && slug) {
      const results = await ArticleCallbacks.loadFullArticleDataPromise(
         slug,
         db
      );

      article = results.article;
      relationArray = results.relationArray;

      if (article.imageSeqArray != null && article.imageSeqArray.length > 0) {
         article.imageSeqArray.forEach((i) => {
            imageUrlArray.push({
               seq: i.seq,
               url: i.base64data,
               orientation: 1,
               filename: i.filename,
               file: null,
            });
         });
      }

      article.audioUrlArray.forEach((u) => {
         audioString = audioString + "\n" + u;
      });

      article.videoUrlArray.forEach((u) => {
         videoString = videoString + "\n" + u;
      });

      audioString = audioString.trim();
      videoString = videoString.trim();
   } else {
      // add defaults
      article = {
         seq: 0,
         slug: "",
         name: "",
         text: "",
         alias: "",
         summary: "",
         category: slug ? slug : "",
         previousSlug: undefined,
         orderby: undefined,
      };

//      console.log(`DEBUG: article.category=${article.category}`);
   }

   return [
      editorMode,
      article,
      relationArray,
      imageUrlArray,
      audioString,
      videoString,
   ];
}

export default function AdminArticlePage() {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const db = useLocalDataStore();

   const [
      editorMode,
      articleDefaults,
      relationArrayDefaults,
      imageUrlArrayDefaults,
      audioStringDefaults,
      videoStringDefaults,
   ] = useLoaderData();

   const seq = articleDefaults.seq;
   const previousSlug = articleDefaults.slug;

   const [slugError, setSlugError] = useState("");
   const [nameError, setNameError] = useState("");
   const [categoryError, setCategoryError] = useState("");
   const [aliasError, setAliasError] = useState("");
   const [summaryError, setSummaryError] = useState("");
   const [textError, setTextError] = useState("");

   const [slugValue, setSlugValue] = useState(articleDefaults.slug);
   const [nameValue, setNameValue] = useState(articleDefaults.name);
   const [textValue, setTextValue] = useState(articleDefaults.text);
   const [aliasValue, setAliasValue] = useState(articleDefaults.alias);

   const [audioValue, setAudioValue] = useState(audioStringDefaults);
   const [videoValue, setVideoValue] = useState(videoStringDefaults);

   const [categorySlugValue, setCategorySlugValue] = useState(
      articleDefaults.category
   );
   const [imageUrlArray, setImageUrlArray] = useState(imageUrlArrayDefaults);
   const [summaryValue, setSummaryValue] = useState(articleDefaults.summary);
   const [relationArray, setRelationArray] = useState(relationArrayDefaults);

   const [categorySearchArray, setCategorySearchArray] = useState([]);
   const [relatedToSearchArray, setRelatedToSearchArray] = useState([]);
   const [relatedToSearchValue, setRelatedToSearchValue] = useState([]);

   const [nameFieldCursorPos, setNameFieldCursorPos] = useState(undefined);
   const [slugFieldCursorPos, setSlugFieldCursorPos] = useState(undefined);
   const [aliasFieldCursorPos, setAliasFieldCursorPos] = useState(undefined);
   const [summaryFieldCursorPos, setSummaryFieldCursorPos] =
      useState(undefined);
   const [textFieldCursorPos, setTextFieldCursorPos] = useState(undefined);
   const [categoryFieldCursorPos, setCategoryFieldCursorPos] =
      useState(undefined);
   const [relatedToFieldCursorPos, setRelatedToFieldCursorPos] =
      useState(undefined);
   const [audioFieldCursorPos, setAudioFieldCursorPos] = useState(undefined);
   const [videoFieldCursorPos, setVideoFieldCursorPos] = useState(undefined);

   const Item = List.Item;
   const confirm = Modal.confirm;

   const SLUG_MIN_LENGTH = 3;
   const NAME_MIN_LENGTH = 3;
   const ALIAS_MIN_LENGTH = 2;
   const SUMMARY_MIN_LENGTH = 5;
   const CATEGORY_MIN_LENGTH = SLUG_MIN_LENGTH;
   const TEXT_MIN_LENGTH = 0;

   const pageRef = useRef(null);

   const useFocus = () => {
      const htmlElRef = useRef(null);
      const setFocus = (cursorPos) => {
         htmlElRef.current && htmlElRef.current.focus();
         if (cursorPos) {
            /*nativeElement*/
            htmlElRef.current.setSelectionRange(cursorPos, cursorPos);
         }
      };
      return [htmlElRef, setFocus];
   };

   const [nameFocusRef, setNameFocusRef] = useFocus();
   const [slugFocusRef, setSlugFocusRef] = useFocus();
   const [aliasFocusRef, setAliasFocusRef] = useFocus();
   const [summaryFocusRef, setSummaryFocusRef] = useFocus();
   const [textFocusRef, setTextFocusRef] = useFocus();
   const [categoryFocusRef, setCategoryFocusRef] = useFocus();
   const [relatedToFocusRef, setRelatedToFocusRef] = useFocus();
   const [audioFocusRef, setAudioFocusRef] = useFocus();
   const [videoFocusRef, setVideoFocusRef] = useFocus();

   /* this sets the initial error state upon load */
   useEffect(() => {
      isNameValidAsync(nameValue, seq, db).then((err) => {
         setNameError(err);
      });
   }, [db, seq, nameValue]);

   /* this sets the initial error state upon load */
   useEffect(() => {
      isSlugValidAsync(slugValue, seq, db).then((err) => {
         setSlugError(err);
      });
   }, [db, seq, slugValue]);

   /* this sets the initial error state upon load */
   useEffect(() => {
      isAliasValidAsync(aliasValue, nameValue, db).then((err) => {
         setAliasError(err);
      });
   }, [db, aliasValue, nameValue]);

   /* this sets the initial error state upon load */
   useEffect(() => {
      const err = isCategoryValid(categorySlugValue);
      if (err.length > 0) {
         setCategoryError(err);
      }
   }, [db, seq, categorySlugValue]);

   /* this sets the initial error state upon load */
   useEffect(() => {
      const err = isSummaryValid(summaryValue);
      if (err.length > 0) {
         setSummaryError(err);
      }
   }, [db, seq, summaryValue]);

   useLayoutEffect(() => {
      if (nameFieldCursorPos) {
         setNameFocusRef(nameFieldCursorPos);
      }
   }, [nameFieldCursorPos, setNameFocusRef]);

   useLayoutEffect(() => {
      if (slugFieldCursorPos) {
         setSlugFocusRef(slugFieldCursorPos);
      }
   }, [slugFieldCursorPos, setSlugFocusRef]);

   useLayoutEffect(() => {
      if (aliasFieldCursorPos) {
         setAliasFocusRef(aliasFieldCursorPos);
      }
   }, [aliasFieldCursorPos, setAliasFocusRef]);

   useLayoutEffect(() => {
      if (summaryFieldCursorPos) {
         setSummaryFocusRef(summaryFieldCursorPos);
      }
   }, [summaryFieldCursorPos, setSummaryFocusRef]);

   useLayoutEffect(() => {
      if (textFieldCursorPos) {
         setTextFocusRef(textFieldCursorPos);
      }
   }, [textFieldCursorPos, setTextFocusRef]);

   useLayoutEffect(() => {
      if (categoryFieldCursorPos) {
         setCategoryFocusRef(categoryFieldCursorPos);
      }
   }, [categoryFieldCursorPos, setCategoryFocusRef]);

   useLayoutEffect(() => {
      if (relatedToFieldCursorPos) {
         setRelatedToFocusRef(relatedToFieldCursorPos);
      }
   }, [relatedToFieldCursorPos, setRelatedToFocusRef]);

   useLayoutEffect(() => {
      if (audioFieldCursorPos) {
         setAudioFocusRef(audioFieldCursorPos);
      }
   }, [audioFieldCursorPos, setAudioFocusRef]);

   useLayoutEffect(() => {
      if (videoFieldCursorPos) {
         setVideoFocusRef(videoFieldCursorPos);
      }
   }, [videoFieldCursorPos, setVideoFocusRef]);

   const NEW_ARTICLE_LABEL = "New Article";
   const EDIT_ARTICLE_LABEL = "Edit Article";

   const computePageLabel = (editorMode) =>
      editorMode === EditorMode.ADD ? NEW_ARTICLE_LABEL : EDIT_ARTICLE_LABEL;

   function isFormValid(slugError, nameError, aliasError, summaryError) {
      return (
         slugError.length === 0 &&
         nameError.length === 0 &&
         aliasError.length === 0 &&
         summaryError.length === 0
      );
   }

   function isSummaryValid(summary) {
      if (summary.length <= 2) return "Summary value is required";
      else return "";
   }

   function isCategoryValid(category) {
      if (category.length === 0) return "Category is required";
      else return "";
   }

   /* returns a promise */
   function isSlugValidAsync(slug, seq, db) {
      return new Promise((resolve, reject) => {
         if (slug.length < SLUG_MIN_LENGTH)
            resolve(`Slug must be at least ${SLUG_MIN_LENGTH} characters`);
         else {
            db.getArticle(slug, (result) => {
               if (result === undefined) {
                  resolve("");
               } else if (result.seq !== seq) {
                  resolve("Slug is already in use by another article");
               } else {
                  resolve("");
               }
            });
         }
      });
   }

   /* returns a promise */
   function isNameValidAsync(name, seq, db) {
      return new Promise((resolve, reject) => {
         if (name.length < NAME_MIN_LENGTH)
            resolve(`Name must be at least ${NAME_MIN_LENGTH} characters`);
         else if (
            !ArticleUtils.isLetter(name.charAt(0)) ||
            !ArticleUtils.isUppercase(name.charAt(0))
         )
            resolve("The first character must be an uppercase letter");
         else if (!name.charAt(name.length - 1) === "s")
            resolve("The last letter must be a lowercase 's' (plural)");
         else {
            db.getArticleByName(name, (result) => {
               if (result === undefined) {
                  resolve("");
               } else if (result.seq !== seq) {
                  resolve("Name is already in use by another article");
               } else {
                  resolve("");
               }
            }).catch((error) => {
               reject(error);
            });
         }
      });
   }

   /* returns a promise */
   function isAliasValidAsync(alias, name, db) {
      return new Promise((resolve, reject) => {
         if (alias.length > 0) {
            if (alias.length !== 0 && alias.length < ALIAS_MIN_LENGTH)
               resolve(`Alias must be at least ${ALIAS_MIN_LENGTH} characters`);
            else if (alias === name) {
               resolve("Alias cannot be the same as Name");
            } else {
               const p1 = new Promise((resolve, reject) => {
                  db.getArticleByName(alias, (result) => {
                     if (result === undefined) {
                        resolve("");
                     } else
                        resolve(
                           "Alias is already in use by another article's name"
                        );
                  }).catch((error) => {
                     reject(error);
                  });
               });

               const p2 = new Promise((resolve, reject) => {
                  db.getArticleByAlias(alias, (result) => {
                     //console.log("getArticleByAlias() result was found.", result);
                     if (result === undefined || result.seq === seq) {
                        resolve("");
                     } else {
                        resolve("Alias is already in use by another article");
                     }
                  }).catch((error) => {
                     reject(error);
                  });
               });

               Promise.all([p1, p2])
                  .then((arr) => {
                     let m =
                        arr[0].length > 0
                           ? arr[0]
                           : arr[1].length > 0
                           ? arr[1]
                           : "";
                     resolve(m);
                  })
                  .catch((error) => {
                     reject(error);
                  });
            }
         } else {
            resolve("");
         }
      });
   }

   function makeRelationRecords(slug, isSlugChanging, relationArray) {
      let relArray = new Map(); //ensure uniqueness
      relationArray.forEach((r) => {
         if (isSlugChanging || !r.seq) {
            relArray.set(r.slug ? r.slug : r.otherSlug, {
               slug1: slug,
               slug2: r.slug ? r.slug : r.otherSlug,
               seq: 0,
            });
         }
      });
      return Array.from(relArray.values());
   }

   /* returns a new array of image records if the source record doesn't have a seq number (new) */
   function getArrayOfNewImages(imageUrlArray) {
     // console.log("DEBUG: getArrayOfNewImages: imageUrlArray=", imageUrlArray);
      let newImagesArray = [];
      imageUrlArray.forEach((img) => {
         if (typeof img.seq === "undefined") {
            newImagesArray.push({
               seq: 0,
               filename: img.file !== null ? img.file.name : img.filename,
               type: img.file !== null ? img.file.type : img.type,
               base64data: img.url,
            });
         }
      });
      return newImagesArray;
   }

   function getArrayOfSavedImages(imageUrlArray) {
      let savedImages = [];

      imageUrlArray.forEach((img) => {
         if (img.seq) {
            savedImages.push({ seq: img.seq });
         }
      });
      return savedImages;
   }

   function copyBackImageSeqNumbers(seqList, newImagesArray) {
      for (let i = 0; i < seqList.length; i++) {
         newImagesArray.push({ seq: seqList[i].seq });
      }
   }

   /* Returns a Promise */
   async function insertArticleWithRelations(
      article,
      relationArray,
      imageSeqArray,
      db,
      dispatch,
      imagesToRemove,
      previousSlug
   ) {
      article.imageSeqArray = imageSeqArray;

      let promises = [];

      promises.push(callSaveArticleAPI(article));

      let slugChange = previousSlug && previousSlug !== article.slug;
      if (slugChange) {
         // console.log(
         //    `SLUG change detected! old=${previousSlug} new=${article.slug}`
         // );
         // mark old article and relations as deleted
         promises.push(callDeleteArticleAPI(previousSlug));
         promises.push(callDeleteRelationsAPI(previousSlug));
      }

      // remove any requested relation that is pointed back to ourself
      relationArray = relationArray.filter((r) => r.slug !== article.slug);

      // slug rename is handled above
      db.fillRelationsArray(article.slug, (existingRelations) => {
        // console.log("DEBUG: existingRelations:", existingRelations);
         if (existingRelations.length > 0) {
            let relationsToRemove = existingRelations.filter(
               (n) =>
                  undefined === relationArray.find((newr) => n.seq === newr.seq)
            );
         //   console.log("DEBUG: relationsToRemove:", relationsToRemove);
            if (relationsToRemove.length > 0) {
               promises.push(callDeleteRelationListAPI(relationsToRemove));
            }
         }
      });

      if (relationArray.length > 0) {
        // console.log("DEBUG: relationArray (before save)", relationArray);
         let relArray = makeRelationRecords(
            article.slug,
            slugChange,
            relationArray
         );
        // console.log("DEBUG: relationArray (to add)", relArray);
         if (relArray.length > 0) {
            promises.push(callSaveRelationsAPI(relArray));
         }
      }

      if (imagesToRemove.length > 0) {
         promises.push(callDeleteImagesAPI(imagesToRemove));
      }

      Promise.all(promises).then((xxx) => {
         // resync because there could be add and remove
        // console.log("DEBUG: all promises resolved! calling process db sync!");
         DbRefreshUtils.processDbSync(db, dispatch)
            .then(async (result) => {
               // console.log(
               //    "DEBUG: I am AdminArticlePageContainer. DbRefreshUtils says it's done.  Reloading data and dispatching."
               // );

               // let category = await db.getCategory(article.category);
               // let categoryArticleList = await db.getArticleArrayForCategory(
               //    article.category
               // );

               // dispatch(fetchCategoryArticleList(category, categoryArticleList));

               Toast.clear();
               navigate(-1);

               // ArticleCallbacks.loadFullArticleDataPromise(
               //    article.slug,
               //    db,
               //    (article, relationArray) => {
               //       CategoryCallbacks.loadFullCategoryDataPromise(
               //          article.category,
               //          db,
               //          dispatch
               //       );

               //       // CategoryCallbacks.handleOnCategoryClick(
               //       //    null,
               //       //    article.category,
               //       //    db,
               //       //    dispatch,
               //       //    null
               //       // );

               //       Toast.clear();

               //       // dispatch(fetchArticlePage(article, relationArray));
               //       // dispatch(resetArticleFormToDefaults());
               //       navigate(-1);
               //    }
               // );
            })
            .catch((error) => {
               console.log("Error resyncing with DB API backend: ", error);
            });
      });
   }

   function onSubmit() {
      //console.log("onSubmit");
      //console.log(`before=${slug}, after=${ArticleUtils.cleanupSlug(slug)}`);
     // console.log("onSubmit relationArray=", relationArray);

      Toast.show({ content: "Saving...", duration: 30000, icon: "loading" });

      const cleanSlug = ArticleUtils.cleanupSlug(slugValue);

      const audio = audioValue.trim();
      const video = videoValue.trim();

      let audioUrlArray = audio.length > 0 ? audio.split("\n") : [];
      let videoUrlArray = video.length > 0 ? video.split("\n") : [];

      let article = {
         slug: cleanSlug,
         name: nameValue,
         alias: aliasValue,
         category: categorySlugValue,
         summary: summaryValue,
         text: textValue,
         seq: 0,
         orderby: articleDefaults.seq,
         audioUrlArray: audioUrlArray,
         videoUrlArray: videoUrlArray,
      };

      let imagesToRemove = [];

      // console.log("DEBUG: imageUrlArray:", imageUrlArray);
      //console.log("DEBUG: audioUrlArray:", audioUrlArray);
      //console.log("DEBUG: videoUrlArray:", videoUrlArray);

      // check to see if there were any images existing before for this article (change)
      db.getArticle(cleanSlug, (previous) => {
         if (typeof previous === "undefined") {
            //console.log(`No previous version of this article was found by slug: ${slug}` );
         } else {
            let existingImages = previous.imageSeqArray;
            //console.log("DEBUG: existingImages:", existingImages);

            imagesToRemove = existingImages.filter(
               (n) =>
                  undefined === imageUrlArray.find((newi) => n.seq === newi.seq)
            );

            //console.log("DEBUG: imagesToRemove:", imagesToRemove);
         }

         /* insert images first because article needs their seq numbers */
         let newImagesArray = getArrayOfNewImages(imageUrlArray);
         let savedImagesMinusRemoves = getArrayOfSavedImages(imageUrlArray);

         //console.log("DEBUG: newImagesArray:", newImagesArray);
         //console.log("DEBUG: savedImagesMinusRemoves:",savedImagesMinusRemoves);

         /* When we update the article record, it needs to contain the complete list of
            image seq numbers for all of the images that we want to keep.
            */

         if (newImagesArray.length > 0) {
            callSaveImagesAPI(newImagesArray).then((response) => {
               //'response' here is just an array of seq attribute records since data is too big
               let imageSeqArray = response.data;
               //console.log("DEBUG: returned list of newly created image sequence numbers: ", imageSeqArray);

               copyBackImageSeqNumbers(imageSeqArray, savedImagesMinusRemoves);

               //console.log("DEBUG: savedImagesMinusRemoves after merge: ", savedImagesMinusRemoves );

               insertArticleWithRelations(
                  article,
                  relationArray,
                  savedImagesMinusRemoves,
                  db,
                  dispatch,
                  imagesToRemove,
                  previousSlug
               );
            });
         } else {
            insertArticleWithRelations(
               article,
               relationArray,
               savedImagesMinusRemoves,
               db,
               dispatch,
               imagesToRemove,
               previousSlug
            );
         }
      });
   }

   function onCancel() {
      //console.log("onCancel");
      navigate(-1);
   }

   function onSlugChange(slug, name, seq) {
      //console.log("onSlugChange", slug);
      slug = WikiTextConverter.sanitizeForSlug(slug);

      isNameValidAsync(name, seq, db)
         .then((nameError) => {
            isSlugValidAsync(slug, seq, db)
               .then((slugError) => {
                  setSlugValue(slug);
                  setNameValue(name);
                  setSlugError(slugError);
                  setNameError(nameError);
               })
               .catch((error) => {
                  console.log("Error onNameChange: ", error);
               });
         })
         .catch((error) => {
            console.log("Error on onSlugChange: ", error);
         });
   }

   function onNameChange(name, slug, seq) {
      //console.log("onNameChange", name);
      slug = WikiTextConverter.sanitizeForSlug(name);
      if (name.length > 0) {
         name = name[0].toUpperCase() + name.slice(1);
      }
      isNameValidAsync(name, seq, db)
         .then((nameError) => {
            isSlugValidAsync(slug, seq, db)
               .then((slugError) => {
                  setSlugValue(slug);
                  setNameValue(name);
                  setSlugError(slugError);
                  setNameError(nameError);
               })
               .catch((error) => {
                  console.log("Error onNameChange: ", error);
               });
         })
         .catch((error) => {
            console.log("Error on onSlugChange: ", error);
         });
   }

   function onAliasChange(alias, name) {
      //console.log("onAliasChange", alias);

      isAliasValidAsync(alias, name, db)
         .then((aliasError) => {
            setAliasValue(alias);
            setAliasError(aliasError);
         })
         .catch((error) => {
            console.log("Error onAliasChange:", error);
         });
   }

   function onSummaryChange(summary, slug) {
      //console.log("onSummaryChange", summary);
      let summaryError = isSummaryValid(summary);
      setSummaryValue(summary);
      setSummaryError(summaryError);
   }

   function onTextChange(text) {
      //console.log("onTextChange", text);
      setTextValue(text);
   }

   function onCategoryChange(category) {
      //console.log("onCategoryChange", category);
      let categoryError = isCategoryValid(category);
      setCategorySlugValue(category);
      setCategoryError(categoryError);

      if (category.length > 0) {
         const limit = 5;
         db.fillQueryCategoriesArray(category, limit, (autoCompleteArray) => {
            setCategorySearchArray(autoCompleteArray);
         });
      } else {
         setCategorySearchArray([]);
      }
   }

   function onCategoryAutoCompleteSelection(e, item) {
      //console.log("onCategoryAutoCompleteSelection() item=", item);

      setCategorySlugValue(item.slug);
      setCategorySearchArray([]);
   }

   function onRemoveRelatedTo(item, itemsList) {
      //console.log("onRemoveRelatedTo() item=", item);
      ArticleUtils.remove(itemsList, item);
      //console.log("DEBUG: itemsList=", itemsList);
      setRelationArray(itemsList.length > 0 ? [...itemsList] : []);
   }

   /* user is typing in the RelatedTo search box */
   function onChangeRelatedToSearch(value) {
      //console.log("onChangeRelatedToSearch() value=", value);
      setRelatedToSearchValue(value);

      if (value.length > 0) {
         db.fillQueryArticlesArray(value, 5, (autocompArray) => {
            setRelatedToSearchArray(autocompArray);
         });
      } else {
         setRelatedToSearchArray([]);
      }
   }

   /* user selected an item in the autocomplete Related To search list */
   function onRelatedToAutoCompleteSelection(e, item /*, relationArray*/) {
      //console.log("onRelatedToAutoCompleteSelection() item=", item);

      if (false === ArticleUtils.itemSlugExistsInArray(item, relationArray)) {
         //console.log("DEBUG: related article item:",item);
         const newRelation = {
            key: item.slug,
            slug: item.slug,
            //otherSlug: item.slug,
            name: item.name,
            category: item.category,
            categoryName: item.categoryName,
         };
         //relationArray.push(newRelation);
         setRelationArray([...relationArray, newRelation]);
      } else {
         console.log("Item already exists in the relationArray");
      }
      setRelatedToSearchArray([]);
      setRelatedToSearchValue("");
   }

   function onAudioChange(newList) {
      // console.log("onAudioChange()!", newList);
      setAudioValue(newList);
   }

   function onVideoChange(newList) {
      //console.log("onVideoChange()!", newList);
      setVideoValue(newList);
   }

   function createNameField() {
      return (
         <>
            <p className="errorText">{nameError}</p>
            <div className="field">
               <label htmlFor="name">Name:</label>
               <input
                  id="name"
                  key="namefieldkey"
                  minLength={NAME_MIN_LENGTH}
                  pattern={`.{${NAME_MIN_LENGTH},}`}
                  required={NAME_MIN_LENGTH > 0}
                  type="text"
                  name="name"
                  title="Name:"
                  onChange={(e) => {
                     setSlugFieldCursorPos(undefined);
                     setAliasFieldCursorPos(undefined);
                     setSummaryFieldCursorPos(undefined);
                     setTextFieldCursorPos(undefined);
                     setCategoryFieldCursorPos(undefined);
                     setRelatedToFieldCursorPos(undefined);
                     setAudioFieldCursorPos(undefined);
                     setVideoFieldCursorPos(undefined);
                     setNameFieldCursorPos(nameFocusRef.current.selectionStart);
                     onNameChange(e.target.value, slugValue);
                  }}
                  placeholder="(required)"
                  value={nameValue}
                  // rows="1"
                  // error={nameError.length > 0}
                  // labelNumber="2"
                  ref={nameFocusRef}
               />
               <ExclamationCircleOutline />
            </div>
         </>
      );
   }

   function createSlugField() {
      return (
         <>
            <p className="errorText">{slugError}</p>
            <div className="field">
               <label htmlFor="slug">Slug:</label>
               <input
                  id="slug"
                  key="slugfieldkey"
                  minLength={SLUG_MIN_LENGTH}
                  pattern={`.{${SLUG_MIN_LENGTH},}`}
                  required={SLUG_MIN_LENGTH > 0}
                  type="text"
                  name="slug"
                  title="Slug:"
                  onChange={(e) => {
                     setNameFieldCursorPos(undefined);
                     setAliasFieldCursorPos(undefined);
                     setSummaryFieldCursorPos(undefined);
                     setTextFieldCursorPos(undefined);
                     setCategoryFieldCursorPos(undefined);
                     setRelatedToFieldCursorPos(undefined);
                     setAudioFieldCursorPos(undefined);
                     setVideoFieldCursorPos(undefined);
                     setSlugFieldCursorPos(slugFocusRef.current.selectionStart);
                     onSlugChange(e.target.value, nameValue);
                  }}
                  placeholder="lowercase, numbers, hyphens only"
                  value={slugValue}
                  // rows="1"
                  // error={slugError.length > 0}
                  // labelNumber="3"
                  ref={slugFocusRef}
               />
               <ExclamationCircleOutline />
            </div>
         </>
      );
   }

   function createAliasField() {
      return (
         <>
            <p className="errorText">{aliasError}</p>
            <div className="field">
               <label htmlFor="slug">Alias:</label>
               <input
                  id="alias"
                  key="aliasfieldkey"
                  minLength={ALIAS_MIN_LENGTH}
                  pattern={`.{0}|.{${ALIAS_MIN_LENGTH},}`}
                  required={false}
                  type="text"
                  name="alias"
                  title="Alias:"
                  onChange={(e) => {
                     setNameFieldCursorPos(undefined);
                     setSlugFieldCursorPos(undefined);
                     setSummaryFieldCursorPos(undefined);
                     setTextFieldCursorPos(undefined);
                     setCategoryFieldCursorPos(undefined);
                     setRelatedToFieldCursorPos(undefined);
                     setAudioFieldCursorPos(undefined);
                     setVideoFieldCursorPos(undefined);
                     setAliasFieldCursorPos(
                        aliasFocusRef.current.selectionStart
                     );
                     onAliasChange(e.target.value, nameValue);
                  }}
                  placeholder="(optional, but unique)"
                  value={aliasValue}
                  // rows="1"
                  // error={aliasError.length > 0}
                  // labelNumber="4"
                  ref={aliasFocusRef}
               />
               <ExclamationCircleOutline />
            </div>
         </>
      );
   }

   function createCategoryTextField() {
      return (
         <>
            <p className="errorText">{categoryError}</p>
            <div className="field">
               <label htmlFor="category">Category:</label>
               <input
                  id="category"
                  key="categoryfieldkey"
                  minLength={CATEGORY_MIN_LENGTH}
                  pattern={`.{${CATEGORY_MIN_LENGTH},}`}
                  required={CATEGORY_MIN_LENGTH > 0}
                  type="text"
                  name="category"
                  title="Category:"
                  onChange={(e) => {
                     setNameFieldCursorPos(undefined);
                     setSlugFieldCursorPos(undefined);
                     setAliasFieldCursorPos(undefined);
                     setSummaryFieldCursorPos(undefined);
                     setTextFieldCursorPos(undefined);
                     setRelatedToFieldCursorPos(undefined);
                     setAudioFieldCursorPos(undefined);
                     setVideoFieldCursorPos(undefined);
                     setCategoryFieldCursorPos(
                        categoryFocusRef.current.selectionStart
                     );
                     onCategoryChange(e.target.value);
                  }}
                  placeholder="(required)"
                  value={categorySlugValue}
                  // rows="1"
                  // error={categoryError.length > 0}
                  // labelNumber="5"
                  ref={categoryFocusRef}
               />
               <ExclamationCircleOutline />
            </div>
         </>
      );
   }

   function createCategoryAutocompleteItem(item, onClick) {
      return (
         <Item key={item.name} onClick={(e) => onClick(e, item)}>
            {item.name}
         </Item>
      );
   }

   function createCategoryAutocompleteList() {
      return (
         <List key="categoryAutoCompleteList" className="my-list">
            {categorySearchArray.map((item) =>
               createCategoryAutocompleteItem(
                  item,
                  onCategoryAutoCompleteSelection
               )
            )}
         </List>
      );
   }

   function createCategoryField() {
      return (
         <>
            {categorySearchArray.length > 0 ? (
               <>
                  <Popover
                     // mask={false}
                     visible={categorySearchArray.length > 0}
                     // overlayClassName="article-category-autocomplete-popup"
                     // overlayStyle={{ color: "currentColor" }}
                     content={createCategoryAutocompleteList(
                        categorySearchArray,
                        onCategoryAutoCompleteSelection
                     )}
                     placement="topLeft"
                     // align={{
                     //    overflow: { adjustY: 0, adjustX: 0 },
                     //    offset: [0, 0],
                     // }}
                  >
                     {createCategoryTextField()}
                  </Popover>
               </>
            ) : (
               createCategoryTextField()
            )}
         </>
      );
   }

   function createSummaryField() {
      return (
         <>
            <p className="errorText">{summaryError}</p>
            <div className="field">
               <label htmlFor="summary">Summary:</label>
               <input
                  id="summary"
                  key="summaryfieldkey"
                  minLength={SUMMARY_MIN_LENGTH}
                  pattern={`.{${SUMMARY_MIN_LENGTH},}`}
                  required={SUMMARY_MIN_LENGTH > 0}
                  type="text"
                  name="summary"
                  title="Summary:"
                  onChange={(e) => {
                     setNameFieldCursorPos(undefined);
                     setSlugFieldCursorPos(undefined);
                     setAliasFieldCursorPos(undefined);
                     setTextFieldCursorPos(undefined);
                     setCategoryFieldCursorPos(undefined);
                     setRelatedToFieldCursorPos(undefined);
                     setAudioFieldCursorPos(undefined);
                     setVideoFieldCursorPos(undefined);
                     setSummaryFieldCursorPos(
                        summaryFocusRef.current.selectionStart
                     );
                     onSummaryChange(e.target.value, nameValue);
                  }}
                  placeholder="(required)"
                  value={summaryValue}
                  // error={summaryError.length > 0}
                  // labelNumber="6"
                  ref={summaryFocusRef}
               />
               <ExclamationCircleOutline />
            </div>
         </>
      );
   }

   function createTextField() {
      return (
         <>
            <p className="errorText">{textError}</p>
            <div className="field">
               <label htmlFor="text">Article Content:</label>
               <textarea
                  id="text"
                  key="textfieldkey"
                  minLength={TEXT_MIN_LENGTH}
                  pattern={`.{${TEXT_MIN_LENGTH},}`}
                  required={TEXT_MIN_LENGTH > 0}
                  name="text"
                  title="Article Content:"
                  onChange={(e) => {
                     setNameFieldCursorPos(undefined);
                     setSlugFieldCursorPos(undefined);
                     setAliasFieldCursorPos(undefined);
                     setSummaryFieldCursorPos(undefined);
                     setCategoryFieldCursorPos(undefined);
                     setRelatedToFieldCursorPos(undefined);
                     setAudioFieldCursorPos(undefined);
                     setVideoFieldCursorPos(undefined);
                     setTextFieldCursorPos(textFocusRef.current.selectionStart);
                     onTextChange(e.target.value);
                  }}
                  placeholder=""
                  rows={8}
                  value={textValue}
                  className="texteditor article-body"
                  ref={textFocusRef}
               />
               <ExclamationCircleOutline />
            </div>
         </>
      );
   }

   async function mockUpload(file) {
      return {
         url: await ArticleUtils.blobToData(file),
         file: file,
         filename: file.name,
         type: file.type,
      };
   }

   function createImageUploader() {
      return (
         <div className="imageUploader">
            <label htmlFor="imageUploader" className="">
               Image:
            </label>
            <span className="instruction">
               400 x 200 px at 96dpi JPEG with 75% quality
            </span>
            <ImageUploader
               id="imageUploader"
               key="imageUploader"
               value={imageUrlArray}
               onChange={setImageUrlArray}
               // selectable={!imageUrlArray || imageUrlArray.length < 3}
               columns={2}
               maxCount={3}
               preview={true}
               imageFit="contain"
               // square={true}
               multiple={false}
               upload={mockUpload}
               // accept="image/gif,image/jpeg,image/jpg,image/png"
            />
         </div>
      );
   }

   function createAudioLinks() {
      return (
         <>
            <p className="errorText"></p>
            <div className="field">
               <label htmlFor="audio">Audio Chapter Links:</label>
               <textarea
                  id="audio"
                  key="audiofieldkey"
                  required={false}
                  name="audio"
                  title="Audio Chapter Links:"
                  onChange={(e) => {
                     setNameFieldCursorPos(undefined);
                     setSlugFieldCursorPos(undefined);
                     setAliasFieldCursorPos(undefined);
                     setSummaryFieldCursorPos(undefined);
                     setCategoryFieldCursorPos(undefined);
                     setRelatedToFieldCursorPos(undefined);
                     setTextFieldCursorPos(undefined);
                     setVideoFieldCursorPos(undefined);
                     setAudioFieldCursorPos(
                        audioFocusRef.current.selectionStart
                     );
                     onAudioChange(e.target.value);
                  }}
                  placeholder="One line per link beginning with https:// and ending with .mp3"
                  value={audioValue}
                  className="texteditor article-body"
                  rows={4}
                  ref={audioFocusRef}
               />
               <ExclamationCircleOutline />
            </div>
         </>
      );
   }

   function createVideoLinks() {
      return (
         <>
            <p className="errorText"></p>
            <div className="field">
               <label htmlFor="video">Video Chapter Links:</label>
               {/* <span className="instruction">
               </span> */}
               <textarea
                  id="video"
                  key="videofieldkey"
                  required={false}
                  name="video"
                  title="Video Chapter Links:"
                  onChange={(e) => {
                     setNameFieldCursorPos(undefined);
                     setSlugFieldCursorPos(undefined);
                     setAliasFieldCursorPos(undefined);
                     setSummaryFieldCursorPos(undefined);
                     setCategoryFieldCursorPos(undefined);
                     setRelatedToFieldCursorPos(undefined);
                     setTextFieldCursorPos(undefined);
                     setAudioFieldCursorPos(undefined);
                     setVideoFieldCursorPos(
                        videoFocusRef.current.selectionStart
                     );
                     onVideoChange(e.target.value);
                  }}
                  placeholder="One line per link beginning with https://"
                  value={videoValue}
                  className="texteditor article-body"
                  rows={4}
                  ref={videoFocusRef}
               />
               <ExclamationCircleOutline />
            </div>
         </>
      );
   }

   function createRelatedToHeader() {
      return (
         <Popover
            // mask={false}
            visible={relatedToSearchArray.length}
            // overlayClassName="article-category-autocomplete-popup"
            // overlayStyle={{ color: "currentColor" }}
            content={createRelatedToAutocompleteList(
               relatedToSearchArray,
               onRelatedToAutoCompleteSelection,
               relationArray
            )}
            placement="topLeft"
            // align={{
            //    overflow: { adjustY: 0, adjustX: 0 },
            //    offset: [0, 0],
            // }}
         >
            <>
               <p className="errorText"></p>
               <div className="field">
                  <label htmlFor="relatedtoSearchValue">Related To:</label>
                  <input
                     id="relatedtoSearchValue"
                     key="relatedtoFieldKey"
                     required={false}
                     name="relatedtoSearchValue"
                     title="Related To:"
                     onChange={(e) => {
                        setNameFieldCursorPos(undefined);
                        setSlugFieldCursorPos(undefined);
                        setAliasFieldCursorPos(undefined);
                        setSummaryFieldCursorPos(undefined);
                        setCategoryFieldCursorPos(undefined);
                        setTextFieldCursorPos(undefined);
                        setAudioFieldCursorPos(undefined);
                        setVideoFieldCursorPos(undefined);
                        setRelatedToFieldCursorPos(
                           relatedToFocusRef.current.selectionStart
                        );
                        onChangeRelatedToSearch(e.target.value);
                     }}
                     placeholder=""
                     value={relatedToSearchValue}
                     ref={relatedToFocusRef}
                  />
                  <ExclamationCircleOutline />
               </div>
            </>
         </Popover>
      );
   }

   function createDeleteRelatedToAlert(item) {
      confirm({
         header: (
            <ExclamationCircleFill
               style={{
                  fontSize: 64,
                  color: "var(--adm-color-warning)",
               }}
            />
         ),
         title: "Remove",
         content: `Are you sure you want to remove ${item.name} from the Related To article list?`,
         confirmText: "Yes Remove",
         cancelText: "",
         showCloseButton: true,
         onConfirm: () => onRemoveRelatedTo(item, relationArray),
      });
   }

   /** this creates the list items for the related-to articles that have been selected for this Article */
   function createRelatedToListItem(item) {
      return (
         <Item
            title=<span>
               {item.categoryName ? item.categoryName : item.category}
            </span>
            key={item.slug}
            onClick={() => {
               createDeleteRelatedToAlert(item);
            }}
            prefix={<CloseCircleFill />}
            arrow={false}
         >
            {item.name}
         </Item>
      );
   }

   function createRelatedToList() {
      return (
         <List header={createRelatedToHeader()} className="related-to-list">
            {relationArray.map((item) => createRelatedToListItem(item))}
         </List>
      );
   }

   function createRelatedToAutocompleteList() {
      return (
         <List key="relatedToAutocompleteList">
            {relatedToSearchArray.map((item) => (
               <ArticleListItem
                  key={item.slug}
                  item={item}
                  onClick={onRelatedToAutoCompleteSelection}
                  master={relationArray}
               />
            ))}
         </List>
      );
   }

   function createRelatedTo() {
      return createRelatedToList();
   }

   function createButtonSet() {
      return (
         <fieldset>
            <Button className="cancelButton" onClick={() => onCancel()}>
               Cancel
            </Button>
            <Button
               disabled={
                  !isFormValid(slugError, nameError, aliasError, summaryError)
               }
               type={
                  !isFormValid(slugError, nameError, aliasError, summaryError)
                     ? ""
                     : "primary"
               }
               onClick={(e) => {
                  e.preventDefault();
                  onSubmit();
               }}
            >
               Save
            </Button>
         </fieldset>
      );
   }

   const ArticleEditorForm = () => (
      <Form
         method="post"
         id="articleEditorForm"
         className="articleEditorForm"
         key="articleEditorForm"
      >
         <h2>{computePageLabel(editorMode)}</h2>
         <p className="instruction">
            Article names must begin with an uppercase letter and be unique.
         </p>

         {createNameField()}
         {createSlugField()}
         {createAliasField()}
         {createCategoryField()}
         {createSummaryField()}
         {createTextField()}
         {createImageUploader()}
         {createAudioLinks()}
         {createVideoLinks()}
         {createRelatedTo()}
         {createButtonSet()}
      </Form>
   );

   return (
      <div className="AdminArticlePage" ref={pageRef} key="AdminArticlePage">
         <ArticleEditorForm />
      </div>
   );
}
