import { replaceSettings } from "../features/settingsSlice";
import { Status } from "../models/Status";
import ArticleUtils from "../utils/ArticleUtils";
import { callGetLatestSettings } from "../utils/BackendAPI";
import {
   GUEST_USER_ID,
} from "../utils/SettingsUtils";
import LocalStorage from "../utils/LocalStorage";
import {
   applyDefaultsForMissingSettings,
//   loadFavoritesData,
} from "../utils/SettingsUtils";

//const axios = require("axios");            // -- TypeError: Axios.get is not a function
//const axios = require("axios").default;   -- TypeError: axios is undefined
//import axios, * as x from "axios";           // TypeError: Axios.get is not a function
import Axios from "axios";

class DbRefreshUtils {
   static ResyncIntervalSeconds = 15 * 60; /* 15 mins */
   static ResyncIntervalMS = this.ResyncIntervalSeconds * 1000;

   /* returns a promise */
   static processDbSync_main(db, dispatch) {
      console.log("proccessDbSync_main() called");
      //schedules for future
      setInterval(() => {
         this.processDbSync_checkOnLine(db, dispatch);
      }, this.ResyncIntervalMS);

      // run now
      return this.processDbSync_checkOnLine(db, dispatch);
   }

   /* returns a promise */
   static processDbSync_checkOnLine(db, dispatch, userId) {
      let lastTimestamp = LocalStorage.getDbRefreshLastTimestamp();
      let now = ArticleUtils.getTimestampSeconds();
      if (!lastTimestamp) lastTimestamp = 0;
      let nextValidInterval = lastTimestamp + this.ResyncIntervalSeconds;

      let timeToRefresh =
         lastTimestamp === null ||
         typeof lastTimestamp === "undefined" ||
         nextValidInterval <= now;

      console.log(
         `lastTimestamp=${lastTimestamp}, now=${now}, nextValidInterval=${nextValidInterval}, timeToRefresh=${timeToRefresh}`
      );

      let promise = null;

      if (timeToRefresh) {
         if (window.navigator.onLine) {
            // if a refresh is needed, return the promise
            promise = this.processDbSync(db, dispatch, userId);
         } else {
            console.log(
               "Browser is OFFLINE. processDbSync() is skipping this interval!"
            );
         }
      } else {
         // if a refresh is not needed, then simply return false
         promise = new Promise((resolve, reject) => {
            resolve(false);
         });
      }

      return promise;
   }

   /* This is the ACTUAL dbsync function */
   /* Returns a promise */
   static processDbSync(db, dispatch, userId) {
      console.log(
         "~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ processDbSync() is being called"
      );
      return new Promise((resolve, reject) => {
         Promise.all([
            this.resyncCategoryTable(db, dispatch),
            this.resyncArticleTable(db, dispatch),
            this.resyncImageTable(db, dispatch),
            this.resyncRelationTable(db, dispatch),
            this.resyncSettingTable(db, dispatch, userId),
         ])
            .then((values) => {
               console.log(
                  "DEBUG: I am DbRefreshUtils, and my promises say they are completed."
               );
               let result = {
                  maxCategorySeq: values[0],
                  maxArticleSeq: values[1],
                  maxImageSeq: values[2],
                  maxRelationSeq: values[3],
                  maxSettingSeq: values[4],
               };

               //console.log(`processDbSync result=${result}`);
               LocalStorage.updateDbRefreshLastTimestamp(
                  ArticleUtils.getTimestampSeconds()
               );

               // used by WhatsNewCard loader
               if (result.maxArticleSeq > 0) {
                  console.log(`Updating LocalStorage with new maxArticleSeq=${result.maxArticleSeq}`);   
                  LocalStorage.updateMaxArticleSeq(
                     result.maxArticleSeq
                  );   
               }

               resolve(result);
            })
            .catch((error) => {
               console.log(
                  `DbRefreshUtils processDbSync() detected a failure! ${error}`
               );

               reject(error);
            });
      });
   }

   static async resyncCategoryTable(db, dispatch) {
      const maxCategorySeq = await db.getMaxCategorySeq();

      let url = `/api/category/latest/${maxCategorySeq}`;
      console.log(`Calling api ... ${url}`);

      return new Promise(async (resolve, reject) => {
         try {
            const { data: categoryEvents } = await Axios.get(url);

            // we've received the results from the backend and
            // now are processing and storing the individual results

            let promiseArray = [];

            console.log(
               `Response from ${url}, length=${categoryEvents.length}`
            );

            categoryEvents.forEach((r) => {
               switch (r.status) {
                  case Status.ALIVE:
                     promiseArray.push(db.insertOneCategory(r));
                     if (maxCategorySeq > 0) {
                        promiseArray.push(
                           db.deleteCategoriesPriorTo(
                              maxCategorySeq + 1,
                              r.slug
                           )
                        );
                     }
                     break;
                  default:
                     /* Status.DELETED */
                     if (r.ref) {
                        promiseArray.push(db.deleteCategory(r.ref));
                     } else {
                        promiseArray.push(
                           db.deleteCategoriesPriorTo(
                              maxCategorySeq + 1,
                              r.slug
                           )
                        );
                        //~~~FIXME                     promiseArray.push(db.deleteAllRecentSearchForSlug(r.slug));
                     }
               }
            });

            Promise.all(promiseArray)
               .then((result) => {
                  // dispatch UI state update
                  console.log("Category: Promise.all() completed");
                  // db.fillBrowseCategoriesArray((categoryArray) => {
                  //    dispatch(fetchCategoriesList(categoryArray));
                  //    resolve(maxCategorySeq);
                  // });
                  resolve(maxCategorySeq);
               })
               .catch((error) => {
                  console.log("Category: Promise.all error: ", error);
                  reject("Category: "+error);
               });
         } catch (error) {
            console.log("Category: catch error: ", error);
            reject("Category: "+error);
         }
      });
   }

   static async resyncArticleTable(db, dispatch) {
      const maxArticleSeq = await db.getMaxArticleSeq();
      let url = `/api/article/latest/${maxArticleSeq}`;
      console.log(`Calling api ... ${url}`);

      return new Promise(async (resolve, reject) => {
         const { data: articleEvents } = await Axios.get(url);

         try {
            // we've received the results from the backend and
            // now are processing and storing the individual results

            //console.log(`Response from ${url}`, articleEvents);
            console.log(`Response from ${url}, length=${articleEvents.length}`);
            let promiseArray = [];
            articleEvents.forEach((r) => {
               switch (r.status) {
                  case Status.ALIVE:
                     promiseArray.push(db.insertOneArticle(r));
                     if (maxArticleSeq > 0) {
                        promiseArray.push(
                           db.deleteArticlesPriorTo(maxArticleSeq + 1, r.slug)
                        );
                     }
                     break;
                  default:
                     /* Status.DELETED */
                     if (r.ref) {
                        promiseArray.push(db.deleteArticle(r.ref));
                     } else {
                        promiseArray.push(
                           db.deleteArticlesPriorTo(maxArticleSeq + 1, r.slug)
                        );
                        //~~~FIXME                     promiseArray.push(db.deleteAllRecentSearchForSlug(r.slug));
                     }
               }
            });

            Promise.all(promiseArray)
               .then((result) => {
                  console.log("Article: Promise.all() completed");
                  //console.log("DEBUG: Articles cleaned up result=", result);
                  resolve(maxArticleSeq);
               })
               .catch((error) => {
                  console.log("Article: Promise.all error: ", error);
                  reject("Article: "+error);
               });
         } catch (error) {
            console.log("Article: catch error: ", error);
            reject("Article: "+error);
         }
      });
   }

   static async resyncRelationTable(db, dispatch) {
      const maxRelationSeq = await db.getMaxRelationSeq();
      let url = `/api/relation/latest/${maxRelationSeq}`;
      console.log(`Calling api ... ${url}`);

      return new Promise(async (resolve, reject) => {
         try {
            const { data: relationEvents } = await Axios.get(url);

            // we've received the results from the backend and
            // now are processing and storing the individual results

            //console.log(`Response from ${url}`, relationEvents);
            console.log(
               `Response from ${url}, length=${relationEvents.length}`
            );
            let promiseArray = [];

            relationEvents.forEach((r) => {
               switch (r.status) {
                  case Status.ALIVE:
                     promiseArray.push(db.insertRelationsAsync(r));
                     break;
                  default:
                     if (r.ref) {
                        promiseArray.push(db.deleteRelation(r.ref));
                     } else {
                        promiseArray.push(db.deleteRelationBySlug(r.slug1));
                     }
               }
            });

            Promise.all(promiseArray)
               .then((result) => {
                  console.log("Relation: Promise.all() completed");
                  //console.log("DEBUG: Relations cleaned up result=", result);
                  resolve(maxRelationSeq);
               })
               .catch((error) => {
                  console.log("Relation: Promise.all error: ", error);
                  reject("Relation: "+error);
               });
         } catch (error) {
            console.log("Relation: catch: ", error);
            reject("Relation: "+error);
         }
      });
   }

   static async resyncImageTable(db, dispatch) {
      const maxImageSeq = await db.getMaxImageSeq();
      let url = `/api/image/latest/${maxImageSeq}`;
      console.log(`Calling api ... ${url}`);

      return new Promise(async (resolve, reject) => {
         try {
            const { data: imageEvents } = await Axios.get(url);

            // we've received the results from the backend and
            // now are processing and storing the individual results

            //console.log(`Response from ${url}`, imageEvents);
            console.log(`Response from ${url}, length=${imageEvents.length}`);

            let promiseArray = [];

            imageEvents.forEach((r) => {
               switch (r.status) {
                  case Status.ALIVE:
                     promiseArray.push(db.insertImagesMany(r));
                     break;
                  default:
                     if (r.ref) {
                        promiseArray.push(db.deleteImage(r.ref));
                     }
               }
            });

            Promise.all(promiseArray)
               .then((result) => {
                  console.log("Image: Promise.all() completed");
                  resolve(maxImageSeq);
               })
               .catch((error) => {
                  console.log("Image: Promise.all error: ", error);
                  reject("Image: "+error);
               });
         } catch (error) {
            console.log("Image: catch error: ", error);
            reject("Image: "+error);
         }
      });
   }

   static async resyncSettingTable(db, dispatch) {
      const maxSettingSeq = await db.getMaxSettingSeq();

      return new Promise(async (resolve, reject) => {
         try {
            const cachedUserId = LocalStorage.getCurrentUserId();
            const userId = cachedUserId ? cachedUserId : GUEST_USER_ID;
            let settingEvents = await callGetLatestSettings(userId, maxSettingSeq);

            // we've received the results from the backend and
            // now are processing and storing the individual results

            let promiseArray = [];

            settingEvents.forEach((r) => {
               switch (r.status) {
                  case Status.ALIVE:
                     promiseArray.push(db.insertOneSetting(r));
                     if (maxSettingSeq > 0) {
                        promiseArray.push(
                           db.deleteSettingsPriorTo(maxSettingSeq + 1, r.userId)
                        );
                     }
                     break;
                  default:
                     /* Status.DELETED */
                     if (r.ref) {
                        promiseArray.push(db.deleteSetting(r.ref));
                     } else {
                        console.log(
                           ">>>DEBUG unrecognized status code for rec:",
                           r
                        );
                        promiseArray.push(
                           db.deleteSettingsPriorTo(maxSettingSeq + 1, r.userId)
                        );
                     }
               }
            });

            Promise.all(promiseArray)
               .then((result) => {
                  // dispatch UI state update
                  console.log("Setting: Promise.all() completed");

                  let userId = LocalStorage.getCurrentUserId();

                  console.log(`Setting: userId=${userId}`);

                  if (userId !== undefined) {
                     db.getSetting(userId).then((settings) => {
                        if (settings) {
                           applyDefaultsForMissingSettings(settings);
                           dispatch(replaceSettings(settings));
                          // loadFavoritesData(settings, db, dispatch);
                        }
                     });
                  } else {
                     console.log("WARN: (Setting) userId not found in local storage!");
                  }

                  resolve(maxSettingSeq);
               })
               .catch((error) => {
                  console.log("Setting: Promise.all error: ", error);
                  reject("Setting: "+error);
               });
         } catch (error) {
            console.log("Setting: catch error: ", error);
            reject("Setting: "+error);
         }
      });
   }
}

export default DbRefreshUtils;
