import { ActionSheet } from "antd-mobile";
import {useSelector, useDispatch} from "react-redux";
import { updateReady } from "../features/mainPageSlice";

export default function AppUpdatingActionSheet() {
   const BUTTONS = ["OK"];

   const waitingServiceWorker = useSelector( (state) => state.mainPage.waitingServiceWorker );
   const dispatch = useDispatch();

   // fix touch to scroll background page on iOS
   // https://github.com/ant-design/ant-design-mobile/issues/307
   // https://github.com/ant-design/ant-design-mobile/issues/163

   const isIPhone = new RegExp("\\biPhone\\b|\\biPod\\b", "i").test(
      window.navigator.userAgent
   );

   let wrapProps;

   if (isIPhone) {
      wrapProps = {
         onTouchStart: (e) => e.preventDefault(),
      };
   }

   function onClose() {
      dispatch(updateReady(undefined));
   }

   ActionSheet.showActionSheetWithOptions(
      {
         options: BUTTONS,
         cancelButtonIndex: BUTTONS.length - 1,
         title: "App Update Available",
         message:
            "An application update is available. Please RESTART the app to use the new version.",
         maskClosable: true,
         "data-seed": "logId",
         wrapProps,
      },
      (buttonIndex) => {
         switch (buttonIndex) {
            case 0:
               if (waitingServiceWorker) {
                  waitingServiceWorker.postMessage({ action: "skipWaiting" });
               }
               onClose();
               break;
            default: //do nothing (Later)
               onClose();
         }
      }
   );

   return null;
}
