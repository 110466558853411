import React, { useRef, useEffect, useState } from "react";
import { useNavigate, redirect, Form, NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button } from "antd-mobile";
// import { callLoginAPI } from "../utils/BackendAPI";
import {
   callJwtLoginAPI,
   getAccountAvatarAPI,
} from "../services/AuthenticationAPI";
import { Toast } from "antd-mobile";
import {
   setMainSearchVisible,
   setToolbarVisible,
} from "../features/mainPageSlice";
import store from "../stores/store";
import { getDB } from "../utils/UseLocalDataStore";
import LocalStorage from "../utils/LocalStorage";
import {
   updateAccount,
   refreshAccountDetails,
   setAvatarData,
} from "../features/accountSlice";
import {
   GUEST_USER_ID,
   applyDefaultsForMissingSettings,
} from "../utils/SettingsUtils";
import { replaceSettings } from "../features/settingsSlice";
import { callSaveSettingAPI } from "../utils/BackendAPI";
import DbRefreshUtils from "../utils/DbRefreshUtils";
import loadStartPageData from "../utils/StartPageLoader";
import sessionTimeoutHandler from "../components/SessionTimeoutHandler";
import AppVersion from "../utils/AppVersion";
import "../components/Form.css";
import "./LoginPage.css";

/* default submit action for this page */
export async function action({ request }) {
   const formData = await request.formData();
   const username = formData.get("username");
   const password = formData.get("password");
   const db = getDB();

   const timeoutS = 60;
   const timeoutMS = timeoutS * 1000;

   console.log(`LoginPage: action() called: username=${username}`);

   Toast.show({
      content: "Authenticating...",
      duration: timeoutMS,
      icon: "loading",
   });

   try {
      const response = await callJwtLoginAPI(username, password);
      //TODO: const response = await callLoginAPI(username, password);
      // console.log(response);
      Toast.clear();

      if (response.data.message) {
         Toast.show({
            content: response.data.message,
            duration: 6000,
            icon: "fail",
         });
      } else {
         console.log("Received response data", response.data);

         // main outputs in response.data include:
         // .accessToken = java web token
         // .roles = map of key:teamId, map of: permission:true|false
         // .id = userid
         // .email = XXXXX
         // .username = XXXXX
         // .travelMode
         // .refreshToken = for use to get new jwt when it expires

         //TODO - this is all the old login stuff @deprecated
         // let profile = response.data.profile;
         // if (profile) {
         //    profile["userId"] = response.data.profile.id;
         //    delete profile["authorities"];
         //    delete profile["diffableFields"];
         //    delete profile["directoryUrl"];
         //    delete profile["eventRoles"];
         //    delete profile["id"];
         //    delete profile["password"];

         //    let base64data = response.data.base64data;
         //    if (base64data) {
         //       profile["base64data"] = base64data;
         //    }

         //    let ttl = response.data.ttl;
         //    if (ttl) {
         //       profile["ttl"] = ttl;
         //    }

         //    let roles = response.data.roles;
         //    if (roles) {
         //       profile["roles"] = roles;
         //    }

         //    await db.updateOrInsertUserProfile(profile);

         //    LocalStorage.setCurrentSessionInfo(
         //       profile.username,
         //       profile.ttl,
         //       profile.userId
         //    );

         //    store.dispatch(updateAccount(profile));
         // }

         // These are the JWT response data items

         if (response.data.accessToken) {
            LocalStorage.setAccessToken(response.data.accessToken);
         }

         if (response.data.refreshToken) {
            LocalStorage.setRefreshToken(response.data.refreshToken);
         }

         LocalStorage.setCurrentSessionInfo(
            response.data.username,
            0,
            response.data.id
         );

         store.dispatch(refreshAccountDetails(response.data));

         if (response.data.id) {
            const imageBinary = await getAccountAvatarAPI(response.data.id);
            if (imageBinary) {
               store.dispatch(setAvatarData(imageBinary));
            }
         }

         let settings = await db.getSetting(response.data.id);

         if (settings) {
            applyDefaultsForMissingSettings(settings);
            store.dispatch(replaceSettings(settings));
            // loadFavoritesPage(settings, db);
         } else {
            //no saved settings for this userId
            //check to see if Guest has saved settings, and
            //ask to migrate

            let guestSettings = await db.getSetting(GUEST_USER_ID);

            if (guestSettings) {
               applyDefaultsForMissingSettings(guestSettings);
               guestSettings.userId = response.data.id;
               await callSaveSettingAPI(guestSettings);
               await DbRefreshUtils.processDbSync(db, store.dispatch);
               Toast.clear();
            } else {
               //there are no settings to migrate,
            }
         }

         // we're done! cleanup and navigate away from here
         store.dispatch(setMainSearchVisible(true));
         store.dispatch(setToolbarVisible(true));
         const topArticleSeq = store.getState("mainPage.topArticleSeq");

         // restart the session timer interval
         // sessionTimeoutHandler(db, store.dispatch);

         console.log("DEBUG: loginPage.onSubmit() loadStartPageData--->");

         return loadStartPageData(
            settings,
            db,
            store.dispatch,
            redirect,
            topArticleSeq
         );
      }
   } catch (error) {
      console.log("ERROR: login received an error:", error);

      if (error.response) {
         Toast.show({
            content: `${error.response.status} ${error.response.statusText}`,
            duration: 6000,
            icon: "fail",
         });
      } else {
         Toast.show({
            content: error.toString(),
            duration: 6000,
            icon: "fail",
         });
      }
   }

   // .catch((error) => {
   // if (error.message) {
   //    Toast.show({
   //       content: error,
   //       duration: 6000,
   //       icon: "fail",
   //    });
   // } else {
   //    Toast.show({
   //       content: "Could not connect to server",
   //       duration: 4000,
   //       icon: "fail",
   //    });
   // }
   // });

   console.log(`DEBUG: returning null`);
   return null;
}

export default function LoginPage() {
   const navigate = useNavigate();
   const inputRef = useRef();
   const dispatch = useDispatch();

   const [loginError, setLoginError] = useState("");

   // function onSubmit(username, password, timeout) {
   //    console.log(`LoginPage: onSubmit() username=${username}`);

   //    Toast.show({
   //       content: "Authenticating...",
   //       duration: 30000,
   //       icon: "loading",
   //    });

   //    callLoginAPI(username, password)
   //       .then(async (response) => {
   //          if (response.data.message) {
   //             setLoginError(response.data.message);
   //             // dispatch(
   //             //    updateLoginFormValues(
   //             //       username,
   //             //       password,
   //             //       response.data.message,
   //             //       true,
   //             //       timeout
   //             //    )
   //             // );

   //             Toast.clear();
   //          } else {
   //             setLoginError("");
   //          }
   //       })
   //       .catch((error) => {
   //          Toast.clear();
   //          clearTimeout(timeout);

   //          if (error.message) {
   //             setLoginError(error.message);

   //             // dispatch(
   //             //    updateLoginFormValues(username, password, error.message, true)
   //             // );
   //          } else {
   //             Toast.show({
   //                content: "Could not connect to server",
   //                duration: 4000,
   //                icon: "fail",
   //             });
   //             console.log("Login received an error:", error);
   //             // dispatch(updateLoginFormValues(username, password, error, true));
   //          }
   //       });
   // }

   useEffect(() => {
      inputRef.current.focus();
   });

   function onCancel() {
      dispatch(setToolbarVisible(true));
      dispatch(setMainSearchVisible(true));
      navigate(-1);
   }

   return (
      <div className="loginPage">
         <div className="background-mask">
            <div className="loginPanel">
               <Button type="link" className="close-button" onClick={onCancel}>
                  &#x2715;
               </Button>
               <h1>Sign In</h1>
               <span>Version {AppVersion}</span>

               <p className="instruction">
                  Please use your Threaded credentials
               </p>

               <Form method="post" id="login">
                  <div className="field">
                     <label htmlFor="username">Username:</label>
                     <input
                        id="username"
                        name="username"
                        type="text"
                        aria-label="username"
                        autoComplete="username"
                        required={true}
                        minLength={3}
                        ref={inputRef}
                     />
                  </div>

                  <div className="field">
                     <label htmlFor="password">Password:</label>
                     <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="password"
                        aria-label="password"
                        required={true}
                        minLength={8}
                     />
                  </div>

                  <NavLink className="forgot" to="/forgot" end>
                     Forgot your password?
                  </NavLink>

                  {/* <p className="errorText">{error}</p> */}

                  <Button
                     className="submitButton"
                     type="submit"
                     name="submitButton"
                  >
                     Submit
                  </Button>
               </Form>
            </div>
         </div>
      </div>
   );
}
