import React, { useRef, useEffect } from "react";
import {
   HomeOutlined,
   InfoCircleOutlined,
   LockOutlined,
   MinusCircleOutlined,
   QuestionCircleOutlined,
   ReloadOutlined,
   WifiOutlined,
} from "@ant-design/icons";
import { Divider, List, Modal, Switch, Toast } from "antd-mobile";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import confirmLogout from "../components/LogoutConfirm";
import ProfileListItem from "../components/ProfileListItem";
import {
   setMainSearchVisible,
   setToolbarVisible,
   setShowSettingsActionSheetMenu,
} from "../features/mainPageSlice";
import AppVersion from "../utils/AppVersion";
import {
   isAppInstalled,
   isIPhone,
   isSafariBrowser,
} from "../utils/BrowserUtils";
import DbRefreshUtils from "../utils/DbRefreshUtils";
import { saveSettingsRemoteOrLocal } from "../utils/SettingsUtils";
import { replaceSettings } from "../features/settingsSlice";
import { useLocalDataStore } from "../utils/UseLocalDataStore";
import GuestActionSheetMenu from "../components/GuestActionSheetMenu";

import "../index.css";
import "./SettingsPage.css";

/* default data loader for this page */
export function loader() {
   console.log(`SettingsPage: loader() called`);
   //T.B.D.
   return null;
}

export default function SettingsPage() {
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const alert = Modal.alert;
   const db = useLocalDataStore();

   const AppName = useSelector((state) => state.mainPage.appName);
   const storage = useSelector((state) => state.mainPage.storage);
   const online = useSelector((state) => state.mainPage.online);
   const showSettingsActionSheetMenu = useSelector(
      (state) => state.mainPage.showSettingsActionSheetMenu
   );
   const currentUser = useSelector((state) => state.account);
   const settings = useSelector((state) => state.settings);
   const startPage = useSelector((state) => state.settings.startPage);
   const fasterStartup = useSelector((state) => state.settings.fasterStartup);
   const simplifiedUI = useSelector((state) => state.settings.simplifiedUI);

   const helpLinkRef = useRef();

   const Item = List.Item;

   useEffect(() => {
      dispatch(
         setShowSettingsActionSheetMenu(
            currentUser.loggedIn === false && settings.userId === undefined
         )
      );
   }, [currentUser.loggedIn, settings.userId, dispatch]);

   // const isIPhone = new RegExp("\\biPhone\\b|\\biPod\\b", "i").test(
   //    window.navigator.platform
   // );

   // const isSafari =
   //    isIPhone &&
   //    !!window.navigator.userAgent.match(/WebKit/i) &&
   //    !window.navigator.userAgent.match(/CriOS/i) &&
   //    !window.navigator.userAgent.match(/FxiOS/i) &&
   //    !window.navigator.userAgent.match(/OPiOS/i);

   function forceDropLocalDatabase() {
      console.log("forceDropLocalDatabase() called");
      Toast.show({
         content: "Deleting Database...",
         duration: 30000,
         icon: "loading",
      });

      db.deleteDatabase().then((x) => {
         Toast.show({
            content: "Resyncing from server...",
            duration: 300000,
            icon: "loading",
         });

         DbRefreshUtils.processDbSync(db, dispatch)
            .then((status) => {
               Toast.clear();
               navigate("/");
            })
            .catch((error) => {
               Toast.show({
                  content: "An error occurred! " + error,
                  duration: 10000,
                  icon: "loading",
               });
            });
      });
   }

   function login(dispatch, navigate) {
      //dispatch(resetLoginForm());
      dispatch(setMainSearchVisible(false));
      dispatch(setToolbarVisible(false));
      navigate("/login");
   }

   async function onProfileClick() {
      //~~~TODO - future - show the user profile details
      if (currentUser.loggedIn) {
         confirmLogout();
      } else {
         login(dispatch, navigate);
      }
      //~~~TODO - future - show the user profile details
   }

   async function onReloadClick(e) {
      console.log("reloading ...... clicked");

      Toast.show({ content: "Resyncing...", duration: 300, icon: "loading" });

      DbRefreshUtils.processDbSync(db, dispatch).then(() => {
         Toast.clear();
      });
   }

   function onLoginClick(e) {
      login(dispatch, navigate);
   }

   function onLogoutClick() {
      confirmLogout();
   }

   function onCardExplorerClick(e, settings) {
      navigate("/settings/cardexplorer");
   }

   function onDeleteCacheClick() {
      alert({
         header: <h2>Reset Database</h2>,
         content: `Are you sure you want to reset your local ${AppName} database? It's best to be on a strong WIFI connection for this.`,
         showCloseButton: true,
         confirmText: "Wipe and Reload",
         onConfirm: () => {
            forceDropLocalDatabase();
         },
      });
   }

   function onChangeFasterStartup(checked) {
      console.log(`onChangeFasterStartup: ${checked}`);

      // make copy of settings
      let newSettings = Object.assign({}, settings, {
         fasterStartup: checked,
      });

      dispatch(replaceSettings(newSettings));
      saveSettingsRemoteOrLocal(online, newSettings, currentUser, db);
   }

   function onChangeSimplifiedUI(checked) {
      console.log(`onChangeSimplifiedUI: ${checked}`);

      // make copy of settings
      let newSettings = Object.assign({}, settings, {
         simplifiedUI: checked,
      });

      dispatch(replaceSettings(newSettings));
      saveSettingsRemoteOrLocal(online, newSettings, currentUser, db);
   }

   function onStartPagePicker() {
      navigate("start-page-picker");
   }

   return (
      <div className="SettingsPage">
         <h2>Settings</h2>
         <ProfileListItem
            profile={currentUser}
            onClick={onProfileClick}
            disabled={!online}
         />
         <List className="PageSettings" key="topSection" header={<Divider />}>
            <Item
               key="onlineItem"
               disabled={true}
               prefix={<WifiOutlined />}
               className="unselectable"
            >
               {online ? "Online" : "Offline"}
            </Item>

            {currentUser.loggedIn === false ? (
               <Item
                  key="loginItem"
                  prefix={<LockOutlined />}
                  onClick={() => onLoginClick()}
                  disabled={!online}
               >
                  Login
               </Item>
            ) : (
               <Item
                  key="logoutItem"
                  prefix={<LockOutlined />}
                  onClick={() => onLogoutClick()}
               >
                  Logout
               </Item>
            )}

            <Item
               key="reloadItem"
               disabled={!online}
               prefix={<ReloadOutlined />}
               arrow={true}
               onClick={() => onReloadClick()}
            >
               Resync
            </Item>
         </List>
         <List className="PageSettings" header={<Divider />}>
            <Item
               key="cardExplorerItem"
               prefix={<HomeOutlined />}
               arrow={true}
               onClick={(e) => onCardExplorerClick(e, settings)}
            >
               Customize Card Explorer
            </Item>
         </List>
         <List
            className="PageSettings"
            key="deleteCacheSection"
            header={<Divider />}
         >
            <Item
               key="deleteCacheItem"
               prefix={<MinusCircleOutlined />}
               arrow={true}
               disabled={!online}
               onClick={(e) => onDeleteCacheClick(e)}
            >
               Delete Local Database
            </Item>
         </List>
         <List className="PageSettings" key="helpSection" header={<Divider />}>
            <a
               ref={helpLinkRef}
               href="mailto:developer@blueflame47.com?subject=Field App"
               _target="_blank"
               className="emailHelpLink"
            >
               Help Contact Email
            </a>
            <Item
               key="helpContactItem"
               prefix={<QuestionCircleOutlined />}
               arrow={true}
               onClick={(e) => {
                  helpLinkRef.current.click();
               }}
            >
               Help Contact
            </Item>
            <Item
               key="appVersionItem"
               prefix={<InfoCircleOutlined />}
               arrow={<span>{AppVersion}</span>}
            >
               App Version
            </Item>
         </List>
         <List className="PageSettings" header={<Divider />}>
            <Item
               className="StartPagePicker"
               key="startPage"
               description={startPage}
               arrow={true}
               onClick={onStartPagePicker}
            >
               Start Page
            </Item>

            <Item
               key="fasterFlipswitch"
               extra={
                  <Switch
                     name="fasterStartup"
                     checked={fasterStartup}
                     onChange={(checked) => onChangeFasterStartup(checked)}
                     platform="ios"
                  />
               }
            >
               Faster Startup
            </Item>
            <Item
               key="simpleFlipswitch"
               extra={
                  <Switch
                     name="simplifiedUI"
                     checked={simplifiedUI}
                     onChange={(checked) => onChangeSimplifiedUI(checked)}
                     platform="ios"
                  />
               }
            >
               Simplified UI
            </Item>
         </List>
         <List
            className="PageSettings"
            key="storageSection"
            header={<Divider />}
         >
            <Item
               key="storageItem"
               prefix={<InfoCircleOutlined />}
               arrow={
                  storage.bytesTotal > 0 ? (
                     <span className="storage">
                        {storage.bytesUsed} of {storage.bytesTotal} MB total
                        used. ~{storage.percentRemaining}% available.
                     </span>
                  ) : (
                     <span className="not-supported">
                        Not suported{" "}
                        {isIPhone() ? <span>on iPhone</span> : null}
                     </span>
                  )
               }
            >
               Available Storage
            </Item>
            <Item
               key="iphoneItem"
               prefix={<InfoCircleOutlined />}
               arrow={
                  <span className="debug">{isIPhone() ? "true" : "false"}</span>
               }
            >
               Is iPhone?
            </Item>
            <Item
               key="safariItem"
               prefix={<InfoCircleOutlined />}
               arrow={
                  <span className="debug">
                     {isSafariBrowser() ? "true" : "false"}
                  </span>
               }
            >
               Is Safari?
            </Item>
            <Item
               key="vendorItem"
               prefix={<InfoCircleOutlined />}
               description="window.navigator.vendor"
               arrow={<span className="debug">{window.navigator.vendor}</span>}
            >
               Browser Vendor
            </Item>
            <Item
               key="platformItem"
               prefix={<InfoCircleOutlined />}
               description="window.navigator.platform"
               arrow={
                  <span className="debug"> {window.navigator.platform} </span>
               }
            >
               App Version
            </Item>
            <Item
               key="standaloneItem"
               prefix={<InfoCircleOutlined />}
               description="window.navigator.platform"
               arrow={
                  <span className="debug">
                     {isAppInstalled() ? "true" : "false"}
                  </span>
               }
            >
               Standalone Display Mode{" "}
            </Item>
         </List>
         <GuestActionSheetMenu
            title="Field App Settings"
            visible={showSettingsActionSheetMenu}
         />
      </div>
   );
}
