import Axios from "axios";

const instance = Axios.create({
  // baseURL: "http://localhost:3000/",
  baseURL: "",
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/json",
  },
});

export default instance;
