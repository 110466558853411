import React, { createRef } from "react";
import Bounce from "bounce.js";
import { ReactComponent as FingerPoint } from "../icons/pointing-down-finger.svg";
import { ReactComponent as ShareIcon } from "../icons/share-apple.svg";
import CloseButtonX from "./CloseButtonX";

import "./BouncyPopup.css";

class BouncyPopup extends React.Component {
   constructor(props) {
      super(props);
      this.installRef = createRef();
      this.bounce = new Bounce().translate({
         from: { x: 0, y: -300 },
         to: { x: 0, y: 0 },
         easing: "bounce",
         duration: 3000,
         delay: 0,
         stiffness: 1,
         bounces: 10,
      });
   }

   componentDidMount() {
      //console.log("INSTALL-REF=", this.installRef);
      this.bounce.applyTo(this.installRef.current, { loop: false });
   }

   render() {
      return (
         <div
            id="install-app-popover"
            className="InstallAppPopover"
            ref={this.installRef}
         >
            <div className="title">
                <div>InstallApp</div>
                <CloseButtonX onClick={this.props.onClose} />
            </div>
            <div className="InstallAppPopoverContent">
               <p>Click Share button,</p>
               <ShareIcon className="ShareIcon" />
               <span>
                  then,
                  <br />
               </span>
               <span>
                  <b>Add To Home Screen</b>
               </span>
               <FingerPoint className="FingerPoint" />
            </div>
         </div>
      );
   }
}

export default BouncyPopup;
