import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ClockCircleOutlined } from "@ant-design/icons";
import { List, Popover, SearchBar } from "antd-mobile";
import { CloseCircleFill } from "antd-mobile-icons";
import { v4 as uuidv4 } from "uuid";
import ArticleListItem from "./ArticleListItem";
import "./ArticleListItem.css";
import "./MainSearch.css";
import NoResults from "./NoResults";
import SimpleArticleListItem from "./SimpleArticleListItem";
import debounce from "lodash/debounce";

import ArticleCallbacks from "../utils/ArticleCallbacks";
import CategoryCallbacks from "../utils/CategoryCallbacks";
import { callSaveSettingAPI } from "../utils/BackendAPI";
import DbRefreshUtils from "../utils/DbRefreshUtils";
import {
   GUEST_USER_ID,
   updateLocalStoreSettings,
   makeNewSettingsWithRecentSearch,
} from "../utils/SettingsUtils";

import {
   RecentSearchArrayInitialState,
   replaceSettings,
   setRecentSearchArray as setRecentSearchSettings,
} from "../features/settingsSlice";
import {
   setAutoCompleteSearchResultsArray,
   setSearchPopupVisible,
   setSearchValue,
   setRecentSearchPicklist,
} from "../features/mainPageSlice";

export default function MainSearch({ db }) {
   const dispatch = useDispatch();
   const navigate = useNavigate();

   const searchVisible = useSelector(
      (state) => state.mainPage.mainSearch.searchVisible
   );

   // this one is used to fill the picklist
   const recentSearchPicklist = useSelector(
      (state) => state.mainPage.mainSearch.recentSearchPicklist
   );

   const popupVisible = useSelector(
      (state) => state.mainPage.mainSearch.popupVisible
   );
   const searchValue = useSelector(
      (state) => state.mainPage.mainSearch.searchValue
   );
   const autoCompleteSearchResultsArray = useSelector(
      (state) => state.mainPage.mainSearch.autoCompleteSearchResultsArray
   );
   const settings = useSelector((state) => state.settings);
   const currentUser = useSelector((state) => state.account);


   function _asyncFillAutoCompleteSearchResultArray(db, query) {
      console.log("_asyncFillAutoCompleteSearchResultArray() query=" + query);

      db.fillAutoCompleteSearchResultArray(query, (inputArray) => {
         console.log(
            "fillAutoCompleteSearchResultArray len=" + inputArray.length
         );

         // fetch image data for articles with images
         let promises = [];

         inputArray.forEach((i) => {
            promises.push(ArticleCallbacks.fetchFeaturedImageData(i, db));
         });

         Promise.all(promises)
            .then((result) => {
               dispatch(setAutoCompleteSearchResultsArray(inputArray));
            })
            .catch((error) => {
               console.log("Error fetching image information: ", error);
            });
      });
   }

   /** user click into the search input box */
   function onSearchFocus(e) {
      console.log(`DEBUG: onSearchFocus() popupVisible=${popupVisible}`,e);
      if (popupVisible) {
         /* if the popup is visible, hide it */
         dispatch(setSearchPopupVisible(false));
         dispatch(setRecentSearchPicklist([]));
      } else {
         /* otherwise, show it */
         dispatch(setSearchPopupVisible(true));
         dispatch(setRecentSearchPicklist(settings.recentSearchArray));
      }
   }

   function updateRecent(item) {
      let recent = {
         slug: item.slug,
         name: item.name,
         type: item.type,
      }; /* category:item.category, */

      // remove existing if present

console.log("DEBUG: before=",settings.recentSearchArray)      ;

      let newRecentSearchArray = settings.recentSearchArray.filter(
         (item) => item.slug !== recent.slug
      );

console.log("DEBUG: after=",newRecentSearchArray)      ;

      // prepend to front of list
      newRecentSearchArray = [recent, ...newRecentSearchArray];

      const newSettings = makeNewSettingsWithRecentSearch(
         newRecentSearchArray,
         settings
      );

      if (currentUser.loggedIn) {
         callSaveSettingAPI(newSettings).then((x) => {
            DbRefreshUtils.processDbSync(db);
            dispatch(setRecentSearchSettings(newRecentSearchArray));
         });
      } else {
         // save settings as Guest
         // settings.userId = GUEST_USER_ID;
         dispatch(replaceSettings(newSettings));
         updateLocalStoreSettings(newSettings, db);
      }
   }

   /** user select an exist recent from the recent search popup */
   function onRecentSelected(e, item) {
      //console.log("DEBUG: onRecentSelected: item=",item);
      let type = item.type;

      dispatch(setRecentSearchPicklist([]));

      if (type === "A") {
         ArticleCallbacks.handleOnArticleClick(item.slug, navigate);
      } else if (type === "C") {
         CategoryCallbacks.handleOnCategoryClick(item.slug, navigate);
      }

      updateRecent(item);
   }

   /** user cleared the recent search list */
   function onClearClick(e) {
      console.log("DEBUG: onClearClick: ");
      e.preventDefault();

      dispatch(setSearchPopupVisible(false));
      dispatch(setRecentSearchPicklist([]));

      const newRecentSearchArray = RecentSearchArrayInitialState;

      const newSettings = makeNewSettingsWithRecentSearch(
         newRecentSearchArray,
         settings
      );

      if (currentUser.loggedIn) {
         callSaveSettingAPI(newSettings).then((x) => {
            DbRefreshUtils.processDbSync(db);
            dispatch(setRecentSearchSettings(newRecentSearchArray));
         });
      } else {
         // save settings as Guest
         dispatch(replaceSettings(newSettings));
         updateLocalStoreSettings(newSettings, db);
      }
   }

   /** user typed a letter in the search box */
   function onSearchChange(value) {
      //console.log("on change called: " + value);
      dispatch(setSearchValue(value));
      asyncFillAutoCompleteSearchResultArray(db, value);
   }

   /** user selected an item from the autocomplete list */
   function onAutoCompleteSelected(e, item) {
      e.preventDefault();
      console.log("DEBUG: onAutoCompleteSelected, item=", item);

      dispatch(setSearchPopupVisible(false));

      if (typeof item.category === "undefined") {
         // item.type = "C";
         CategoryCallbacks.handleOnCategoryClick(item.slug, navigate);
      } else {
         //item.type = "A";
         ArticleCallbacks.handleOnArticleClick(item.slug, navigate);
      }

      updateRecent(item);
   }

   function onSearchBlur() {
      // console.log(`onSearchBlur popupVisible=${popupVisible}`);
      setTimeout(function () {
         if (popupVisible) {
            dispatch(setSearchPopupVisible(false));
         }
      }, 300);
   }

   /* Debounced version */
   const asyncFillAutoCompleteSearchResultArray = debounce(
      _asyncFillAutoCompleteSearchResultArray,
      300
   );

   const RecentSearchesList = () => (
      <List
         header={
            <div className="recent-search-header">
               <span>Recent searches:</span>
               {recentSearchPicklist.length > 0 ? constructClearIcon() : null}
            </div>
         }
         className="my-list"
      >
         {recentSearchPicklist.map((item) => (
            <SimpleArticleListItem
               key={uuidv4()}
               item={item}
               master={undefined}
               onClick={onRecentSelected}
               showCategoryName={false}
               icon={<ClockCircleOutlined />}
            />
         ))}
      </List>
   );

   const AutocompleteSearchResultsList = ({
      autoCompleteSearchResultsArray,
      onAutoCompleteSelected,
   }) => (
      <List className="my-list">
         {autoCompleteSearchResultsArray.map((item) => (
            <ArticleListItem
               key={uuidv4()}
               item={item}
               onClick={(e) => onAutoCompleteSelected(e, item)}
               master={""}
            />
         ))}
      </List>
   );

   const autocompleteSearchResultsList = (
      autoCompleteSearchResultsArray,
      onAutoCompleteSelected
   ) => (
      <AutocompleteSearchResultsList
         autoCompleteSearchResultsArray={autoCompleteSearchResultsArray}
         onAutoCompleteSelected={onAutoCompleteSelected}
      />
   );

   const recentSearchesList = (
      recentSearchPicklist,
      onRecentSelected,
      onClearClick
   ) => (
      <RecentSearchesList
         recentSearchPicklist={recentSearchPicklist}
         onRecentSelected={onRecentSelected}
         onClearClick={onClearClick}
      />
   );

   function constructClearIcon() {
      return (
         <div onClick={(e) => onClearClick(e)}>
            <CloseCircleFill />
            <span>Clear</span>
         </div>
      );
   }

   const noResultsFound = () => (
      // <Empty description="No matching results found" />
      <NoResults instructions="No matching results found" />
   );

   let searchPopupList =
      recentSearchPicklist.length > 0
         ? recentSearchesList(recentSearchPicklist, onRecentSelected, onClearClick)
         : autoCompleteSearchResultsArray.length > 0
         ? autocompleteSearchResultsList(
              autoCompleteSearchResultsArray,
              onAutoCompleteSelected
           )
         : noResultsFound();

   return searchVisible ? (
      <div className="MainSearch">
         <Popover
            className="MainSearchPopover"
            visible={popupVisible}
            content={searchPopupList}
            placement="bottom-start"
         >
            <SearchBar
               aria-label="Search"
               value={searchValue}
               placeholder="Search"
               // clearButtonMode="white-editing"
               cancelText="Cancel"
               showCancelButton={searchValue && searchValue.length > 0}
               onClear={(val) => console.log("onClear val=" + val)}
               onCancel={() => console.log("oncancel called")}
               onChange={(val) => onSearchChange(val)}
               onFocus={(e) => onSearchFocus(e)}
               onBlur={() => onSearchBlur()}
            />
         </Popover>
      </div>
   ) : (
      ""
   );
}
