import { useSelector, useDispatch } from "react-redux";
import { Outlet, ScrollRestoration } from "react-router-dom";
import PageHeader from "../components/PageHeader";
import ToolBar from "../components/ToolBar";
import MainPageActionSheetMenu from "../components/MainPageActionSheetMenu";
import ShareActionSheet from "../components/ShareActionSheet";
import MyMask from "../components/MyMask";
import { updateShareMenu, setInstallAppVisible } from "../features/mainPageSlice";
import AppUpdatingActionSheet from "../components/AppUpdatingActionSheet";
import BouncyPopup from "../components/BouncyPopup";

import "./MainPage.css";

export default function MainPage() {
   const shareMenu = useSelector((state) => state.mainPage.shareMenu);
   const dispatch = useDispatch();
   const waitingServiceWorker = useSelector(
      (state) => state.mainPage.waitingServiceWorker
   );
   const installAppVisible = useSelector(
      (state) => state.mainPage.installAppVisible
   );

   function onShareClose() {
      //console.log("onShareClose()");
      dispatch(updateShareMenu(false, "", ""));
   }

   
   function onInstallClose() {
      dispatch(setInstallAppVisible(false));
   }


   return (
      <>
         <MyMask />
         <PageHeader />
         <Outlet />
         <ToolBar />
         <ShareActionSheet
            url={shareMenu.url}
            pageTitle={shareMenu.title}
            visible={shareMenu.visible}
            onCloseClick={onShareClose}
         />
         <MainPageActionSheetMenu title="Page Actions" />
         {waitingServiceWorker ? <AppUpdatingActionSheet /> : ""}
         {installAppVisible && !waitingServiceWorker ? (
            <BouncyPopup onClose={onInstallClose} />
         ) : (
            ""
         )}
         <ScrollRestoration />
      </>
   );
}
