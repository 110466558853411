import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import AppVersion from "../utils/AppVersion";
import sessionTimeoutHandler from "./SessionTimeoutHandler";
import LocalStorage from "../utils/LocalStorage";
import { Toast } from "antd-mobile";
import { callKeepaliveAPI } from "../utils/BackendAPI";
import { updateOnline } from "../features/mainPageSlice";
import { useLocalDataStore } from "../utils/UseLocalDataStore";

const ONLINE_TIMER_MS = 15 * 1000;
const STARTUP_TIMESTAMP_WAIT_MS = 30 * 1000;
const APP_UPDATED_WAIT_MS = 4.5 * 1000;

var startupHandler_initializing = false;

/*
  Right now this function handles three items:
   1. Periodic keep alive to the backend-server online check
   2. Posts the "app has been upgraded" toast when new version number is detected
   3. Login session timeout handler to auto logout the user when session has expired
*/
export default function StartupHandler({ startedTimestamp, children }) {
   // const [initializing, setInitializing] = useState(false);
//   const [sessionTimer, setSessionTimer] = useState(undefined);
   const [onlineTimer, setOnlineTimer] = useState(undefined);
   const [timestampTimeout, setTimestampTimeout] = useState(undefined);
   const dispatch = useDispatch();
   const db = useLocalDataStore();

   /* WARNING: code in this handler cannot assume that the database has been
   resynced or that the user session and settings have been initialized.
   Typically this will NOT be the case by the time this component
   is rendered, so if you depend on those items you need to invoke your code
   in the handlers that fire after the DbRefreshUtils have completed, or
   after session/settings have been loaded.  Not here.
   */

   useEffect(() => {
      function checkOnline() {
         if (!window.navigator.onLine) {
            dispatch(updateOnline(false));
         } else {
            callKeepaliveAPI()
               .then((result) => {
                  dispatch(updateOnline(true));
               })
               .catch((error) => {
                  dispatch(updateOnline(false));
               });
         }
      }

      function startup() {
         // setInitializing(true);
         startupHandler_initializing = true;

         console.log(
            `>>>>>>>>>>>>>>>>>>>>>>>>>> STARTUPHANDLER.useEffect().startup(), initializing=${startupHandler_initializing}, onlineTimer=${onlineTimer} ) CALLED <<<<<<<<<<<<<<<<<<<<<<<<<`
         );

         checkOnline();

         if (typeof onlineTimer === "undefined") {
            setOnlineTimer(
               setInterval(() => {
                  checkOnline();
               }, ONLINE_TIMER_MS)
            );
         }

         // if (typeof sessionTimer === "undefined") {
         //    setSessionTimer(sessionTimeoutHandler(db, dispatch));
         // }

         if (!LocalStorage.isVersionAcked(AppVersion)) {
            let prevVersion = LocalStorage.getItem("INSTALLED_APP_VERSION");
            let firstTime = prevVersion === null;
            setTimeout(() => {
               if (!firstTime) {
                  try {
                     Toast.show({
                        content: `App updated to version ${AppVersion}`,
                        duration: 5000,
                        icon: "success",
                     });
                  } catch (NotFoundError) {
                     console.log("Caught NotFoundError during Toast.info");
                  }
               }
               LocalStorage.acknowledgeVersion(AppVersion);
            }, APP_UPDATED_WAIT_MS);
         }

         // wait for XXX seconds before writing our startup time in case they immediately exit
         if (typeof timestampTimeout === "undefined") {
            setTimeout(() => {
               LocalStorage.setStartupTimestampMS(startedTimestamp);
               setTimestampTimeout(undefined);
            }, STARTUP_TIMESTAMP_WAIT_MS);
         }
      }

      // run async startup() function now
      if (!startupHandler_initializing) {
         startup();
      }

      /** cleanup() is called every time that useEffect() returns */
      // return function cleanup() {
      //    console.log(`>>>>>>>>>>>>>>>>>>>>>>>>>> STARTUPHANDLER.useEffect().cleanup(initializing=${initializing}) CALLED <<<<<<<<<<<<<<<<<<<<<<<<<`);
      //    if (initialized) {
      //       if (sessionTimer) {
      //          clearInterval(sessionTimer);
      //          setSessionTimer(null);
      //       }
      //       if (onlineTimer) {
      //          clearInterval(onlineTimer);
      //          setOnlineTimer(null);
      //       }
      //    }
      // };
   }, [
      // initializing,
      // sessionTimer,
      onlineTimer,
      timestampTimeout,
      startedTimestamp,
      dispatch,
      db,
   ]);

   return <>{children}</>;
}
