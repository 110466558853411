import React from "react";
import { List } from "antd-mobile";
import { v4 as uuidv4 } from "uuid";
import ArticleListHeader from "./ArticleListHeader";
import ArticleListItem from "./ArticleListItem";
import "./ArticlesList.css";
import "./ArticleListHeader.css";

export default function ArticlesList({
   showHeader,
   online,
   settings,
   category,
   articlesArray,
   onArticleClick,
   onStarClick,
   showCategoryName = true,
   currentUser,
}) {
   function listHeader() {
      return (
         <ArticleListHeader
            category={category}
            onStarClick={onStarClick}
            currentUser={currentUser}
            online={online}
            settings={settings}
         />
      );
   }

   return (
      <List key={uuidv4()} header={ showHeader ? listHeader() : null} className="my-list">
         {articlesArray.map((item) => (
            <ArticleListItem
               key={uuidv4()}
               item={item}
               onClick={onArticleClick}
               master={""}
               showCategoryName={showCategoryName}
            />
         ))}
      </List>
   );
}
