import { Mask } from "antd-mobile";
import { useSelector } from "react-redux";
import "./MyMask.css";

export default function MyMask() {
   const installAppVisible = useSelector(
      (state) => state.mainPage.installAppVisible
   );
   const shareMenuVisible = useSelector(
      (state) => state.mainPage.shareMenu.visible
   );
   const mainPageActionSheetVisible = useSelector(
      (state) => state.mainPage.mainSearch.pageActionSheetVisible
   );
   const toolbarActionSheetMenuVisible = useSelector(
      (state) => state.mainPage.toolbar.toolbarActionSheetMenuVisible
   );
   const showSettingsActionSheetMenu = useSelector(
      (state) => state.mainPage.showSettingsActionSheetMenu
   );
   const mainSearchPopupVisible = useSelector((state) => state.mainPage.mainSearch.popupVisible)

   return (
      <Mask
         className={mainSearchPopupVisible ? "MyMask SearchMask" : "MyMask"}
         visible={
            mainSearchPopupVisible ||
            installAppVisible ||
            shareMenuVisible ||
            mainPageActionSheetVisible ||
            toolbarActionSheetMenuVisible ||
            showSettingsActionSheetMenu
         }
      />
   );
}
