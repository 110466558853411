export const CardType = {
    FAVORITES_CARD: "Favorites",
    LOOKING_UP_CALENDAR_CARD: "Looking Up Calendar",
    WHATS_NEW_CARD: "What's New",
    ARTICLE_OF_MOMENT_CARD: "Article of the Moment",
    HELPFULS_CARD: "Helpfuls",
    IMAGE_OF_MOMENT_CARD: "Image of the Moment",
    MORNING_TEA_CARD: "Morning Tea Podcast",
    BLUEARMY_RADIO_CARD: "Blue Army Radio",
    SOLAR_CYCLE_CALENDAR_CARD: "Solar Cycle Calendar",
};
