import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocalDataStore } from "../utils/UseLocalDataStore";
import {
   CompassOutlined,
   StarOutlined,
   InsertRowBelowOutlined,
   SettingOutlined,
   UserOutlined,
} from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import { ToolBarButtonType } from "../models/ToolBarButtonType";
import ToolBarActionSheetMenu from "./ToolBarActionSheetMenu";
import { Toast } from "antd-mobile";
import {
   setMainSearchVisible,
   setActiveToolbarTab,
   setToolbarVisible,
   setToolbarActionSheetMenuVisible,
} from "../features/mainPageSlice";
import DbRefreshUtils from "../utils/DbRefreshUtils";
import { loadFavoritesPage } from "../utils/SettingsUtils";
import { loadCardExplorerPage } from "../utils/CardExplorerUtils";
import confirmLogout from "../components/LogoutConfirm";
import "./ToolBar.css";

export default function ToolBar() {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const db = useLocalDataStore();
   const settings = useSelector((state) => state.settings);
   const currentUser = useSelector((state) => state.account);
   const online = useSelector((state) => state.mainPage.online);
   const lastStartupTimestampMS = useSelector(
      (state) => state.mainPage.lastStartupTimestampMS
   );
   const activeTab = useSelector((state) => state.mainPage.toolbar.activeTab);
   const toolbarVisible = useSelector(
      (state) => state.mainPage.toolbar.toolbarVisible
   );
   const toolbarActionSheetMenuVisible = useSelector(
      (state) => state.mainPage.toolbar.toolbarActionSheetMenuVisible
   );
   const topArticleSeq = useSelector((state) => state.mainPage.topArticleSeq);

   function onHomeClick() {
      /*dispatch(toggleMainSlideoutPanelVisible());*/
      dispatch(setActiveToolbarTab(ToolBarButtonType.EXPLORER));
      loadCardExplorerPage(settings, db, dispatch, topArticleSeq);
      navigate("/cardexplorer");
   }

   function onCategoriesClick(e) {
      /* dispatch(toggleMainSlideoutPanelVisible());*/
      //      loadCategoriesPage(db, dispatch, navigate);
      dispatch(setActiveToolbarTab(ToolBarButtonType.CATEGORIES));
      navigate("/categories");
   }

   async function onFavoritesClick(e, settings) {
      loadFavoritesPage(settings, db, dispatch, navigate);
   }

   function onSettingsClick(e, currentUser, settings) {
      console.log("Settings Clicked");
      dispatch(setToolbarActionSheetMenuVisible(false));
      dispatch(setActiveToolbarTab(ToolBarButtonType.SETTINGS));
      navigate("/settings");
   }

   function onHamburglerClick(e, currentUser, settings) {
      //console.log("Hamburgler Clicked");
      dispatch(setActiveToolbarTab(ToolBarButtonType.HAMBURGLER));
      dispatch(setToolbarActionSheetMenuVisible(true));
   }

   function onToolbarClose(e) {
      //console.log("Toolbar action sheet closed");
      dispatch(setToolbarActionSheetMenuVisible(false));
   }

   async function onReloadClick(e) {
      console.log("reloading ...... clicked");

      dispatch(setToolbarActionSheetMenuVisible(false));

      Toast.show({ content: "Resyncing...", duration: 30000, icon: "loading" });

      await DbRefreshUtils.processDbSync(db, dispatch);

      Toast.clear();
   }

   function onProfileClick(e, profile) {
      dispatch(setToolbarActionSheetMenuVisible(false));
      if (profile.loggedIn) {
         confirmLogout(dispatch, db);
      } else {
         // dispatch(resetLoginForm());
         dispatch(setMainSearchVisible(false));
         dispatch(setToolbarVisible(false));
         navigate("/login");
      }
   }

   return (
      <React.Fragment>
         {toolbarVisible ? (
            <div className={online ? "ToolBar online" : "ToolBar offline"}>
               <div
                  key={uuidv4()}
                  onClick={(e) => {
                     onFavoritesClick(e, settings);
                  }}
                  className={
                     ToolBarButtonType.FAVORITES === activeTab
                        ? "ToolBarButton selected-icon"
                        : "ToolBarButton"
                  }
               >
                  <StarOutlined />
               </div>
               <div
                  key={uuidv4()}
                  onClick={(e) => {
                     onHomeClick(lastStartupTimestampMS);
                  }}
                  className={
                     ToolBarButtonType.EXPLORER === activeTab
                        ? "ToolBarButton selected-icon"
                        : "ToolBarButton"
                  }
               >
                  <CompassOutlined />
               </div>
               <div
                  key={uuidv4()}
                  onClick={(e) => {
                     onCategoriesClick(e);
                  }}
                  className={
                     ToolBarButtonType.CATEGORIES === activeTab
                        ? "ToolBarButton selected-icon"
                        : "ToolBarButton"
                  }
               >
                  <InsertRowBelowOutlined />
               </div>
               <div
                  key={uuidv4()}
                  onClick={(e) => {
                     onSettingsClick(e, currentUser, settings);
                  }}
                  className={
                     ToolBarButtonType.SETTINGS === activeTab
                        ? "ToolBarButton selected-icon"
                        : "ToolBarButton"
                  }
               >
                  <SettingOutlined />
               </div>

               <div
                  key={uuidv4()}
                  onClick={(e) => {
                     onHamburglerClick(e, currentUser, settings);
                  }}
                  className={
                     ToolBarButtonType.HAMBURGLER === activeTab
                        ? "ToolBarButton selected-icon"
                        : "ToolBarButton"
                  }
               >
                  <UserOutlined />
               </div>
            </div>
         ) : (
            ""
         )}

         {/* <CSSTransition
            in={   setToolbarActionSheetMenuVisible}
            timeout={300}
            unmountOnExit={true}
            classNames="slideup"
         > */}
         <ToolBarActionSheetMenu
            online={online}
            profile={currentUser}
            visible={toolbarActionSheetMenuVisible}
            onSettingsClick={onSettingsClick}
            onCloseClick={onToolbarClose}
            onReloadClick={onReloadClick}
            onProfileClick={onProfileClick}
         ></ToolBarActionSheetMenu>
         {/* </CSSTransition> */}
      </React.Fragment>
   );
}
