import { useSelector } from "react-redux";
import SplashPage from "../pages/SplashPage";

/** loader function for this page */
export async function loader() {
   console.log(`DefaultOutletPage loader() called`);
   return null;
}

export default function DefaultOutletPage() {
   const splashScreenVisible = useSelector(
      (state) => state.mainPage.splashScreenVisible
   );

   return (
      <>
         {splashScreenVisible ? <SplashPage /> : ""}
      </>
   );
}
