import { List } from "antd-mobile";
import React from "react";
import "./SimpleArticleListItem.css";

const Item = List.Item;

export default function SimpleArticleListItem({
   item,
   onClick,
   master,
   showCategoryName = true,
   icon,
}) {
   return (
      <Item className="SimpleArticleListItem"
         // hack for hiding props we don't always want.
         {...(showCategoryName && item.category !== "Category"
            ? { extra: item.category }
            : {})}
         align="top"
         onClick={(e) => onClick(e, item, master)}
         thumb={icon}
      >
         {item.name}
         {typeof item.alias !== "undefined" && item.alias.length > 0
            ? " (" + item.alias + ")"
            : ""}
      </Item>
   );
}
