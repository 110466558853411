var db = null;

export function setDB(aLocalDataStore) {
    db = aLocalDataStore;
}

export function useLocalDataStore() {
  return db;
}

export function getDB() {
    return db;
}