import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Input } from "antd";
import SolarCycleCalendarWidget from "../components/SolarCycleCalendarWidget";
import SolarCycleCalendarCallbacks from "../utils/SolarCycleCalendarCallbacks";

import "./SolarCycleCalendarPage.css";

/* default data loader for this page */
export function loader() {
   console.log(`SolarCycleCalendarPage: loader() called`);
   //T.B.D.
   return null;
}


export default function SolarCycleCalendarPage() {
   const solarCyclesArray = useSelector((state) => state.solarCycleCalendar.pageData);
   const year = useSelector((state) => state.solarCycleCalendar.pageYear);
   const dispatch = useDispatch();

   const { Search } = Input;

   const [scrolled, setScrolled] = useState(false);

   const divRef = React.createRef();
   const scrollerRef = React.createRef();

   useEffect(() => {
      if (divRef.current && !scrolled) {
         let selectionLocX = divRef.current.getBoundingClientRect().x;
         let selectionLocWidthPx = divRef.current.getBoundingClientRect().width;
         let scrollerWidthPx =
            scrollerRef.current.getBoundingClientRect().width;
         let newPosX =
            selectionLocX - (scrollerWidthPx - selectionLocWidthPx) / 2;
         scrollerRef.current.scrollLeft = newPosX;
         setScrolled(true);
         console.log(
            `SolarCycleCalendarPage.useEffect() selectionLocX=${selectionLocX}, selectLocWidthPx=${selectionLocWidthPx}, scrollerWidthPx=${scrollerWidthPx}, newPosX=${newPosX}`
         );
      }
   }, [scrolled, setScrolled, divRef, scrollerRef]);

   function onSearch(value) {
      SolarCycleCalendarCallbacks.loadSolarCycleCalendarPage(value,dispatch);
   }

   return (
      <div className="SolarCycleCalendarPage">
         <div className="searchBar">
            <span className="label">Jump to Gregorian Year:</span>
            <Search
               placeholder="YYYY"
               onSearch={onSearch}
               enterButton={true}
               type="number"
               defaultValue={year}
            />
         </div>
         <div
            className="calendarContent"
            ref={(node) => {
               if (node) scrollerRef.current = node;
            }}
         >
            {solarCyclesArray.map((solarCycle) => (
               <SolarCycleCalendarWidget
                  calendarData={solarCycle}
                  selectionRef={divRef}
                  key={solarCycle[0].solarCycle}
               />
            ))}
         </div>
      </div>
   );
}
