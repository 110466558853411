import React, { useState } from "react";
import ReactPlayer from "react-player";

function MediaPlayer({ className, urlArray, volume, controls, light }) {
   const [playIndex, setPlayIndex] = useState(0);

   function nextTrack() {
      if (playIndex < urlArray.length) {
         setPlayIndex(playIndex + 1);
      }
   }

   return (
      <div className={className}>
         <ReactPlayer
            className="react-player"
            width="100%"
            height="100%"
            light={light}
            playing={playIndex > 0}
            volume={volume}
            controls={controls}
            url={urlArray[playIndex]}
            onEnded={nextTrack}
         />
      </div>
   );
}

export default MediaPlayer;
