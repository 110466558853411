import React, { useRef, useLayoutEffect, useEffect, useState } from "react";
import { useNavigate, useLoaderData, Form } from "react-router-dom";
import { List, Button, ImageUploader, Popover } from "antd-mobile";
import { EditorMode } from "../components/EditorMode";
import WikiTextConverter from "../utils/WikiTextConverter";
import ArticleUtils from "../utils/ArticleUtils";
import DbRefreshUtils from "../utils/DbRefreshUtils";
import CategoryCallbacks from "../utils/CategoryCallbacks";
import { callSaveCategoryAPI } from "../utils/BackendAPI";
import { Toast } from "antd-mobile";
import { useLocalDataStore, getDB } from "../utils/UseLocalDataStore";
import { ExclamationCircleOutline } from "antd-mobile-icons";
import "../utils/ArticleUtils";
import "../components/Form.css";
import "./AdminCategoryPage.css";

/* default data loader for this page */
/** There are 4 scenarios:
 * ADD Top Category
 * ADD Subcategory
 * EDIT Top Category
 * EDIT Subcateory
 *
 *    /admin/category/new           (new top category)
 *    /admin/category/:slug/new"    (new subcategory)
 *    /admin/category/:slug         (edit top | sub category)
 */
export async function loader({ request, params }) {
   let slug = params.slug ? params.slug : "";
   let editorMode = request.url.endsWith("/new")
      ? EditorMode.ADD
      : EditorMode.EDIT;
   let isTopCategory = editorMode === EditorMode.ADD && slug === "";
   let parentSlug = undefined;

   // console.log(
   //    `AdminCategoryPage: loader() called slug=${slug}, isTopCategory=${isTopCategory}, editorMode=${editorMode}`
   // );

   let name = "";
   let hasDependents = false;
   let seq = undefined;
   let imageUrlArray = [];

   if (editorMode === EditorMode.ADD) {
      // handle subcategory ADD scenario...
      if (slug) {
         parentSlug = slug;
         slug = "";
      }
   } else if (editorMode === EditorMode.EDIT) {
      const db = getDB();
      let results = await CategoryCallbacks.loadFullCategoryDataPromise(
         params.slug,
         db
      );

      let category = results.category;

      name = category.name;
      parentSlug = category.parent;
      hasDependents = category.hasDependents;
      seq = category.seq;
      isTopCategory = parentSlug === null || typeof parentSlug === "undefined";
      imageUrlArray = category.imageUrlArray;
   }

   return [
      isTopCategory,
      editorMode,
      seq,
      slug,
      name,
      parentSlug,
      hasDependents,
      imageUrlArray,
   ];
}

export default function AdminCategoryPage() {
   const db = useLocalDataStore();
   const navigate = useNavigate();
   const NEW_TOP_CATEGORY_LABEL = "New Top Category";
   const NEW_SUBCATEGORY_LABEL = "New Subcategory";
   const EDIT_TOP_CATEGORY_LABEL = "Edit Top Category";
   const EDIT_SUBCATEGORY_LABEL = "Edit Subcategory";
   const SLUG_MIN_LENGTH = 3;
   const NAME_MIN_LENGTH = 3;
   const PARENT_MIN_LENGTH = SLUG_MIN_LENGTH;
   const Item = List.Item;

   const [
      isTopCategory,
      editorMode,
      seq,
      defaultSlug,
      defaultName,
      defaultParentSlug,
      hasDependents,
      defaultImageUrlArray,
   ] = useLoaderData();

   const [slugError, setSlugError] = useState("");
   const [nameError, setNameError] = useState("");
   const [parentError, setParentError] = useState("");
   const [slugValue, setSlugValue] = useState(defaultSlug);
   const [nameValue, setNameValue] = useState(defaultName);
   const [parentSlugValue, setParentSlugValue] = useState(defaultParentSlug);
   const [parentAutocompleteArray, setParentAutocompleteArray] = useState([]);
   const [imageUrlArray, setImageUrlArray] = useState(defaultImageUrlArray);

   /* this sets the initial error state upon load */
   useEffect(() => {
      isNameValidAsync(seq, nameValue, db).then((err) => {
         setNameError(err);
      });
   }, [db, seq, nameValue]);

   /* this sets the initial error state upon load */
   useEffect(() => {
      isSlugValidAsync(seq, hasDependents, slugValue, db).then((err) => {
         setSlugError(err);
      });
   }, [db, seq, slugValue, hasDependents]);

   const [nameFieldCursorPos, setNameFieldCursorPos] = useState(undefined);
   const [slugFieldCursorPos, setSlugFieldCursorPos] = useState(undefined);
   const [parentFieldCursorPos, setParentFieldCursorPos] = useState(undefined);

   const useFocus = () => {
      const htmlElRef = useRef(null);
      const setFocus = (cursorPos) => {
         htmlElRef.current && htmlElRef.current.focus();
         if (cursorPos) {
            htmlElRef.current.setSelectionRange(cursorPos, cursorPos);
         }
      };
      return [htmlElRef, setFocus];
   };

   const [nameFocusRef, setNameFocusRef] = useFocus();
   const [slugFocusRef, setSlugFocusRef] = useFocus();
   const [parentFocusRef, setParentFocusRef] = useFocus();

   useLayoutEffect(() => {
      if (nameFieldCursorPos) {
         setNameFocusRef(nameFieldCursorPos);
      }
   }, [nameFieldCursorPos, setNameFocusRef]);

   useLayoutEffect(() => {
      if (slugFieldCursorPos) {
         setSlugFocusRef(slugFieldCursorPos);
      }
   }, [slugFieldCursorPos, setSlugFocusRef]);

   useLayoutEffect(() => {
      if (parentFieldCursorPos) {
         setParentFocusRef(parentFieldCursorPos);
      }
   }, [parentFieldCursorPos, setParentFocusRef]);

   function computePageLabel(editorMode, isTopCategory) {
      // console.log(
      //    `DEBUG: computePageLable editorMode=${editorMode}, isTopCategory=${isTopCategory}`
      // );
      if (editorMode === EditorMode.ADD) {
         if (isTopCategory === true) {
            return NEW_TOP_CATEGORY_LABEL;
         } else {
            return NEW_SUBCATEGORY_LABEL;
         }
      } else {
         if (isTopCategory === true) {
            return EDIT_TOP_CATEGORY_LABEL;
         } else {
            return EDIT_SUBCATEGORY_LABEL;
         }
      }
   }

   /* returns a promise */
   function isSlugValidAsync(seq, hasDependents, slug, db) {
      return new Promise((resolve, reject) => {
         if (slug.length < SLUG_MIN_LENGTH)
            resolve(`Slug must be at least ${SLUG_MIN_LENGTH} characters`);
         else {
            if (!hasDependents) {
               db.getArticle(slug, (result) => {
                  if (result === undefined) {
                     db.getCategory(slug, (result) => {
                        if (
                           result === undefined ||
                           (seq && result.seq === seq)
                        ) {
                           resolve("");
                        } else
                           resolve(
                              "Slug is already in use by another category"
                           );
                     });
                  } else resolve("Slug is already in use by another article");
               });
            } else {
               resolve("");
            }
         }
      });
   }

   /* returns a promise */
   function isNameValidAsync(seq, name, db) {
      return new Promise((resolve, reject) => {
         if (name.length < NAME_MIN_LENGTH)
            resolve(`Name must be at least ${NAME_MIN_LENGTH} characters`);
         else if (!name.charAt(name.length - 1) === "s")
            resolve("The last letter must be a lowercase 's' (plural)");
         else {
            db.getArticleByName(name, (result) => {
               if (result === undefined) {
                  resolve("");
               } else resolve("Name is already in use by another article");
            }).catch((error) => {
               reject(error);
            });
         }
      });
   }

   function isFormValid(slugError, nameError, parentError) {
      return (
         slugError.length === 0 &&
         nameError.length === 0 &&
         parentError.length === 0
      );
   }

   function canEditSlug(editorMode, hasDependents) {
      return hasDependents === false;
   }

   function onSlugChange(slug, name) {
      //console.log("onSlugChange", slug);
      if (slug) {
         slug = WikiTextConverter.sanitizeForSlug(slug);
      }

      setSlugValue(slug);

      isNameValidAsync(seq, name, db)
         .then((nomError) => {
            setNameError(nomError);
            isSlugValidAsync(seq, hasDependents, slug, db)
               .then((slgError) => {
                  setSlugError(slgError);
                  // dispatch(
                  //    updateCategoryFormValues(
                  //       editorMode,
                  //       slug,
                  //       name,
                  //       seq,
                  //       slugError,
                  //       nameError,
                  //       hasDependents,
                  //       imageUrlArray,
                  //       parent
                  //    )
                  // );
               })
               .catch((error) => {
                  console.log("Error onSlugChange: ", error);
               });
         })
         .catch((error) => {
            console.log("Error during onSlugChange: ", error);
         });
   }

   function onParentChange(parent) {
      //console.log("onParentChange", parent);

      parent = WikiTextConverter.sanitizeForSlug(parent);

      setParentSlugValue(parent);

      isNameValidAsync(seq, nameValue, db)
         .then((nomError) => {
            setNameError(nomError);
            isSlugValidAsync(seq, hasDependents, parent, db)
               .then((slgError) => {
                  setSlugError(slgError);

                  if (parent.length > 0) {
                     const limit = 4;
                     db.fillQueryCategoriesArray(
                        parent,
                        limit,
                        (autoCompleteArray) => {
                           setParentAutocompleteArray(autoCompleteArray);
                           // dispatch(
                           // );
                           // dispatch(
                           //    updateCategoryParentPopupVisible(
                           //       autoCompleteArray.length > 0
                           //    )
                           // );
                        }
                     );
                  } else {
                     setParentAutocompleteArray([]);
                  }
               })
               .catch((error) => {
                  console.log("Error onSlugChange: ", error);
               });
         })
         .catch((error) => {
            console.log("Error during onSlugChange: ", error);
         });
   }

   function onCategoryAutoCompleteSelection(e, item) {
      //console.log("onCategoryAutoCompleteSelection() item=", item);

      setParentSlugValue(item.slug);
      // dispatch(setCategoryEditorParentSlug(item.slug));
      // dispatch(updateCategoryParentPopupVisible(false));
      //dispatch(setParentAutocompleteArray([]));
      setParentSlugValue(item.slug);
      setParentAutocompleteArray([]);
   }

   function onImageChange(files) {
      //("onImageChange", files);
      //dispatch(updateCategoryImageArray(files));

      // files = [] of { extra:, key:, thumbnailUrl:, url:}
      setImageUrlArray(files);
   }

   function onNameChange(name, slug) {
      //console.log("onNameChange", name);

      if (name && !hasDependents) {
         slug = WikiTextConverter.sanitizeForSlug(name);
      }

      if (name && name.length > 0) {
         name = name[0].toUpperCase() + name.slice(1);
      }

      setNameValue(name);
      setSlugValue(slug);

      isNameValidAsync(seq, name, db)
         .then(async (nomError) => {
            setNameError(nomError);
            setSlugError("");

            if (!hasDependents) {
               setSlugError(
                  await isSlugValidAsync(seq, hasDependents, slug, db)
               );
            }

            // dispatch(
            //    updateCategoryFormValues(
            //       editorMode,
            //       slug,
            //       name,
            //       seq,
            //       slugError,
            //       nameError,
            //       hasDependents,
            //       imageUrlArray,
            //       parent
            //    )
            // );
         })
         .catch((error) => {
            console.log("Error during onNameChange: ", error);
         });
   }

   /* add or update save */
   function onSubmit(e) {
      e.preventDefault();
      //console.log("onSubmit");

      let cleanSlug = ArticleUtils.cleanupSlug(slugValue);
      //console.log(`before=${slugValue}, after=${cleanSlug}`);

      Toast.show({ content: "Saving...", duration: 30000, icon: "loading" });

      callSaveCategoryAPI({
         slug: cleanSlug,
         name: nameValue,
         seq: seq ? seq : 0,
         imageUrlArray: imageUrlArray,
         parent: parentSlugValue,
      })
         .then(function (response) {
            //console.log(`Response from callSaveCategoryAPI`, response.data);
            //response.data  is the returned category object.

            // resync because there could be add and remove
            DbRefreshUtils.processDbSync(db)
               .then((result) => {
                  //console.log("processDbSync() completed with result:", result);

                  // CategoryCallbacks.loadFullCategoryDataPromise(cleanSlug, db);

                  // await CategoryCallbacks.handleOnCategoryClick(
                  //    null,
                  //    slug,
                  //    db,
                  //    dispatch,
                  //    null
                  // );

                  Toast.clear();

                  navigate(-1);
                  //dispatch(resetCategoryFormToDefaults());
               })
               .catch((error) => {
                  Toast.show({
                     content: error,
                     duration: 6000,
                     icon: "failed",
                  });
               });
         })
         .catch(function (error) {
            Toast.show({ content: error, duration: 6000, icon: "failed" });
         });
   }

   function onCancel(e) {
      e.preventDefault();
      //console.log("onCancel");
      navigate(-1);
      //dispatch(resetCategoryFormToDefaults());
   }

   function createNameField(
   ) {
      return (
         <>
            <p className="errorText">{nameError}</p>
            <div className="field">
               <label htmlFor="name">Name:</label>
               <input
                  id="name"
                  key="fieldfieldkey"
                  minLength={NAME_MIN_LENGTH}
                  pattern={`.{${NAME_MIN_LENGTH},}`}
                  required={NAME_MIN_LENGTH > 0}
                  type="text"
                  name="name"
                  title="Name:"
                  onChange={(e) => {
                     setSlugFieldCursorPos(undefined);
                     setParentFieldCursorPos(undefined);
                     setNameFieldCursorPos(nameFocusRef.current.selectionStart);
                     onNameChange(e.target.value, slugValue);
                  }}
                  placeholder=""
                  value={nameValue}
                  // rows="1"
                  // error={nameError.length > 0}
                  // labelNumber="2"
                  ref={nameFocusRef}
               />
               <ExclamationCircleOutline />
            </div>
         </>
      );
   }

   function createCategoryAutocompleteItem(item, onClick) {
      return (
         <Item key={item.name} onClick={(e) => onClick(e, item)}>
            {item.name}
         </Item>
      );
   }

   function createCategoryAutocompleteList(
      parentAutocompleteArray,
      onCategoryAutoCompleteSelection
   ) {
      return (
         <List
            key="categoryAutoCompleteList"
            className="article-category-autocomplete-popup"
         >
            {parentAutocompleteArray.map((item) =>
               createCategoryAutocompleteItem(
                  item,
                  onCategoryAutoCompleteSelection
               )
            )}
         </List>
      );
   }

   function createParentTextField(
      // editorMode,
      // nameValue,
      // slugValue,
      // seq,
      // hasDependents,
      // imageUrlArray,
      // parentValue,
      // onParentChange
   ) {
      return (
         <>
            <p className="errorText">{parentError}</p>
            <div className="field">
               <label htmlFor="parent">Parent:</label>
               <input
                  id="parent"
                  key="parentFieldKey"
                  minLength={PARENT_MIN_LENGTH}
                  pattern={`.{0}|.{${PARENT_MIN_LENGTH},}`}
                  required={false}
                  name="parent"
                  title="Parent Slug:"
                  onChange={(e) => {
                     setNameFieldCursorPos(undefined);
                     setSlugFieldCursorPos(undefined);
                     setParentFieldCursorPos(
                        parentFocusRef.current.selectionStart
                     );
                     onParentChange(e.target.value, slugValue);
                  }}
                  placeholder=""
                  value={parentSlugValue}
                  // rows="1"
                  // labelNumber="5"
                  ref={parentFocusRef}
               />
               <ExclamationCircleOutline />
            </div>
         </>
      );
   }

   function createParentField(
      // editorMode,
      // nameValue,
      // slugValue,
      // seq,
      // hasDependents,
      // imageUrlArray,
      // parentValue,
      // onParentChange,
      // isTopCategory,
      // parentAutocompleteArray
   ) {
      return (
         <>
            {isTopCategory === false ? (
               parentAutocompleteArray.length > 0 ? (
                  <Popover
                     // mask={false}
                     visible={parentAutocompleteArray.length > 0}
                     // overlayClassName="article-category-autocomplete-popup"
                     // overlayStyle={{ color: "currentColor" }}
                     content={createCategoryAutocompleteList(
                        parentAutocompleteArray,
                        onCategoryAutoCompleteSelection
                     )}
                     placement="topLeft"
                     // align={{
                     //    overflow: { adjustY: 0, adjustX: 0 },
                     //    offset: [0, 0],
                     // }}
                  >
                     {createParentTextField(
                        {/* editorMode,
                        nameValue,
                        slugValue,
                        seq,
                        hasDependents,
                        imageUrlArray,
                        parentSlugValue,
                        onParentChange */}
                     )}
                  </Popover>
               ) : (
                  createParentTextField(
                     {/* editorMode,
                     nameValue,
                     slugValue,
                     seq,
                     hasDependents,
                     imageUrlArray,
                     parentSlugValue,
                     onParentChange */}
                  )
               )
            ) : (
               ""
            )}
         </>
      );
   }

   function createSlugField(
      // editorMode,
      // slugValue,
      // nameValue,
      // seq,
      // slugError,
      // hasDependents,
      // onSlugChange,
      // imageUrlArray,
      // parent
   ) {
      return (
         <>
            <p className="errorText">{slugError}</p>
            <div className="field">
               <label htmlFor="slug">Slug:</label>
               <input
                  id="slug"
                  key="slugfieldkey"
                  minLength={SLUG_MIN_LENGTH}
                  pattern={`.{${SLUG_MIN_LENGTH},}`}
                  required={SLUG_MIN_LENGTH > 0}
                  type="text"
                  name="slug"
                  title="Slug:"
                  disabled={false === canEditSlug(editorMode, hasDependents)}
                  onChange={(e) => {
                     setNameFieldCursorPos(undefined);
                     setParentFieldCursorPos(undefined);
                     setSlugFieldCursorPos(slugFocusRef.current.selectionStart);
                     onSlugChange(e.target.value, nameValue);
                  }}
                  placeholder="lowercase, numbers, hyphens only"
                  value={slugValue}
                  // rows="1"
                  // error={slugError.length > 0}
                  // labelNumber="3"
                  ref={slugFocusRef}
               />
               <ExclamationCircleOutline />{" "}
            </div>
         </>
      );
   }

   async function mockUpload(file) {
      return {
         url: await ArticleUtils.blobToData(file),
      };
   }

   function createImageUploader(imageUrlArray) {
      return (
         <div className="imageUploader">
            <label htmlFor="imageUploader" className="">
               Image:
            </label>
            <span className="instruction">
               400 x 200 px at 96dpi JPEG with 75% quality
            </span>
            <ImageUploader
               id="imageUploader"
               key="imageUploader"
               value={imageUrlArray}
               onChange={setImageUrlArray}
               // selectable={!imageUrlArray || imageUrlArray.length < 3}
               columns={2}
               maxCount={3}
               preview={true}
               imageFit="contain"
               // square={true}
               multiple={false}
               upload={mockUpload}
               // accept="image/gif,image/jpeg,image/jpg,image/png"
            />
         </div>
      );
   }

   function createButtonSet() {
   // editorMode,
   // slugValue,
   // nameValue,
   // seq,
   // slugError,
   // nameError,
   // onSubmit,
   // onCancel,
   // imageUrlArray,
   // parent
      return (
         <fieldset>
            <Button className="cancelButton" onClick={(e) => onCancel(e)}>
               Cancel
            </Button>
            <Button
               disabled={!isFormValid(slugError, nameError, parentError)}
               type="primary"
               // type={!isFormValid(slugError, nameError, parentError) ? "" : "primary"}
               onClick={(e) =>
                  onSubmit(
                     e,
                     slugValue,
                     nameValue,
                     seq,
                     editorMode,
                     imageUrlArray,
                     parentSlugValue
                  )
               }
            >
               Save
            </Button>
         </fieldset>
      );
   }

   const CategoryEditorForm = ({
      // these used to be from redux
      //isTopCategory,
      //editorMode,
      // slugValue,
      // nameValue,
      //slugError,
      //hasDependents,
      //nameError,
      // parent,
      //seq,
      //
      onSlugChange,
      onNameChange,
      onParentChange,
      onSubmit,
      onCancel,
      onDelete,
      onImageChange,
      //imageUrlArray,
      //parentAutocompleteArray,
   }) => (
      <Form
         method="post"
         id="categoryEditorForm"
         className="categoryEditorForm"
      >
         <h2>{computePageLabel(editorMode, isTopCategory)}</h2>
         <p className="instruction">
            Category names must begin with an uppercase letter and end in an 's'
            (plural)
         </p>
         {createNameField(
            {/* editorMode,
            nameValue,
            slugValue,
            seq,
            nameError,
            hasDependents,
            onNameChange,
            imageUrlArray,
            parentSlugValue */}
         )}
         {createSlugField(
            {/* editorMode,
            slugValue,
            nameValue,
            seq,
            slugError,
            hasDependents,
            onSlugChange,
            imageUrlArray,
            parentSlugValue */}
         )}

         {createParentField(
            {/* editorMode,
            nameValue,
            slugValue,
            seq,
            hasDependents,
            imageUrlArray,
            parentSlugValue,
            onParentChange,
            isTopCategory,
            parentAutocompleteArray */}
         )}

         {createImageUploader(imageUrlArray)}

         {createButtonSet({
            /* editorMode,
            slugValue,
            nameValue,
            seq,
            slugError,
            nameError,
            onSubmit,
            onCancel,
            imageUrlArray,
            parentSlugValue */
         })}
      </Form>
   );

   return (
      <div className="AdminCategoryPage" key="AdminCategoryPage">
         <CategoryEditorForm
            editorMode={editorMode}
            hasDependents={hasDependents}
            seq={seq}
            parentSlugValue={parentSlugValue}
            slugValue={slugValue}
            nameValue={nameValue}
            slugError={slugError}
            nameError={nameError}
            imageUrlArray={imageUrlArray}
            parentAutocompleteArray={parentAutocompleteArray}
            isTopCategory={isTopCategory}
            onSlugChange={onSlugChange}
            onNameChange={onNameChange}
            onParentChange={onParentChange}
            onSubmit={onSubmit}
            onCancel={onCancel}
            onImageChange={onImageChange}
         />
      </div>
   );
}
