import {
   setArticleOfDay,
   setImageOfDayArticle,
} from "../features/cardExplorerPageSlice";
import { callSaveArticleAPI } from "../utils/BackendAPI";
import DbRefreshUtils from "./DbRefreshUtils";

const ArticleCallbacks = {
   async loadArticleDataPromise(slug, db) {
      return new Promise(async (resolve, reject) => {
         let article = await db.getArticle(slug);

         //console.log(`DEBUG: got article for slug ${slug}`,article);

         if (!article) {
            reject(`Article ${slug} not found`);
         }

         const p3 = new Promise((resolve, reject) => {
            //console.log("DEBUG:appended category names");
            db.appendCategoryNames([article], (articleWithCategoryName) => {
               if (articleWithCategoryName.length > 0) {
                  article = articleWithCategoryName[0];
               }
               resolve(article);
            }).catch((error) => {
               reject(error);
            });
         });

         const p4 = this.fetchImageData(article, db);

         Promise.all([p3, p4]).then((values) => {
            //console.log("DEBUG: p3 p4 resolved");

            article.img2PopupVisible = false;
            article.img3PopupVisible = false;

            if (
               article.videoUrlArray === undefined ||
               article.videoUrlArray === null
            ) {
               article.videoUrlArray = [];
            }

            if (
               article.audioUrlArray === undefined ||
               article.audioUrlArray === null
            ) {
               article.audioUrlArray = [];
            }

            resolve(article);
         });
      });
   },

   async loadArticleRelationsArrayPromise(slug, db) {
      return new Promise(async (resolve, reject) => {
         let relationsArray = await db.fillRelationsArray(slug);
         //console.log("DEBUG: filling relations array, length="+relationsArray.length);

         if (relationsArray.length > 0) {
            let promises = [];

            for (let i = 0; i < relationsArray.length; i++) {
               let relation = relationsArray[i];
               let otherSlug =
                  slug === relation.slug1 ? relation.slug2 : relation.slug1;

               //console.log("DEBUG: pushing promise for relation #",i);
               promises.push(
                  new Promise((resolve, reject) => {
                     db.getArticle(otherSlug, (rec) => {
                        if (rec) {
                           relation["alias"] = rec.alias;
                           relation["category"] = rec.category;
                           relation["name"] = rec.name;
                           relation["key"] = otherSlug;
                           relation["otherSlug"] = otherSlug;
                        } else {
                        }
                        resolve(relationsArray);
                     });
                  })
               );
            }

            //console.log("DEBUG: promises len="+promises.length);

            Promise.all(promises).then((x) => {
               //console.log("DEBUG: p2 resolved all relations");

               db.appendCategoryNames(relationsArray, (relationsArray) => {
                  //console.log("DEBUG: relationsArray w categoryName:",relationsArray);
                  resolve(relationsArray);
               });
            });
         } else {
            //console.log("DEBUG: p2 resolved with no relations");
            resolve(relationsArray);
         }
      });
   },

   async loadFullArticleDataPromise(slug, db) {
      return new Promise((resolve, reject) => {
         try {
            const p1 = this.loadArticleDataPromise(slug, db);
            const p2 = this.loadArticleRelationsArrayPromise(slug, db);

            Promise.all([p1, p2]).then((success) => {
               resolve({ article: success[0], relationArray: success[1] });
            });
         } catch (error) {
            reject(error);
         }
      });
   },

   handleOnArticleClick(slug, navigate) {
      navigate("/article/" + slug);

      // this.loadFullArticleData(slug, db, (article, relationArray) => {
      //    //    dispatch(fetchArticlePage({ article: article, relationArray: relationArray }));
      //    if (navigate) {
      //       navigate("/article/" + slug);
      //    }
      // });
   },

   // async loadFullArticleDataPromise(slug, db) {
   //    let p = new Promise((resolve, reject) => {
   //       try {
   //          this.loadFullArticleData(slug, db, (article, relationArray) => {
   //             // if (dispatch) {
   //             //    dispatch(fetchArticlePage(article, relationArray));
   //             // }
   //             resolve({ article: article, relationArray: relationArray });
   //          });
   //       } catch (error) {
   //          reject(error);
   //       }
   //    });
   //    return p;
   // },

   /* Returns a Promise */
   async _subImagePlaceholderForData(article, db, onlyOneFlag) {
      return new Promise((resolve, reject) => {
         if (!article.imageSeqArray || article.imageSeqArray === null) {
            resolve(article);
         } else if (article.imageSeqArray.length > 0) {
            let promises = [];

            for (let i = 0; i < article.imageSeqArray.length; i++) {
               let img = article.imageSeqArray[i];
               let imgSeq = img.seq;

               promises.push(
                  db
                     .getImage(imgSeq)
                     .then((result) => {
                        if (result) {
                           //console.log(`DEBUG: article.imageSeqArray[i]=`,article.imageSeqArray[i]);
                           article.imageSeqArray[i] = result;
                        }
                        resolve(article);
                     })
                     .catch((error) => {
                        console.log(
                           "Error getting image from local db: ",
                           error
                        );
                        reject(error);
                     })
               );
               if (onlyOneFlag) break;
            }

            Promise.all(promises).then((success) => {
               resolve(article);
            });
         } else {
            resolve(article);
         }
      });
   },

   /* Returns a Promise */
   async fetchImageData(article, db) {
      const onlyOneFlag = false;
      return this._subImagePlaceholderForData(article, db, onlyOneFlag);
   },

   /* Returns a Promise */
   async fetchFeaturedImageData(article, db) {
      const onlyOneFlag = true;
      return this._subImagePlaceholderForData(article, db, onlyOneFlag);
   },

   loadArticleOfDay(db, articleCount, dispatch) {
      let randomIndex = Math.floor(Math.random() * articleCount);
      db.getArticleAtIndex(randomIndex).then((article) => {
         // console.log(
         //    `loadArticleOfMoment() random chose: ${article.slug} at randomIndex=${randomIndex}`
         // );
         this.fetchImageData(article, db).then((x) => {
            dispatch(setArticleOfDay(article));
         });
      });
   },

   loadImageOfDayArticle(db, imageCount, dispatch) {
      let randomIndex = Math.floor(Math.random() * imageCount);
      // console.log(
      //    `DEBUG: loadImageOfDayArticle: imageCount=${imageCount}, randomIndex=${randomIndex}`
      // );
      db.getImageSeqAtIndex(randomIndex).then((imgSeq) => {
         // console.log(`DEBUG: loadImageOfDayArticle: imgSeq=${imgSeq}`);
         if (imgSeq) {
            db.getArticleForImgSeq(imgSeq).then((article) => {
               console.log("DEBUG: loadImageOfDayArticle: article:", article);
               if (article) {
                  this.fetchImageData(article, db).then((x) => {
                     dispatch(
                        setImageOfDayArticle({ article: article, seq: imgSeq })
                     );
                  });
               }
            });
         }
      });
   },

   /** returns a Promise, if needed */
   resequenceArticleArray(articleArray, db, dispatch, navigate) {
      return new Promise((resolve, reject) => {
         let orderby = 0;
         let promises = [];

         articleArray.forEach((article) => {
            article.orderby = orderby;
            delete article._id;
            promises.push(callSaveArticleAPI(article));
            orderby = orderby + 1;
         });

         // wait for all the individual updates to complete,
         // then do a forced db resync
         Promise.all(promises)
            .then((x) => {
               DbRefreshUtils.processDbSync(db, dispatch)
                  .then((y) => {
                     if (navigate) {
                        navigate(-1);
                     }
                     resolve();
                  })
                  .catch((error) => {
                     console.log("ERROR: forced db refresh failed!", error);
                     reject(error);
                  });
            })
            .catch((error) => {
               console.log("ERROR: some article updates failed!", error);
               reject(error);
            });
      });
   },
};

export default ArticleCallbacks;
