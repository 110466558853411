class WikiTextConverter {
   static NonWordPattern = new RegExp(/\W/g);
   static DoubleBlankLinesPattern = new RegExp(/\n\n/g);

   // sample:  [[/category/red][Red]]
   static CustomLinkPattern = new RegExp(
      /([^]*?)\[\[([^[\]]+)\]\[([^[\]]+)\]\]/
   );

   // sample:  ==Heading Number Two==
   static Heading2Pattern = new RegExp(/==([^=]+)==/g);
   static Heading3Pattern = new RegExp(/===([^=]+)===/g);

   // sample:  _bold text_
   static BoldPattern = new RegExp(/\*([\w^*][^*]+)\*/g);

   // sample:  _italics text_
   static ItalicPattern = new RegExp(/_([\w^_][^_]+)_/g);

   // sample: {| class="wikitable mw-collapsible" style="text-align:center; margin-left:auto;"
   static EmbeddedTableOpenPattern = new RegExp(/{\|(.*?)/);

   // sample: |-
   static EmbeddedTableRowPattern = new RegExp(/\|-(\s*?)/);

   // sample ! This
   static EmbeddedTableCellPattern = new RegExp(/^!\s+(.+)$/);

   // sample: |}
   static EmbeddedTableClosePattern = new RegExp(/\|}(.*?)/);

   static sanitizeForSlug(str) {
      return str.toLowerCase().replace(this.NonWordPattern, "-");
   }

   static expandItalic(str) {
      return str.replace(this.ItalicPattern, "<em>$1</em>");
   }

   static expandBold(str) {
      return str.replace(this.BoldPattern, "<strong>$1</strong>");
   }

   static expandH3(str) {
      return str.replace(this.Heading3Pattern, "<h3>$1</h3>");
   }

   static expandH2(str) {
      return str.replace(this.Heading2Pattern, "<h2>$1</h2>");
   }

   static expandNewlines(str) {
      return str.replace(this.DoubleBlankLinesPattern, "<br/><br/>");
   }

   static applyAllWikiTextTransformations(str) {
      if (str != null && str.length > 0) {
         //this.expandNewlines(
         return this.expandH2(
            this.expandH3(this.expandBold(this.expandItalic(str)))
         );
      }
   }

   static parseLink(line, results) {
      let parseText = line;
      let i = 0;
      let result;

      while (parseText.length > 0 && i < 50) {
         result = parseText.match(this.CustomLinkPattern);
         // console.log(`CustomLinkPattern: result=`, result);

         if (result !== null) {
            let matchingString = result[0];
            let pretext = result[1];
            let href = result[2];
            let display = result[3];
            let index = result["index"];

            if (pretext != null && pretext.length > 0) {
               results.push({
                  type: "text",
                  value: this.expandNewlines(pretext),
               });
            }

            // console.log(`+++++ pretext=${pretext}`);
            // console.log(`+++++ href=${href}`);
            // console.log(`+++++ display=${display}`);

            if (display.startsWith("*") && href.startsWith("http")) {
               results.push({
                  type: "imglink",
                  href: href,
                  img: display.substring(1) + ".png",
               });
            } else {
               results.push({
                  type: "link",
                  href: href,
                  display: display,
               });
            }

            parseText = parseText.substring(index + matchingString.length);
            i = i + 1;
         } else {
            //console.log(`line=${line}`);

            //+"<br/><br/>"
            results.push({
               type: "text",
               value: this.expandNewlines(parseText),
            });
            break;
         }
      }
   }

   static parseEmbeddedTableClose(line, results) {
      let result = line.match(this.EmbeddedTableClosePattern);
      // console.log(`EmbeddedTableClosePattern: result=`, result);
      if (result !== null) {
         results.push({
            type: "closeTag",
         });
      } else {
         this.parseLink(line, results);
      } /* final match else */
   }

   static parseEmbeddedTableCell(line, results) {
      let result = line.match(this.EmbeddedTableCellPattern);
      //console.log(`EmbeddedTableCellPattern: result=`, result);
      if (result !== null) {
         let value = result[1];
         if (value.includes("[[")) {
            let temp = [];
            this.parseLink(value, temp);
            if (temp.length > 0) {
               value = temp;
            }
         }

         results.push({
            type: "tableCell",
            value: value,
         });
      } else {
         this.parseEmbeddedTableClose(line, results);
      } /* EmbeddedTableClosePattern */
   }

   static parseIntoMetaArray(str) {
      let results = [];
      if (str) {
         let lines = str.split("\n");
         let result;

         //console.log("lines===", lines);

         lines.forEach((line) => {
            if (line.trim().length === 0) {
               results.push({
                  type: "text",
                  value: this.expandNewlines("\n\n"),
               });
            } else {
               result = line.match(this.EmbeddedTableOpenPattern);
               // console.log(`EmbeddedTableOpenPattern: result=`, result);
               if (result !== null) {
                  results.push({ type: "openTag", tag: `<table ${result[1]}` });
               } else {
                  result = line.match(this.EmbeddedTableRowPattern);
                  //console.log(`EmbeddedTableRowPattern: result=`, result);
                  if (result !== null) {
                     results.push({
                        type: "tableRow",
                     });
                  } else {
                     this.parseEmbeddedTableCell(line, results);
                  }
               }
            }
         });
      }

      //console.log("results===", results);

      return results;
   }
}

export default WikiTextConverter;
