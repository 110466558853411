import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLoaderData } from "react-router-dom";
import store from "../stores/store";
import { Button, List } from "antd-mobile";
import { MinusCircleFilled } from "@ant-design/icons";
import { ReactComponent as HamburglerIcon } from "../icons/hamburgler-icon.svg";
import { FavoritesMode } from "../models/FavoritesMode";
import {
   loadFavoritesData,
   updateLocalStoreSettings,
   showRemoveFavoritesOfflineAlert,
   showEditFavoritesOfflineAlert,
   confirmRemoveItem,
   makeNewSettingsWithFavorites,
} from "../utils/SettingsUtils";
import {
   replaceSettings,
   replaceFavoritesList,
} from "../features/settingsSlice";
import { useLocalDataStore, getDB } from "../utils/UseLocalDataStore";
//import LocalStorage from "../utils/LocalStorage";
import ArticleListItem from "../components/ArticleListItem";
import NoResults from "../components/NoResults";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import StrictModeDroppable from "../components/StrictModeDroppable";
import { callSaveSettingAPI } from "../utils/BackendAPI";
import DbRefreshUtils from "../utils/DbRefreshUtils";
import GuestActionSheetMenu from "../components/GuestActionSheetMenu";

import "./FavoritesPage.css";

/* default data loader for this page */
export async function loader() {
   //console.log(`FavoritesPage: loader() called`);
   const db = getDB();

   //const currentUserId = LocalStorage.getCurrentUserId();
   //   const settings = await db.getSetting(currentUserId);
   const settings = store.getState("mainPage").settings;
   const favoritesItemList = await loadFavoritesData(settings, db);

   //console.log(`DEBUG: favoritesItemList=`, favoritesItemList);

   return favoritesItemList ? favoritesItemList : [];
}

export default function FavoritesPage() {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const db = useLocalDataStore();
   const APP_NAME = useSelector((state) => state.mainPage.appName);
   const online = useSelector((state) => state.mainPage.online);
   const settings = useSelector((state) => state.settings);
   const currentUser = useSelector((state) => state.account);
   const [favoritesItemList, setFavoritesItemList] = useState(useLoaderData());
   const [mode, setMode] = useState(FavoritesMode.VIEW);

   /* read-only view list item */
   function createListItemContent(favoritesItemList, onItemClick, online) {
      let results = [];

      if (favoritesItemList) {
         results = favoritesItemList
//            .filter((item) => item)
            .map((item) => (
               <ArticleListItem
                  key={item.slug}
                  item={item}
                  onClick={onItemClick}
                  master={undefined}
                  showCategoryName={item.type === "A"}
                  showThumbnail={true}
                  showSummary={item.type === "A"}
                  showArrow={true}
                  online={online}
               />
            ));
      }

      return results;
   }

   async function onItemClick(e, item) {
      //console.log(`DEBUG: onItemClick: ${item.slug}`);

      if (item.type === "A") {
         navigate("/article/" + item.slug);
      } else {
         navigate("/category/" + item.slug);
      }
   }

   async function onToggleEdit(currentUser, online) {
      if (currentUser && currentUser.loggedIn && !online) {
         showEditFavoritesOfflineAlert();
      } else {
         if (mode === FavoritesMode.EDIT) {
            // transitioning from EDIT to VIEW (i.e. saving)

            // commit the save to DB and Redux
            // make a list of favorite slugs
            const newFavoritesList = favoritesItemList.map(
               (item) => item.slug
            );
           // console.log(`DEBUG: newFavoritesList=`, newFavoritesList);

            const newSettings = makeNewSettingsWithFavorites(
               currentUser.userId,
               newFavoritesList,
               settings
            );
            //console.log(`DEBUG: newSettings=`, newSettings);
            dispatch(replaceFavoritesList(newFavoritesList));

            if (currentUser.loggedIn) {
               callSaveSettingAPI(newSettings).then(() => {
                  DbRefreshUtils.processDbSync(db, dispatch);
               });
            } else {
               // save settings as Guest
               dispatch(replaceSettings(newSettings));
               updateLocalStoreSettings(newSettings, db);
            }

            setMode(FavoritesMode.VIEW);
         } else {
            // transitioning from VIEW to EDIT (so that we can edit)
            setMode(FavoritesMode.EDIT);
         }
      }
   }

   function onItemDelete(slug) {
      //console.log(`onItemDelete: ${slug}`);

      if (currentUser && currentUser.loggedIn && !online) {
         showRemoveFavoritesOfflineAlert();
      } else {
         confirmRemoveItem(slug, () => {
            // this code only removes from our local list (doesn't save yet)
            const newFavoritesList = favoritesItemList.filter(
               (item) => item.slug !== slug
            );
            setFavoritesItemList(newFavoritesList);
         });
      }
   }

   /** shuffle the data list based on user drag-and-drop */
   const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
   };

   /** handle drag-and-drop end actions */
   const onDragEnd = (result) => {
      if (!result.destination) return;

      //console.log(`onDragEnd result=`, result);

      // what we need out of the result object is:
      // result.destination.index and
      // result.source.index is the old (pre-drag) index

      const newFavoritesItemList = reorder(
         favoritesItemList,
         result.source.index,
         result.destination.index
      );

      // this function doesn't update DB or Redux, only local state
      setFavoritesItemList(newFavoritesItemList);
   };

   /** make our payload contents for draggable row */
   function makeEditRowContents(props) {
      return (
         <div className="editable-row">
            <div
               className="delete-button"
               onClick={() => {
                  props.onItemDelete(props.slug);
               }}
            >
               <MinusCircleFilled className="circle-minus-delete-icon" />
            </div>
            <span className="edit-text">{props.name}</span>{" "}
            <HamburglerIcon className="hamburgler-icon" />
         </div>
      );
   }

   /** create React element for one draggable row */
   const DraggableListItem = (props) => {
      //console.log(`DEBUG: props.id=${props.id}`);
      return (
         <Draggable key={props.id} draggableId={props.id} index={props.index}>
            {(provided, snapshot) => (
               <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={{
                     ...provided.draggableProps.style,
                     opacity: snapshot.isDragging ? 0.8 : 1,
                  }}
               >
                  {makeEditRowContents(props.item)}
               </div>
            )}
         </Draggable>
      );
   };

   /** make properties for one draggable row */
   function makeItemProps(item, onItemDelete, settings, currentUser, index) {
      return {
         id: item.slug,
         item: item,
         onItemDelete: onItemDelete,
         settings: settings,
         currentUser: currentUser,
         index: index,
      };
   }

   /** make whole list of draggable rows */
   function makeItemList(idList, onItemDelete, settings, currentUser) {
      let itemList = idList.map((item, index) => (
         <DraggableListItem
            {...makeItemProps(item, onItemDelete, settings, currentUser, index)}
         />
      ));

      return itemList;
   }

   return (
      <div className="FavoritesPage">
         {!favoritesItemList || favoritesItemList.length === 0 ? (
            <NoResults
               instructions={
                  <span>
                     You have no favorite articles or categories as of yet.{" "}
                     <br />
                     <br /> To add a favorite, find an article or category page
                     in {APP_NAME} that you like that contains a star icon
                     (&#9734;) and click the star to add the page to your list
                     of favorites. <br />
                     <br /> Then come back here to this page and it will show in
                     the list!
                  </span>
               }
            />
         ) : (
            <List
               header={
                  <div className="list-header">
                     <h3>Favorites</h3>
                     <Button
                        onClick={() => onToggleEdit(currentUser, online)}
                        type="primary"
                        inline={true}
                        size="small"
                        className="am-button-borderfix"
                     >
                        {mode === FavoritesMode.EDIT ? "Done" : "Edit"}
                     </Button>
                  </div>
               }
               className="FavoritesSortingList"
            >
               {mode === FavoritesMode.EDIT ? (
                  <DragDropContext onDragEnd={onDragEnd}>
                     <StrictModeDroppable droppableId="droppable">
                        {(droppableProvided) => (
                           <div ref={droppableProvided.innerRef}>
                              {makeItemList(
                                 favoritesItemList,
                                 onItemDelete,
                                 settings,
                                 currentUser
                              )}
                              {droppableProvided.placeholder}
                           </div>
                        )}
                     </StrictModeDroppable>
                  </DragDropContext>
               ) : (
                  createListItemContent(favoritesItemList, onItemClick, online)
               )}
               <GuestActionSheetMenu
                  visible={
                     mode === FavoritesMode.EDIT &&
                     currentUser.loggedIn === false &&
                     settings.userId === undefined
                  }
               />
            </List>
         )}
      </div>
   );
}
