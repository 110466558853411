import api from "./ThreadedAPI";
import ArticleUtils from "../utils/ArticleUtils";

const timeoutS = 30;
const timeoutMS = timeoutS * 1000;

export async function callJwtLoginAPI(username, password) {
   const url = "/api/auth/signin";
   console.log(`Calling callJwtLoginAPI ... username=${username}, url=${url}`);
   const data = { username: username, password: password };
   // console.log("DEBUG: data=",data);
   return api.post(url, data, { timeout: timeoutMS });
}

export async function callLogoutAPI() {
   const url = "/api/auth/signout";
   console.log(`Calling callLogoutAPI ... url=${url}`);
   return api.post(url, {}, { timeout: timeoutMS });
}

/** TODO move this to its own AccountAPI module */
export async function getAccountAvatarAPI(accountId) {
   const url = `/api/account/${accountId}/avatar`;
   console.log(`Calling getAccountAvatarAPI ... url=${url}`);
   //WARNING: .get() does not accept a body object
   const response = await api.get(
      url,
      {
//AXIOS isn't supporting these overrides here ---->   headers: { Accept: "image/jpeg" },
         responseType: "blob",
         timeout: timeoutMS,
      }
   );

   return await ArticleUtils.blobToData(response.data);
}

export async function getLoginUserInfoAPI() {
   const url = "/api/account/login-user-info";
   console.log(`Calling getLoginUserInfoAPI ... url=${url}`);
   // console.log("DEBUG: data=",data);
   return api.post(url, {}, { timeout: timeoutMS });
}
