import React from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Timeline } from "antd";
import { CardType } from "../models/CardType";
import { HelpfulsData } from "../utils/HelpfulsData";
import { useNavigate } from "react-router-dom";
import Card from "./Card";
import "./HelpfulsCard.css";

export default function HelpfulsCard() {
   const navigate = useNavigate();

   function onMenuClick() {
      console.log("onMenuClick()");
      navigate("/settings/cardexplorer");
   }

   function onClickNavLink() {
      console.log("onClickNavLink()");
      navigate("/helpfuls");
   }

   const Item = Timeline.Item;
   const ShowOnlyQuantity = 3;

   function createCardItems(itemList) {
      let results = [];

      for (let i = 0; i < ShowOnlyQuantity && i < itemList.length; i++) {
         results.push(
            <Item key={i} color="green">
               {itemList[i]}
            </Item>
         );
      }

      return results;
   }

   return (
      <Card
         key="HelpfulsCard"
         className="HelpfulsCard"
         headerIcon={<QuestionCircleOutlined className="headerIcon" />}
         titleText={CardType.HELPFULS_CARD}
         onMenuIconClick={onMenuClick}
         onMoreClick={onClickNavLink}
      >
         <div className="helpfulsContent">
            <Timeline>{createCardItems(HelpfulsData)}</Timeline>
         </div>
      </Card>
   );
}

{
   /*
      <Card>
         <Card.Header
            title="Helpfuls"
            thumb={<QuestionCircleOutlined />}
            extra={
               <Button
                  className="cardMenuButton"
                  onClick={(e) => onMenuClick()}
               >
                  <EllipsisOutlined rotate="90"/>
               </Button>
            }
         />
         <Card.Body>
         </Card.Body>
         <Card.Footer
            extra={
               <NavLink
                  to={"/helpfuls"}
                  onClick={(e) => {
                     onClickNavLink();
                  }}
               >
                  Click here for more
               </NavLink>
            }
         />
      </Card>

*/
}
