import React from "react";
import { useSelector } from "react-redux";
import { useLoaderData, useNavigate } from "react-router-dom";
import BrowseCategoryList from "../components/BrowseCategoriesList";
import CategoryFrufru from "../components/CategoryFrufru";
import { loadCategoriesData } from "../utils/CategoriesPageUtils";
import { getDB } from "../utils/UseLocalDataStore";
import CategoryCallbacks from "../utils/CategoryCallbacks";

import "./BrowseCategoriesPage.css";

/* default data loader for this page */
export async function loader() {
   console.log(`BrowseCategoriesPage: loader() called`);

   const db = getDB();
   let browseCategoriesArray = await loadCategoriesData(db);

   return browseCategoriesArray;
}

export default function BrowseCategoriesPage() {
   const navigate = useNavigate();
   const browseCategoriesArray = useLoaderData();
   const settings = useSelector((state) => state.settings);

   function onCategoryClick(e, slug) {
      //navigate("/category/"+slug);
      CategoryCallbacks.handleOnCategoryClick(slug, navigate);
   }

   return (
      <div className="BrowseCategoriesPage">
         {settings.simplifiedUI ? (
            <BrowseCategoryList
               browseCategoriesArray={browseCategoriesArray}
               onCategoryClick={onCategoryClick}
            />
         ) : (
            <div className="bubbleList">
               <h2>Browse by Category</h2>
               {browseCategoriesArray.map((category) => (
                  <CategoryFrufru
                     inset={true}
                     key={category.slug}
                     category={category}
                     onCategoryClick={onCategoryClick}
                  />
               ))}
            </div>
         )}
      </div>
   );
}
