class SolarCycleCalendar {
   static #epochYearGregorian = 2019;
   static #epochSolarCycle = 25;
   static #solarCycleLength = 11;

   /*
   static #solarFlareIntensity = {
      1:10,
      2:20,
      3:40,
      4:60,
      5:80,
      6:100,
      7:80,
      8:60,
      9:40,
      10:20,
      11:10,
   };
   */

   /* returns the year num for a given year, ex. 1 for 2019 */
   static getYearNumForGregorianYear(gregorianYear) {
      if (gregorianYear >= this.#epochYearGregorian) {
         return (
            ((gregorianYear - this.#epochYearGregorian) %
               this.#solarCycleLength) +
            1
         );
      } else {
         return (
            this.#solarCycleLength -
            ((this.#epochYearGregorian - gregorianYear - 1) %
               this.#solarCycleLength)
         );
      }
   }

   /* returns the solar cycle for a given year, ex. 25 for 2019 */
   static getSolarCycleForGregorianYear(gregorianYear) {
      if (gregorianYear >= this.#epochYearGregorian) {
         return (
            this.#epochSolarCycle +
            Math.trunc(
               (gregorianYear - this.#epochYearGregorian) /
                  this.#solarCycleLength
            )
         );
      } else {
         return (
            (this.#epochSolarCycle - Math.ceil((this.#epochYearGregorian - gregorianYear) / this.#solarCycleLength))
         );
      }
   }

   /*
   static getSolarFlareIntensityForNum(cycleYearNum) {
      return this.#solarFlareIntensity[cycleYearNum];
   }
   */
}

export default SolarCycleCalendar;
