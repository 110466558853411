import React from "react";
import ActionSheetMenu from "./ActionSheetMenu";
import { Divider, Menu } from "antd";
import { List } from "antd-mobile";
import {
   SettingOutlined,
   ReloadOutlined,
   WifiOutlined,
   LockOutlined,
} from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import ProfileListItem from "./ProfileListItem";
import "./ToolBarActionSheetMenu.css";

const TermsOfServiceLink = "https://www.blueflame47.com/terms-of-service/";
const PrivacyPolicyLink = "https://www.blueflame47.com/privacy/";

function ToolBarActionSheetMenu({
   online,
   title,
   visible,
   profile,
   onSettingsClick,
   onReloadClick,
   onCloseClick,
   onProfileClick,
}) {
   return (
      <ActionSheetMenu
         title={title}
         className="ToolBarActionSheetMenu"
         visible={visible}
         onCloseClick={onCloseClick}
      >
         <List>
            <ProfileListItem
               profile={profile}
               onClick={online && onProfileClick}
               disabled={!online}
            />
         </List>
         <Menu>
            <Menu.Item
               key={uuidv4()}
               disabled={true}
               icon={<WifiOutlined />}
               className="unselectable"
            >
               {online ? "You are Online" : "You are Offline"}
            </Menu.Item>

            <Menu.Item
               key={uuidv4()}
               icon={<LockOutlined />}
               disabled={!online && !profile.loggedIn}
               onClick={(e) => onProfileClick(e, profile)}
            >
               {profile.loggedIn ? "Logout" : "Login"}
            </Menu.Item>

            <Menu.Item
               key={uuidv4()}
               icon={<SettingOutlined />}
               onClick={(e) => onSettingsClick(e)}
            >
               Settings
            </Menu.Item>
            <Menu.Item
               key={uuidv4()}
               disabled={!online}
               icon={<ReloadOutlined />}
               onClick={(e) => onReloadClick(e)}
            >
               Resync
            </Menu.Item>

            <Divider key="divider1" />

            <Menu.Item key={uuidv4()} className="MenuItemLink">
               <a target="_new" href={TermsOfServiceLink}>
                  Terms of Service
               </a>
            </Menu.Item>

            <Menu.Item key={uuidv4()} className="MenuItemLink">
               <a target="_new" href={PrivacyPolicyLink}>
                  Privacy Policy
               </a>
            </Menu.Item>
         </Menu>
      </ActionSheetMenu>
   );
}

export default ToolBarActionSheetMenu;
