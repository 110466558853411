import React from "react";
import Logo from "../images/BF47_LandscapeLogo_Tagline_Under_alt.png";
import "./SplashPage.css";

function SplashPage(props) {
   return (
      <div className="SplashScreen">
         <img src={Logo} className="logo" alt="Blue Flame Logo" />
      </div>
   );
}

export default SplashPage;
