import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocalDataStore } from "../utils/UseLocalDataStore";
import { isAppInstalled, isSafariBrowser } from "../utils/BrowserUtils";
import loadStartPageData from "../utils/StartPageLoader";
import {
   SettingsInitialState,
   replaceSettings,
   reset as resetSettings,
} from "../features/settingsSlice";
import {
   setSessionInfoFromLocalStorage,
   setAvatarData,
   refreshAccountDetails,
} from "../features/accountSlice";
import {
   setAppInitialized,
   setInstallAppVisible,
   setSplashScreenVisible,
   setHeaderVisible,
   setToolbarVisible,
   setMainSearchVisible,
   setStorage,
   setTopArticleSeq,
} from "../features/mainPageSlice";
import DbRefreshUtils from "../utils/DbRefreshUtils";
import LocalStorage from "../utils/LocalStorage";
import {
   applyDefaultsForMissingSettings,
   GUEST_USER_ID,
} from "../utils/SettingsUtils";
import { Toast } from "antd-mobile";
import {
   getAccountAvatarAPI,
   getLoginUserInfoAPI,
} from "../services/AuthenticationAPI";

const SPLASH_SCREEN_WAIT_MS = 3000;

// these need to be globals, we don't want them to change on a render() call
let splashTimer = undefined;
let dbloading = false;
let settinz = undefined;

/** loads the data for this page */
export async function loader({ request }) {
   console.log(`App loader() called`);

   return null;
}

export default function App({ children }) {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const db = useLocalDataStore();
   const initialized = useSelector((state) => state.mainPage.appInitialized);
   const topArticleSeq = useSelector((state) => state.mainPage.topArticleSeq);

   // const [splashTimer, setSplashTimer] = useState(undefined);
   // const [dbloading, setDbloading] = useState(false);

   useEffect(() => {
      console.log(
         `App.useEffect() was called, initialized=${initialized}, dbloading=${dbloading}, topArticleSeq=${topArticleSeq}`
      );

      function dismissSplashScreen() {
         console.log("dismissSplashScreen() called", settinz);
         Toast.clear();
         dispatch(setSplashScreenVisible(false));
         dispatch(setHeaderVisible(true));
         dispatch(setMainSearchVisible(true));
         dispatch(setToolbarVisible(true));

         // ~~~ TODO
         // If Login becomes REQUIRED, this loadStartPageData() should be
         // removed from here, and only called after successful login.

         if (settinz) {
            loadStartPageData(settinz, db, dispatch, navigate, topArticleSeq);
         }
      }

      function startSplashScreenTimer() {
         //give time for splash screen to be seen
         splashTimer = setTimeout(async () => {
            // the splash screen timeout has elapsed
            console.log(
               `Splash screen timer has elapsed. dbloading=${dbloading}`
            );
            splashTimer = undefined;
            if (!dbloading) {
               dismissSplashScreen();
            } else {
               Toast.show({
                  content: `Downloading Articles...`,
                  icon: "loading",
                  duration: 300000,
               });
            }
         }, SPLASH_SCREEN_WAIT_MS);
      }

      async function startApp() {
         console.log(
            `App.startApp() was called initialized=${initialized}, dbloading=${dbloading}`
         );

         const previousMaxArticleSeq = LocalStorage.getMaxArticleSeq();
         console.log(
            `DEBUG: previousMaxArticleSeq=${previousMaxArticleSeq} from LocalStorage`
         );

         if (!isAppInstalled() && isSafariBrowser()) {
            dispatch(setInstallAppVisible(true));
         }

         // mandatory on-demand db resync during startup
         dbloading = true;

         startSplashScreenTimer();
         let p = DbRefreshUtils.processDbSync_main(db, dispatch);

         // allSettled() is called after all of the given promises have either fulfilled or rejected
         let status = await Promise.allSettled([p]);
         console.log(
            `*********** DB RESYNC SETTLED status=${status[0].status}, needed?=`,
            status[0].value
         );

         //console.log(`status length = ${status.length}`);
         //console.log( `status[0].status = ${"fulfilled" === status[0].status}` );

         // if (splashTimer === undefined) {
         //    console.log("SplashTimer says undefined, so dismissing splash screen...");
         //    dismissSplashScreen();
         //    Toast.hide();
         // }

         if (status.length > 0 && "fulfilled" === status[0].status) {
            let userId = GUEST_USER_ID;

            // used by WhatsNewCard - only if NOT after an initial db install
            if (
               typeof previousMaxArticleSeq !== "undefined" &&
               previousMaxArticleSeq !== null
            ) {
               console.log(
                  `dispatching previousMaxArticleSeq=${previousMaxArticleSeq}`
               );
               dispatch(setTopArticleSeq(previousMaxArticleSeq));
            }

            Toast.clear();

            // get cached login session
            let currentSessionInfo = LocalStorage.getCurrentSessionInfo();
            if (currentSessionInfo) {
               // console.log(
               //    "currentSessionInfo from LocalStorage:",
               //    currentSessionInfo
               // );

               userId =
                  currentSessionInfo.userId !== undefined
                     ? currentSessionInfo.userId
                     : GUEST_USER_ID;

               if (
                  currentSessionInfo.accessToken &&
                  currentSessionInfo.refreshToken &&
                  userId
               ) {
                  // this works even if offline
                  dispatch(setSessionInfoFromLocalStorage(currentSessionInfo));

                  Toast.show({
                     content: `Logging in...`,
                     icon: "loading",
                     duration: 30000,
                  });
   
                  try {
                  // only works if online
                  const response = await getLoginUserInfoAPI()
                  if (response.data) {
                     dispatch(refreshAccountDetails(response.data));
                  }

                  // only works if online
                  getAccountAvatarAPI(currentSessionInfo.userId).then(
                     (avatarUUencoded) => {
                        dispatch(setAvatarData(avatarUUencoded));
                     }
                  );
                  } catch (error) {
                     Toast.show({
                        content: error,
                        icon: "fail",
                        duration: 5000,
                     });
                     }
               }
            }

            if (navigator.storage && navigator.storage.estimate) {
               navigator.storage.estimate().then((quota) => {
                  const remaining =
                     ((quota.quota - quota.usage) / quota.quota) * 100;
                  //console.log("DEBUG quota:", quota);
                  dispatch(
                     setStorage({
                        bytesUsed: Math.round(quota.usage / 1000000),
                        bytesTotal: Math.round(quota.quota / 1000000),
                        percentRemaining: Math.round(remaining),
                     })
                  );
               });
            } else {
               console.log("DEBUG: navigator.storage API not supported.");
            }

            try {
               let settings = await db.getSetting(userId);
               console.log(
                  `db.getSetting() resolved with value for userId=${userId}`,
                  settings
               );
               if (settings) {
                  applyDefaultsForMissingSettings(settings);
                  //console.log("DB SETTINGS:", settings);
                  dispatch(replaceSettings(settings));
               } else {
                  // load default session from defaults
                  settings = SettingsInitialState;
                  dispatch(resetSettings());
               }
               settinz = settings;
               //loadStartPageData(settings, db, dispatch, navigate, topArticleSeq);
               dbloading = false;
               console.log(`Database load is done. splashTimer=${splashTimer}`);
               if (splashTimer === undefined || settings.fasterStartup) {
                  dismissSplashScreen();
               }
            } catch (error) {
               console.log(
                  `db.getSetting() not found. userId=${userId}, splashTimer=${splashTimer}, error=${error}`
               );
               // load default session from defaults
               dbloading = false;
               let settings = SettingsInitialState;
               dispatch(resetSettings());
               settinz = settings;
               //loadStartPageData(settings, db, dispatch, navigate, topArticleSeq);
               if (splashTimer === undefined || settings.fasterStartup) {
                  dismissSplashScreen();
               }
            }
         } else {
            // DbSync FAILED!
            let reason =
               "rejected" === status[0].status
                  ? status[0].reason
                  : status[0].value;
            Toast.show({
               content: `Database failed to download ERR= ${reason}. Please try again`,
               icon: "fail",
               duration: 30000,
            });
         }

         // we've either succeeded or failed by this point.
         console.log("INITIALIZED!");
         dispatch(setAppInitialized());
      }

      if (!initialized && !dbloading) {
         startApp();
      }

      // return function cleanup() {
      //    console.log(
      //       `>>>>>>>>>>>>>>>>>>>>>>>>>> APP.useEffect().cleanup(initialized=${initialized}, dbloading=${dbloading}) CALLED <<<<<<<<<<<<<<<<<<<<<<<<<`
      //    );
      // };
   }, [initialized, dispatch, navigate, db, topArticleSeq]);

   return <>{children}</>;
}
