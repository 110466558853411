import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLoaderData, useNavigate } from "react-router-dom";
import {useLocalDataStore, getDB} from "../utils/UseLocalDataStore";
import ArticlesList from "../components/ArticlesList";
import ArticleListHeader from "../components/ArticleListHeader";
import NoResults from "../components/NoResults";
import BrowseCategoryList from "../components/BrowseCategoriesList";
import CategoryFrufru from "../components/CategoryFrufru";
import ArticleCallbacks from "../utils/ArticleCallbacks";
import CategoryCallbacks from "../utils/CategoryCallbacks";
import { toggleFavorite } from "../utils/SettingsUtils";
import "./CategoryPage.css";
import "../components/CategoryFrufru.css";
import "../components/ArticleListHeader.css";

/* default loader for this page */
export async function loader({ params }) {
   console.log(`CategoryPage.loader() called slug=${params.slug}`);
   const db = getDB();
   let results = await CategoryCallbacks.loadFullCategoryDataPromise(params.slug, db);
   console.log(results);
   return [results.category,results.subCategoriesArray,results.articlesArray];
}

/** The CATEGORY page actually shows either
 * a list of subcategory topics, or a list of
 * articles depending on what the children of the category are.
 */
export default function CategoryPage() {
   const [category,subCategoriesArray,articlesArray] = useLoaderData();
   const online = useSelector((state) => state.mainPage.online);
   const settings = useSelector((state) => state.settings);
   const currentUser = useSelector((state) => state.account);
   const db = useLocalDataStore();
   const navigate = useNavigate();
   const dispatch = useDispatch();

   function onArticleClick(e, item) {
      //console.log(`DEBUG: article clicked: ${item.slug}`);
      // navigate("/article/" + item.slug);
      ArticleCallbacks.handleOnArticleClick(item.slug, navigate);
   }

   function onCategoryClick(e, slug) {
      // navigate("/category/" + slug);
      CategoryCallbacks.handleOnCategoryClick(slug, navigate);
   }

   /* https://reactrouter.com/en/main/start/tutorial#mutations-without-navigation */
   function onStarClick(slug) {
      console.log(`onStarClick() slug=${slug}`);
      toggleFavorite(slug, settings, currentUser, db, dispatch, online);
   }

   function renderSubcategoryArray(
      settings,
      subCategoriesArray,
      onCategoryClick
   ) {
      return subCategoriesArray.length > 0 ? (
         settings.simplifiedUI ? (
            <BrowseCategoryList
               browseCategoriesArray={subCategoriesArray}
               onCategoryClick={onCategoryClick}
            />
         ) : (
            <React.Fragment>
               <div className="bubbleList">
                  {subCategoriesArray.map((category) => (
                     <CategoryFrufru
                        inset={true}
                        key={category.slug}
                        category={category}
                        onCategoryClick={onCategoryClick}
                     />
                  ))}
               </div>
            </React.Fragment>
         )
      ) : (
         ""
      );
   }

   return (
      <div className="CategoryPage">
         <ArticleListHeader
            category={category}
            onStarClick={onStarClick}
            currentUser={currentUser}
            online={online}
            settings={settings}
         />
         {category ? (
            <React.Fragment>
               {renderSubcategoryArray(
                  settings,
                  subCategoriesArray,
                  onCategoryClick
               )}
               {(!articlesArray || articlesArray.length === 0) &&
               (!subCategoriesArray || subCategoriesArray.length === 0) ? (
                  <NoResults instructions="There are no articles for this category" />
               ) : (
                  <ArticlesList
                     showHeader={false}
                     online={online}
                     settings={settings}
                     category={category}
                     articlesArray={articlesArray}
                     onArticleClick={onArticleClick}
                     onStarClick={onStarClick}
                     showCategoryName={false}
                     currentUser={currentUser}
                  />
               )}
            </React.Fragment>
         ) : (
            <NoResults instructions="This category does not exist" />
         )}
      </div>
   );
}
