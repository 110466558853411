import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StarOutlined } from "@ant-design/icons";
import { CardType } from "../models/CardType";
import { ToolBarButtonType } from "../models/ToolBarButtonType";
import { setActiveToolbarTab } from "../features/mainPageSlice";
import ArticleListItem from "../components/ArticleListItem";
import ArticleCallbacks from "../utils/ArticleCallbacks";
import CategoryCallbacks from "../utils/CategoryCallbacks";
import Card from "./Card";

export default function FavoritesCard() {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const favoritesItemList = useSelector((state) => state.cardExplorerPage.favoritesItemList);

   const ShowOnlyQuantity = 3;


   function createCardItems(itemList, onItemClick) {
      let results = [];

      if (itemList) {
         for (let i = 0; i < ShowOnlyQuantity && i < itemList.length; i++) {
            let item = itemList[i];
            if (item) {
               results.push(
                  <ArticleListItem
                     key={item.slug}
                     item={item}
                     onClick={onItemClick}
                     master={undefined}
                     showCategoryName={item.type === "A"}
                     showThumbnail={true}
                     showSummary={item.type === "A"}
                     showArrow={true}
                  />
               );
            }
         }
      }

      return results;
   }

   function onMenuClick(currentUser, settings) {
      console.log("onMenuClick()");
      navigate("/settings/cardexplorer");
   }

   function onClickNavLink(currentUser, settings) {
      //console.log("onClickNavLink()");
      dispatch(setActiveToolbarTab(ToolBarButtonType.FAVORITES));
      navigate("/favorites");
   }

   async function onItemClick(e, item) {
      // console.log(`DEBUG: onItemClick: ${item.slug}`);

      if (item.type === "A") {
         ArticleCallbacks.handleOnArticleClick(item.slug, navigate);
      } else {
         CategoryCallbacks.handleOnCategoryClick(item.slug, navigate);
      }
   }

   return (
      <Card
         key="FavoritesCard"
         className="FavoritesCard"
         headerIcon={<StarOutlined fill="#61678D" className="headerIcon" />}
         titleText={CardType.FAVORITES_CARD}
         onMenuIconClick={onMenuClick}
         onMoreClick={onClickNavLink}
      >
         {createCardItems(favoritesItemList, onItemClick)}
      </Card>
   );
}
