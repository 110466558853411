import React from "react";
import { Timeline } from "antd";
import { HelpfulsData } from "../utils/HelpfulsData";

import "../components/HelpfulsCard.css";
import "./HelpfulsPage.css";

/* default data loader for this page */
export function loader() {
   console.log(`HelpfulsPage: loader() called`);
   //T.B.D.
   return null;
}

function HelpfulsPage({
   settings,
   currentUser,
   articlesArray,
   title,
   slug,
   onArticleClick,
   onStarClick,
}) {
   const Item = Timeline.Item;

   function createCardItems(itemList) {
      let results = [];

      for (let i = 0; i < itemList.length; i++) {
         results.push(
            <Item key={i} color="green">
               {itemList[i]}
            </Item>
         );
      }

      return results;
   }

   return (
      <div className="HelpfulsPage">
         <h3>Helpfuls</h3>
         <div className="helpfulsContent">
            <Timeline>{createCardItems(HelpfulsData)}</Timeline>
         </div>
      </div>
   );
}

export default HelpfulsPage;
