import React,{useRef, useEffect} from "react";
import { Form, useNavigate, redirect } from "react-router-dom";
import { Button } from "antd-mobile";
import { callResetPasswordAPI } from "../utils/BackendAPI";
import { Toast } from "antd-mobile";
import "./FindYourAccountPage.css";
import "../components/Form.css";

/* default submit action for this page */
export async function action({request}) {
   console.log(`FindYourAccountPage: action() called`);
   let formData = await request.formData();
   let emailValue = formData.get("email");

   console.log(`onSubmit: emailValue=${emailValue}`);

   callResetPasswordAPI(emailValue)
      .then(async (response) => {
         //console.log(response);
         Toast.show({
            content: "A message has been sent to the email address on file.",
            duration: 6000,
            icon: "success",
         });
         return redirect("/categories");
      })
      .catch((error) => {
         Toast.show({
            content: `Could not connect to server: ${error}`,
            duration: 6000,
            icon: "fail",
         });
         console.log("Login received an error:", error);
         return null;
      });

     return redirect("/categories");
}

export default function FindYourAccountPage() {
   const navigate = useNavigate();
   const inputRef = useRef();

   useEffect(() => {
      inputRef.current.focus();
   });
      
   function onCancel() {
      navigate(-1);
   }

   return (
      <div className="FindYourAccountPage">
         <h2>Find Your Account</h2>
         <div className="FindAccountForm">
         <Form method="post" id="forgot-form">
            <p className="instruction">
               Please enter the email address for your account. An email will be
               sent to the address on file with a one-time-use link.
            </p>
            <p className="instruction">
               You will then be redirected to <b>Threaded</b> change your
               password.
            </p>
               <div className="field">
                  <label htmlFor="email">Email:</label>
                  <input
                     id="email"
                     name="email"
                     type="email"
                     aria-label="email address"
                     placeholder="me@myemail.com"
                     required={true}
                     minLength={5}
                     ref={inputRef}
                  />
               </div>
               <fieldset>
                  <Button className="cancelButton" onClick={(e) => onCancel(e)}>
                     Cancel
                  </Button>
                  <Button
                     name="submitButton"
                     className="submitButton"
                     type="submit"
                  >
                     Submit
                  </Button>
               </fieldset>
            </Form>
         </div>
      </div>
   );
}
