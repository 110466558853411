const LocalStorage = {
   ActionTypes: {
      DB_REFRESH_LAST_TIMESTAMP_KEY: "DB_REFRESH_LAST_TIMESTAMP_KEY",
      CURRENT_USERNAME: "CURRENT_USERNAME",
      CURRENT_USERID: "CURRENT_USERID",
      CURRENT_SESSION_TTL: "CURRENT_SESSION_TTL",
      INSTALLED_APP_VERSION: "INSTALLED_APP_VERSION",
      STARTUP_TIMESTAMP_MS: "STARTUP_TIMESTAMP_MS",
      MAX_ARTICLE_SEQ: "MAX_ARTICLE_SEQ",
      ACCESS_TOKEN: "ACCESS_TOKEN",
      REFRESH_TOKEN: "REFRESH_TOKEN",
   },

   getLocalStorage() {
      return window.localStorage;
   },

   getItem(key) {
      return this.getLocalStorage().getItem(key);
   },

   updateMaxArticleSeq(seq) {
      this.getLocalStorage().setItem(this.ActionTypes.MAX_ARTICLE_SEQ, seq);
   },

   getMaxArticleSeq() {
      return this.getItem(this.ActionTypes.MAX_ARTICLE_SEQ);
   },

   updateDbRefreshLastTimestamp(timestamp) {
      this.getLocalStorage().setItem(
         this.ActionTypes.DB_REFRESH_LAST_TIMESTAMP_KEY,
         timestamp
      );
   },

   getDbRefreshLastTimestamp() {
      let ts = parseInt(
         this.getLocalStorage().getItem(
            this.ActionTypes.DB_REFRESH_LAST_TIMESTAMP_KEY
         )
      );
      if (isNaN(ts)) ts = 0;
      return ts;
   },

   setStartupTimestampMS(timestampMillis) {
      this.getLocalStorage().setItem(
         this.ActionTypes.STARTUP_TIMESTAMP_MS,
         timestampMillis
      );
   },

   getStartupTimestampMS(timestampMillis) {
      let v = parseInt(
         this.getLocalStorage().getItem(this.ActionTypes.STARTUP_TIMESTAMP_MS)
      );
      if (isNaN(v)) v = 0;
      return v;
   },

   getCurrentUserId() {
      let id = parseInt(
         this.getLocalStorage().getItem(this.ActionTypes.CURRENT_USERID)
      );
      if (isNaN(id)) return undefined;
      return id;
   },

   getCurrentSessionInfo() {
      let ls = this.getLocalStorage();
      return {
         username: ls.getItem(this.ActionTypes.CURRENT_USERNAME),
         ttl: parseInt(ls.getItem(this.ActionTypes.CURRENT_SESSION_TTL)),
         userId: this.getCurrentUserId(),
         accessToken: this.getAccessToken(),
         refreshToken: this.getRefreshToken(),
      };
   },

   setCurrentSessionInfo(username, ttl, userId) {
      let ls = this.getLocalStorage();
      ls.setItem(this.ActionTypes.CURRENT_USERNAME, username);
      ls.setItem(this.ActionTypes.CURRENT_SESSION_TTL, ttl);
      ls.setItem(this.ActionTypes.CURRENT_USERID, userId);
   },

   removeCurrentSessionInfo() {
      let ls = this.getLocalStorage();
      ls.removeItem(this.ActionTypes.CURRENT_USERNAME);
      ls.removeItem(this.ActionTypes.CURRENT_USERID);
      ls.removeItem(this.ActionTypes.CURRENT_SESSION_TTL);
   },

   isVersionAcked(version) {
      let value = this.getLocalStorage().getItem(
         this.ActionTypes.INSTALLED_APP_VERSION
      );
      console.log(`value=${value}, checking=${version}`);
      return value === version;
   },

   acknowledgeVersion(version) {
      let ls = this.getLocalStorage();
      ls.setItem(this.ActionTypes.INSTALLED_APP_VERSION, version);
      ls.removeItem("0.4.19"); //obsolete key
   },

   setAccessToken(token) {
      let ls = this.getLocalStorage();
      ls.setItem(this.ActionTypes.ACCESS_TOKEN, token);
   },

   getAccessToken() {
      let ls = this.getLocalStorage();
      return ls.getItem(this.ActionTypes.ACCESS_TOKEN);
   },

   setRefreshToken(token) {
      let ls = this.getLocalStorage();
      ls.setItem(this.ActionTypes.REFRESH_TOKEN, token);
   },

   getRefreshToken() {
      let ls = this.getLocalStorage();
      return ls.getItem(this.ActionTypes.REFRESH_TOKEN);
   },

};

export default LocalStorage;
