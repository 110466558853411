import React from "react";
// import { List } from "antd-mobile";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { RightOutline } from "antd-mobile-icons";
import "./ProfileListItem.css";

// const Item = List.Item;

export default function ProfileListItem({
   profile,
   onClick,
   showThumbnail = true,
   showUsername = true,
   showArrow = true,
}) {
   const AvatarImageSize = 52;

   function getAvatar(profile) {
      return profile.loggedIn === false ? (
         <Avatar
            className="guest-profile"
            size={AvatarImageSize}
            icon={<UserOutlined />}
         />
      ) : (
         <Avatar
            className="avatar"
            src={profile.base64data}
            size={AvatarImageSize}
            icon={<UserOutlined />}
         />
      );
   }

   function getRightArrow() {
      return <RightOutline className="navIcon" />;
   }

   return (
      <div className="ProfileListItem" key={profile.userId} onClick={() => onClick()}>
         {showThumbnail ? getAvatar(profile) : null}
         <div className="content">
            <div className="header">
               <div className="title">
                  {profile.loggedIn ? profile.longDisplayName : "Guest"}
               </div>
               <div className="extra"></div>
            </div>
            <div className="body">
               {" "}
               {showUsername
                  ? profile.loggedIn
                     ? "@" + profile.username
                     : ""
                  : "@guest"}
            </div>
         </div>
         {showArrow ? getRightArrow() : ""}
      </div>
   );
}
