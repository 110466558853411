import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PodcastOutlined } from "../icons/podcast-icon.svg";
import { CardType } from "../models/CardType";
import { isIPhone } from "../utils/BrowserUtils";
import { Toast } from "antd-mobile";
import PodcastLogoImage from "../images/Blue-Army-Radio_minified-1.jpg";
import Card from "./Card";
import "./BlueArmyRadioCard.css";

export default function BlueArmyRadioCard() {
   const navigate = useNavigate();
   const online = useSelector((state) => state.mainPage.online);
   const smoresRef = useRef();

   function onMenuClick() {
      console.log("onMenuClick()");
      navigate("/settings/cardexplorer");
   }

   const ApplePodcastShowLink =
      "https://podcasts.apple.com/us/podcast/blue-army-radio/id1416163976?mt=2&uo=4";
   const WebsitePodcastShowLink = "https://bluearmy.blueflame47.com/";

   function failToast() {
      Toast.show({
         content: "You must be online to perform this action",
         duration: 5000,
         icon: "fail",
      });
   }

   function onClickNavLink() {
      console.log("onClickNavLink()");

      if (online) {
         smoresRef.current.click();
      } else {
         failToast();
      }
   }

   return (
      <Card
         key="BlueArmyRadioCard"
         className="BlueArmyRadioCard"
         headerIcon={<PodcastOutlined className="headerIcon" />}
         titleText={CardType.BLUEARMY_RADIO_CARD}
         onMenuIconClick={onMenuClick}
         onMoreClick={onClickNavLink}
      >
         <>
            <div className="cardContent">
               {isIPhone() ? (
                  <a
                     className="imageButton"
                     target="_new"
                     href={ApplePodcastShowLink}
                  >
                     <img src={PodcastLogoImage} alt={"Blue Army Radi"} />
                  </a>
               ) : online ? (
                  <a
                     className="imageButton"
                     target="_new"
                     href={WebsitePodcastShowLink}
                  >
                     <img src={PodcastLogoImage} alt={"Blue Army Radi"} />
                  </a>
               ) : (
                  <button onClick={(e) => failToast()} className="imageButton">
                     <img src={PodcastLogoImage} alt={"Blue Army Radi"} />
                  </button>
               )}
            </div>
            <a
               ref={smoresRef}
               target="_new"
               className="hiddenLink"
               href={WebsitePodcastShowLink}
            >
               Hidden link
            </a>
         </>
      </Card>
   );
}
