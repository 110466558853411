const multiHyphenPattern = new RegExp("-{2,}", "g");
const hyphenEOLPattern = new RegExp("-+$");
const hyphenBOLPattern = new RegExp("^-+");

const ArticleUtils = {

   /* blobToData is async */
   blobToData(blob) {
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsDataURL(blob)
      })
    },
   cleanupSlug(slug) {
      return slug
         .replace(hyphenBOLPattern, "")
         .replace(hyphenEOLPattern, "")
         .replace(multiHyphenPattern, "-");
   },
   alphabetArray() {
      return "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
   },
   numberArray() {
      return "0123456789".split("");
   },
   lettersAndNumbersArray() {
      return this.alphabetArray().concat(this.numberArray());
   },
   itemSlugExistsInArray(item, theArray) {
      let found = false;
      for (let i = 0; i < theArray.length; i++) {
         //console.log(`item.slug=${item.slug}`, theArray[i]);
         if (
            theArray[i].slug === item.slug ||
            theArray[i].slug1 === item.slug ||
            theArray[i].slug2 === item.slug
         ) {
            found = true;
            break;
         }
      }
      return found;
   },
   isUppercase(c) {
      return c === c.toUpperCase();
   },
   isLetter(str) {
      return str.length === 1 && str.match(/[a-z]/i);
   },
   getTimestampMillis() {
      return new Date().getTime(); //milliseconds
   },
   getTimestampSeconds() {
      return Math.round(new Date().getTime() / 1000);
   },
   extractRelatedToSlugList(slug, relationArray) {
      let others = [];
      relationArray.forEach((r) => {
         if (r.slug1 === slug) others.push(r.slug2);
         else if (r.slug2 === slug) others.push(r.slug1);
      });
      return others;
   },
   remove(ary, item) {
      let i = ary.indexOf(item);
      if (i >= 0) {
         ary.splice(i, 1);
      }
   },
   createMasterIndexMap(flatArray) {
      let masterIndexMap = {};
      let alpha = this.lettersAndNumbersArray();

      // initialize the map for each letter
      alpha.forEach((x) => {
         masterIndexMap[x] = [];
      });

      // iterate over the beheamouth
      let key1, key2;
      flatArray.forEach((r) => {
         key1 = r.name.charAt(0);
         masterIndexMap[key1].push(r);

         if (r.alias) {
            key2 = r.alias.charAt(0);

            if (key1 !== key2) {
               masterIndexMap[key2].push(r);
            }
         }
      });

      // sort each letter's content array by name/alias
      alpha.forEach((x) => {
         masterIndexMap[x] = masterIndexMap[x].sort(function (a, b) {
            if (a.alias && a.alias.charAt(0) === x) {
               if (b.alias && b.alias.charAt(0) === x) {
                  return a.alias > b.alias;
               } else {
                  return a.alias > b.name;
               }
            } else if (b.alias && b.alias.charAt(0) === x) {
               return a.name > b.alias;
            }

            return a.name > b.name ? 1 : -1;
         });
      });

      return masterIndexMap;
   },
};

export default ArticleUtils;
