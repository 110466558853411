import { PageType } from "../models/PageType";
import { setActiveToolbarTab } from "../features/mainPageSlice";
import { ToolBarButtonType } from "../models/ToolBarButtonType";
import { loadCardExplorerPage } from "../utils/CardExplorerUtils";
import { loadFavoritesData } from "./SettingsUtils";
import { loadCategoriesPage } from "../utils/CategoriesPageUtils";

/** This function is responsible for redirecting (navigating) the user to
 * the initial "start" page outlet content. Everything must be initialized
 * before this is called, i.e. db, settings, login (if available), etc.
 */
export default function loadStartPageData(
   settings,
   db,
   dispatch,
   navigate,
   topArticleSeq
) {
   console.log(`loadStartPageData() topArticleSeq=${topArticleSeq} settings=`,settings);
   if (settings) {
      switch (settings.startPage) {
         case PageType.FAVORITES_PAGE:
            dispatch(setActiveToolbarTab(ToolBarButtonType.FAVORITES));
            loadFavoritesData(settings, db, dispatch);
            return navigate("/favorites", { replace: true });

         case PageType.CATEGORIES_PAGE:
            dispatch(setActiveToolbarTab(ToolBarButtonType.CATEGORIES));
            loadCategoriesPage(db, dispatch, navigate);
            return navigate("/categories", { replace: true });

         default:
            dispatch(setActiveToolbarTab(ToolBarButtonType.EXPLORER));
            loadCardExplorerPage(settings, db, dispatch, topArticleSeq);
            return navigate("/cardexplorer", { replace: true });
      }
   }
}
