import ArticleCallbacks from "./ArticleCallbacks";
import SolarCycleCalendarCallbacks from "./SolarCycleCalendarCallbacks";
import {
   setCalendarCard,
   setFavoritesCardData,
   setWhatsNewArticleList,
} from "../features/cardExplorerPageSlice";
//import Hebcal from "hebcal";
import { HDate } from "@hebcal/core";
import { CardType } from "../models/CardType";
import { loadFavoritesData } from "./SettingsUtils";

//Nisan, Iyyar, Sivan, Tamuz, Av, Elul, Tishrei, Cheshvan, Kislev, Tevet, Shvat, Adar (1, 2)

const hebMonthMap = {
   Nisan: "Nisan",
   Iyyar: "Iyar",
   Sivan: "Sivan",
   Tamuz: "Tammuz",
   Av: "Av",
   Elul: "Elul",
   Tishrei: "Tishrei",
   Cheshvan: "Cheshvan",
   Kislev: "Kislev",
   Tevet: "Tevet",
   Shvat: "Shevat",
   Adar: "Adar",
   "Adar 1": "Adar I",
   "Adar 2": "Adar II",
};

export function prepArticleList(articleList, db, dispatch) {
   return new Promise(async (resolve, reject) => {
      if (articleList.length > 0) {
         let promises = [];

         articleList.forEach((a) => {
            a.type = "A";
            a.id = a._id;
            delete a._id;
            promises.push(ArticleCallbacks.fetchFeaturedImageData(a, db));
         });

         Promise.all(promises)
            .then((result) => {
               dispatch(setWhatsNewArticleList(articleList));
               resolve(result);
            })
            .catch((error) => {
               //console.log("Error fetching image information: ", error);
               reject(error);
            });
      } else {
         dispatch(setWhatsNewArticleList([]));
         // there are no artciles in the input parameter list
         resolve();
      }
   });
}

async function loadLookingUpCalendarCard(db, dispatch) {
   //https://github.com/hebcal/hebcal-js
   let hdate = new HDate(new Date());
   let monthName = hdate.getMonthName().replace(/'/g, "");

   console.log(`>>> hebCal monthName=${monthName}`);

   let articleName = hebMonthMap[monthName];
   if (!articleName) articleName = hdate;

   console.log(`>>> articleName=${articleName}`);

   let image = await db.getImageFromArticleName(
      "Kislev" /*~~~ TODO articleName*/
   );

   console.log(`>>> image data length = ${image.length}`);
   let hebdate = hdate.toString();

   console.log(`>>> hebdate=${hebdate}, articleName=${articleName}`);

   dispatch(
      setCalendarCard({
         hebdate: hebdate,
         image: image,
         monthName: articleName,
      })
   );
}

export async function loadLookingUpCalendarPage(db, dispatch, navigate) {
   //https://github.com/hebcal/hebcal-js
   let hdate = new HDate(new Date());
   let monthName = hdate.getMonthName().replace(/'/g, "");
   let articleName = hebMonthMap[monthName];
   if (!articleName) articleName = hdate;

   //console.log(`DEBUG: monthName=${monthName}, articleName=${articleName}`);

   let article = await db.getArticleByName(articleName);

   ArticleCallbacks.handleOnArticleClick(article.slug, navigate);
}

export async function loadSolarCycleCalendarCard(dispatch) {
   SolarCycleCalendarCallbacks.loadSolarCycleCalendarCard(dispatch);
}

/* main function to load all */
export function loadCardExplorerPage(settings, db, dispatch, topArticleSeq) {
   if (isCardOn(settings, CardType.FAVORITES_CARD)) {
      loadFavoritesData(settings, db).then((favoritesItemList) => {
         dispatch(setFavoritesCardData(favoritesItemList));
      });
   }

   if (isCardOn(settings, CardType.ARTICLE_OF_MOMENT_CARD)) {
      db.getArticleCount().then((articleCount) => {
         if (articleCount && articleCount > 0) {
            ArticleCallbacks.loadArticleOfDay(db, articleCount, dispatch);
         }
      });
   }

   if (isCardOn(settings, CardType.IMAGE_OF_MOMENT_CARD)) {
      db.getImageCount().then((imageCount) => {
         if (imageCount && imageCount > 0) {
            ArticleCallbacks.loadImageOfDayArticle(db, imageCount, dispatch);
         }
      });
   }

   if (isCardOn(settings, CardType.WHATS_NEW_CARD)) {
      db.getArticlesAfterSeq(topArticleSeq).then((articleList) => {
         // console.log(`DEBUG: getArticlesAfterSeq(${topArticleSeq}, articleList.length=${articleList.length})`);
         prepArticleList(articleList, db, dispatch);
      });
   }

   if (isCardOn(settings, CardType.LOOKING_UP_CALENDAR_CARD)) {
      loadLookingUpCalendarCard(db, dispatch);
   }

   if (isCardOn(settings, CardType.SOLAR_CYCLE_CALENDAR_CARD)) {
      loadSolarCycleCalendarCard(dispatch);
   }
}

export function isCardOn(settings, cardId) {
   let card = settings.cardExplorer.cardIdList.find(
      (card) => card.id === cardId
   );
   return card && card.on;
}
