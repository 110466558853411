import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Button, List } from "antd-mobile";
import { ReactComponent as HamburglerIcon } from "../icons/hamburgler-icon.svg";
import NoResults from "../components/NoResults";
import ArticleCallbacks from "../utils/ArticleCallbacks";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import StrictModeDroppable from "../components/StrictModeDroppable";
import { getDB, useLocalDataStore } from "../utils/UseLocalDataStore";
import CategoryCallbacks from "../utils/CategoryCallbacks";
import { Toast } from "antd-mobile";

import "./ResequenceArticlesPage.css";

/* default data loader for this page */
export async function loader({ params }) {
   console.log(`ResequenceArticlesPage.loader() called slug=${params.slug}`);
   const db = getDB();
   let results = await CategoryCallbacks.loadArticleArrayForCategory(
      params.slug,
      db
   );
   //console.log(results);
   return results;
}

export default function ResequenceArticlesPage() {
   const db = useLocalDataStore();
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const [articlesArray, setArticlesArray] = useState(useLoaderData());

   /** rebuild data list after drag and drop */
   const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
   };

   /** final completion action when item is dropped */
   const onDragEnd = (result) => {
      if (!result.destination) return;

      console.log(`onDragEnd result=`, result);

      const newArticleList = reorder(
         articlesArray,
         result.source.index,
         result.destination.index
      );

      setArticlesArray(newArticleList);
   };

   function onDone() {
      Toast.show({ content: "Saving...", duration: 30000, icon: "loading" });

      ArticleCallbacks.resequenceArticleArray(articlesArray, db, dispatch)
         .then(() => {
            Toast.clear();
            navigate(-1);
         })
         .catch((error) => {
            Toast.show({ content: error, duration: 10000, icon: "failed" });
         });
   }

   /** make internal draggable payload contents */
   function makeEditRowContents(props) {
      return (
         <div className="editable-row">
            <span className="edit-text">
               {props.name} {props.alias ? "(" + props.alias + ")" : ""}
            </span>{" "}
            <HamburglerIcon className="hamburgler-icon" />
         </div>
      );
   }

   /** single Draggable wrapper */
   const DraggableListItem = (props) => {
      //console.log(`DEBUG: props.id=${props.id}`);
      return (
         <Draggable key={props.id} draggableId={props.id} index={props.index}>
            {(provided, snapshot) => (
               <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={{
                     ...provided.draggableProps.style,
                     opacity: snapshot.isDragging ? 0.8 : 1,
                  }}
               >
                  {makeEditRowContents(props.item)}
               </div>
            )}
         </Draggable>
      );
   };

   /** make required properties for each row */
   function makeItemProps(item, index) {
      return {
         id: item.slug,
         item: item,
         index: index,
      };
   }

   /** make array of draggable react components */
   function makeItemList(idList) {
      let itemList = idList.map((item, index) => (
         <DraggableListItem {...makeItemProps(item, index)} />
      ));

      return itemList;
   }

   return (
      <div className="ResequenceArticlesPage">
         {!articlesArray || articlesArray.length === 0 ? (
            <NoResults instructions="There are no articles for this category" />
         ) : (
            <List
               header={
                  <>
                     <div className="list-header">
                        <h3>Resequence Articles</h3>
                        <Button
                           onClick={onDone}
                           type="primary"
                           inline={true}
                           size="small"
                           className="am-button-borderfix"
                        >
                           Done
                        </Button>
                     </div>
                     <span className="instruction">
                        Drag and drop the list items to rearrange.
                     </span>
                  </>
               }
               className="my-list"
            >
               <DragDropContext onDragEnd={onDragEnd}>
                  <StrictModeDroppable droppableId="droppable">
                     {(droppableProvided) => (
                        <div ref={droppableProvided.innerRef}>
                           {makeItemList(articlesArray)}
                           {droppableProvided.placeholder}
                        </div>
                     )}
                  </StrictModeDroppable>
               </DragDropContext>
            </List>
         )}
      </div>
   );
}
