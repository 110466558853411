import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { NavBar, Button } from "antd-mobile";
import MainSearch from "./MainSearch";
import { ReactComponent as HamburglerIcon } from "../icons/hamburgler-icon.svg";
import { v4 as uuidv4 } from "uuid";
import { AppName } from "../utils/AppVersion";
import {
   setPageActionSheetVisible,
} from "../features/mainPageSlice";
import {useLocalDataStore} from "../utils/UseLocalDataStore";

import "./PageHeader.css";

export default function PageHeader() {
   const appTitle = AppName;
   const location = useLocation();
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const db = useLocalDataStore();

   const headerVisible = useSelector((state) => state.mainPage.mainSearch.headerVisible);
   const online = useSelector((state) => state.mainPage.online);

   function onHamburglerClick(e) {
      //console.log("onHamburglerClick() called");
      dispatch(setPageActionSheetVisible(true));
   }


   //console.log(`PAGE HEADER location.pathname: >>>${location.pathname}<<<`);

   return headerVisible ? (
      <div className={online ? "PageHeader online" : "PageHeader offline"}>
         <NavBar
            className="main-header"
            backArrow={"/" !== location.pathname && "/login" !== location.pathname ? 
                  true
                : <img
                     src={require(`../icons/shield_icon_48x48.png`)}
                     className="ShieldIcon"
                     alt="Shield"
                  />
            }
            onBack={() => {
               if (location.pathname !== "/") {
                  navigate(-1);
               }
            }}
            mode="dark"
            right={[
               location.pathname !== "/login" ? (
                  <Button
                     key={uuidv4()}
                     className="hamburgler-button"
                     onClick={(e) => onHamburglerClick(e)}
                  >
                     <HamburglerIcon />
                  </Button>
               ) : (
                  ""
               ),
            ]}
         >
            {appTitle}
         </NavBar>
         <MainSearch db={db} />

      </div>
   ) : (
      ""
   );
}
