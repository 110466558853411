import ActionSheetMenu from "./ActionSheetMenu";
import { useNavigate } from "react-router-dom";
import { useLocalDataStore } from "../utils/UseLocalDataStore";
import { useSelector, useDispatch } from "react-redux";
import { setShowSettingsActionSheetMenu } from "../features/mainPageSlice";
import { GUEST_USER_ID, replaceLocalSettings } from "../utils/SettingsUtils";
import {
   setMainSearchVisible,
   setToolbarVisible,
} from "../features/mainPageSlice";
//import { Menu } from "antd-mobile";
import { Divider, Menu } from "antd";
import "./GuestActionSheetMenu.css";




export default function GuestActionSheetMenu({ visible = false, title }) {
   const settings = useSelector((state) => state.settings);
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const db = useLocalDataStore();

   const showSettingsActionSheetMenu = useSelector(
      (state) => state.mainPage.showSettingsActionSheetMenu
   );

   function redirectToLogin(dispatch, navigate) {
      dispatch(setShowSettingsActionSheetMenu(false));
      dispatch(setMainSearchVisible(false));
      dispatch(setToolbarVisible(false));
      navigate("/login");
   }

   function continueAsGuest(settings, db, dispatch) {
      dispatch(setShowSettingsActionSheetMenu(false));
      let newSettings = Object.assign({}, settings, {
         userId: GUEST_USER_ID,
      });
      replaceLocalSettings(newSettings, db, dispatch);
   }   
   

   return (
      <ActionSheetMenu
         title={title}
         visible={showSettingsActionSheetMenu || visible}
         // closeOnAction={true}
         // popupClassName="GuestActionSheetMenu"
         className="GuestActionSheetMenu"
         // cancelText="Cancel"
         onCloseClick={() => continueAsGuest(settings, db, dispatch)}
         // actions={[
         //    {
         //       key: "login",
         //       text: "Login",
         //       onClick: () => {
         //          redirectToLogin(dispatch, navigate);
         //       },
         //    },
         //    {
         //       key: "guest",
         //       text: "Continue as Guest",
         //       onClick: () => {
         //          continueAsGuest(settings, db, dispatch);
         //       },
         //    },
         // ]}
         message=" You are not logged in. If you continue as Guest your items will only be available locally on this device. If you login to your account, your settings will be available on all of your devices."
         // extra={
         //    <>
         //       <h2>{title}</h2>
         //       <span>

         //       </span>
         //    </>
         // }
      >
         <Menu>
         <Divider/>
            <Menu.Item
               key="login"
               onClick={(e) => redirectToLogin(dispatch, navigate)}
            >
               Login
            </Menu.Item>
<Divider/>
            <Menu.Item
               key="guest"
               onClick={(e) => continueAsGuest(settings, db, dispatch)}
            >
               Continue as Guest
            </Menu.Item>
         </Menu>
      </ActionSheetMenu>
   );
}

/*
export function showGuestAccessActionSheet(settings, db, dispatch, navigate) {
    ActionSheet.show({
       popupClassName: "GuestActionSheetMenu",
       actions: [
          {
             key: "login",
             text: "Login",
             onClick: () => {
                redirectToLogin(dispatch, navigate);
             },
          },
          {
             key: "guest",
             text: "Continue as Guest",
             onClick: () => {
                continueAsGuest(settings, db, dispatch);
             },
          },
       ],
       closeOnAction: true,
       cancelText: "Cancel",
       title: "Update Settings",
       extra: (
          <>
             {" "}
             <h2>Explorer Card Settings</h2>{" "}
             <span>
                {" "}
                You are not logged in. If you continue as Guest your items will
                only be available locally on this device. If you login to your
                account, your settings will be available on all of your devices.{" "}
             </span>{" "}
          </>
       ),
    });
 }
    */
