import { setActiveToolbarTab } from "../features/mainPageSlice";
import { ToolBarButtonType } from "../models/ToolBarButtonType";

export async function loadCategoriesData(db) {
   return new Promise((resolve, reject) => {
      try {
         db.fillBrowseCategoriesArray((browseCategoriesArray) => {
            //console.log("indexjs.fillBrowseCategoriesArray() called. Dispatching fetchCategoriesList()" );
            resolve(browseCategoriesArray);
         });
      } catch (error) {
         reject(error);
      }
   });
}

export function loadCategoriesPage(db, dispatch, navigate) {

   /* BrowseCategoriesPage.loader() loads the actual data */
   dispatch(setActiveToolbarTab(ToolBarButtonType.CATEGORIES));

   if (navigate) {
      navigate("/categories");
   }

   /*
   loadCategoriesData(db).then((browseCategoriesArray) => {
      if (dispatch) {
         dispatch(setActiveToolbarTab(ToolBarButtonType.CATEGORIES));
         dispatch(fetchCategoriesList(browseCategoriesArray));
      }

      if (navigate) {
         navigate("/categories");
      }
   });
   */
}
