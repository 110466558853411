import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
   setMainSearchVisible,
   setToolbarVisible,
} from "../features/mainPageSlice";

/** This ReactJS component 'listens' for logout events and
 * dispatches to the LoginPage automatically when the account
 * becomes logged out.
 */
export default function LoginPageRedirector() {
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const appIsInitialized = useSelector(
      (state) => state.mainPage.appInitialized
   );
   const online = useSelector((state) => state.mainPage.online);
   const loggedIn = useSelector((state) => state.account.loggedIn);
   const sessionExpired = useSelector((state) => state.account.sessionExpired);

   useEffect(() => {
      if (appIsInitialized && sessionExpired && loggedIn === false && online) {
         console.log(
            "LoginPageRedirector detected a logged out account.  Redirecting to LoginPage /login"
         );
         dispatch(setMainSearchVisible(false));
         dispatch(setToolbarVisible(false));
         navigate("/login");
      }
   }, [loggedIn, online, sessionExpired, appIsInitialized, dispatch, navigate]);

   return <></>;
}
