class JubileeCalendar {
   static #epochYearGregorian = 2015;
   static #jubileeYearCount = 50;

   static isGregorianYearJubilee(gregorianYear) {
      if (gregorianYear >= this.#epochYearGregorian) {
         return (
            (gregorianYear - this.#epochYearGregorian) %
               this.#jubileeYearCount ===
            0
         );
      } else {
         if (this.#epochYearGregorian > gregorianYear) {
            return (
               (this.#epochYearGregorian - gregorianYear) %
                  this.#jubileeYearCount ===
               0
            );
         }
      }
   }
}

export default JubileeCalendar;
