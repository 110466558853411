import React from "react";
import { List } from "antd-mobile";
import { v4 as uuidv4 } from 'uuid';

const Item = List.Item;

export default function BrowseCategoriesList({ browseCategoriesArray, onCategoryClick }) {
   
   /*   const [itemArray, setItemArray] = useState([]); */
   /*
   const handleBrowseCategorySelection = (e, slug) => {
      e.preventDefault();
      props.history.push("/category/" + slug);
   };
   */

   function createCategoryItem(item) {
      return (
         <Item
            key={uuidv4()}
            arrow="horizontal"
            onClick={(e) => onCategoryClick(e, item.slug)}
         >
            {item.name}
         </Item>
      );
   }

   return (
      <List
         renderHeader={() => "Browse categories..."}
         className="my-list"
      >
         {browseCategoriesArray.map((category) => (
            createCategoryItem(category)
         ))}
      </List>
   );
}

