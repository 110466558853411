import { createSlice } from "@reduxjs/toolkit";

export const SolarCycleCalendarInitialState = {
    cardData: [],
    pageData: [],
    pageYear: 0, 
};

export const solarCycleCalendarSlice = createSlice({
   name: "solarCycleCalendar",
   initialState: SolarCycleCalendarInitialState,
   reducers: {
      reset: () => {
         return SolarCycleCalendarInitialState;
      },
      setSolarCycleCalendarCardData: (state, action) => {
        state.cardData =  action.payload;
      },
      setSolarCycleCalendarPageData: (state, action) => {
        state.pageYear = action.payload.pageYear;
        state.pageData =  action.payload.pageData;
      },
   },
});

export const {
   reset,
   setSolarCycleCalendarCardData,
   setSolarCycleCalendarPageData,
} = solarCycleCalendarSlice.actions;

export default solarCycleCalendarSlice.reducer;
