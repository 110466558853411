import {
   setSolarCycleCalendarCardData,
   setSolarCycleCalendarPageData,
} from "../features/solarCycleCalendarSlice";
import { getSolarCycleData } from "./SolarCycleUtils";

const SolarCycleCalendarCallbacks = {
   loadSolarCycleCalendarCard(dispatch) {
      //console.log("SolarCycleCalendarCallbacks loadSolarCycleCalendarCard called");

      return new Promise((resolve, reject) => {
         try {
            //FIXME - hardcoding
            const notesMap = {
               2020: "Streching",
               2021: "Rising",
               2022: "Deepening",
            };

            let solarCalendarData = getSolarCycleData(
               new Date().getFullYear(),
               notesMap
            );
            dispatch(setSolarCycleCalendarCardData(solarCalendarData));

            resolve("");
         } catch (error) {
            reject(error);
         }
      });
   },

   loadSolarCycleCalendarPage(year, dispatch, navigate) {
      //console.log("SolarCycleCalendarCallbacks loadSolarCycleCalendarPage called");

      let solarCycleSizeYear = 11;

      return new Promise((resolve, reject) => {
         try {
            //FIXME - hardcoding
            const notesMap = {
               2020: "Streching",
               2021: "Rising",
               2022: "Deepening",
            };

            let selectedCycle = getSolarCycleData(
               year,
               notesMap
            );

            // an array of solar cycles
            let solarCyclesArray = [];
            const firstYearInSelectedCycle = selectedCycle[0].gregorianYear;

            // previous two cycles
            [ firstYearInSelectedCycle - (2*solarCycleSizeYear),
              firstYearInSelectedCycle - (solarCycleSizeYear)].forEach(function (y) {
               solarCyclesArray.push( getSolarCycleData(
                  y,
                  notesMap
               ));
              });

            // this selected cycle
            solarCyclesArray.push(selectedCycle);

            // subsequent two cycles
            [ firstYearInSelectedCycle + solarCycleSizeYear,
               firstYearInSelectedCycle + (2 * solarCycleSizeYear)].forEach(function (y) {
                solarCyclesArray.push( getSolarCycleData(
                   y,
                   notesMap
                ));
               });
 
            dispatch(setSolarCycleCalendarPageData({pageYear: year, pageData: solarCyclesArray}));

            if (navigate) {
               navigate("/solar-cycle-calendar/");
            }
            resolve("");
         } catch (error) {
            reject(error);
         }
      });
   },
};

export default SolarCycleCalendarCallbacks;
