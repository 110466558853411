import React from "react";
import { Image } from "antd-mobile";
import { RightOutline } from 'antd-mobile-icons'
import "./ArticleListItem.css";

export default function ArticleListItem({
   item,
   onClick,
   master,
   showCategoryName = true,
   showThumbnail = true,
   showSummary = true,
   showArrow = true,
}) {
   const imageUrl = getImageUrl(item);

   function getImageUrl(item) {
      if (
         item.imageSeqArray &&
         item.imageSeqArray.length > 0 &&
         item.imageSeqArray[0].base64data
      ) {
         return item.imageSeqArray[0].base64data;
      } else if (
         item.imageUrlArray &&
         item.imageUrlArray.length > 0 &&
         item.imageUrlArray[0].url
      ) {
         return item.imageUrlArray[0].url;
      } else {
         return "";
      }
   }

   function getImage(imageUrl) {
      return <Image src={imageUrl} className="avatar" fit="cover" />;
   }

   function getRightArrow() {
      return <RightOutline className="navIcon" />
   }

   function makeItemTitle(item) {
      return `${"C" === item.type ? "Category: " : ""} ${item.name} ${
         typeof item.alias !== "undefined" && item.alias.length > 0
            ? " (" + item.alias + ")"
            : ""
      }`;
   }

   return (
      <div
         className="ArticleListItem"
         key={item.otherSlug ? item.otherSlug : item.id}
         onClick={(e) => onClick(e, item, master)}
      >
         {showThumbnail && imageUrl ? getImage(imageUrl) : null}
         <div className="content">
            <div className="header">
               <div className="title">{makeItemTitle(item)}</div>
               <div className="extra">
                  {showCategoryName && item.category !== "Category"
                     ? item.category
                     : ""}
               </div>
            </div>
            <div className="body">{showSummary ? item.summary : ""}</div>
         </div>
         {showArrow ? getRightArrow() : ""}
      </div>
   );
}

{
   /*
      <List.Item
         className={
            item.type === "C"
               ? "ArticleListItem CategoryThumbnail"
               : "ArticleListItem"
         }
         key={item.otherSlug ? item.otherSlug : item.id}
         id={item.id}
         // hack for hiding props we don't always want.
         {...(showCategoryName && item.category !== "Category"
            ? { extra: item.category }
            : {})}
         align="top"
         arrow={showArrow ? true : ""}
         prefix={showThumbnail && imageUrl ? getImage(imageUrl) : undefined}
         multipleLine={showCategoryName || showSummary}
         onClick={(e) => onClick(e, item, master)}
         title={makeItemTitle(item)}
         children="blah blah"
         description={showSummary ? item.summary : ""}
      />

*/
}
