import SolarCycleCalendar from "./SolarCycleCalendar";
import ShmitaCalendar from "./ShmitaCalendar";
import JubileeCalendar from "./JubileeCalendar";
import DimensionMap from "./DimensionMap";
import {HDate} from "@hebcal/core";

export function hebrewYearFromGregorian(gregorianYear) {
   return gregorianYear + 3760;
}

export function makeSolarCycleData(gregorianYear, notesMap) {

   let num = SolarCycleCalendar.getYearNumForGregorianYear(gregorianYear);
   let hy = hebrewYearFromGregorian(gregorianYear);

   return {
      solarCycle:
         SolarCycleCalendar.getSolarCycleForGregorianYear(gregorianYear),
      yearNum: num,
      gregorianYear: gregorianYear,
      yearRange: `${gregorianYear} - ${gregorianYear + 1}`,
      hebrewYear: `${hy}`,
      dimensionHebrew: DimensionMap[num].hebrew,
      dimensionEnglish: DimensionMap[num].meaning,
      shmita: ShmitaCalendar.isGregorianYearShmita(gregorianYear),
      jubilee: JubileeCalendar.isGregorianYearJubilee(gregorianYear),
      notes: notesMap[gregorianYear],
   };
}

/** This function creates the Solar Cycle table column data for each year in the Solar Cycle.
 * The output of this function is an array of exactly 11 columns, for years 1-11.
 */
export function getSolarCycleData(year, notesMap) {
   const gregorianYear = parseInt(year);
   let results = [];
   let gy;

   let now = new HDate();
   let nowYear = now.getFullYear();

   let selectedYear = makeSolarCycleData(gregorianYear, notesMap);
   selectedYear.selected = (parseInt(selectedYear.hebrewYear) === nowYear) ? true : false;

   const yearNum = parseInt(selectedYear.yearNum);

   //console.log(`getSolarCycleData() gregorianYear=${gregorianYear}, yearNum=${yearNum}`);
   //console.log(`hebrew nowYear=${nowYear}`);

   //console.log(`Solar Cycle ${selectedYear.solarCycle}`);

   // create any columns in the table prior to the selected year
   let start = (gregorianYear-yearNum) + 1;
   for (let i = start; i < gregorianYear; i++) {
      gy = makeSolarCycleData(i, notesMap);
      //console.log(`iteration ${i}, gy.hebrewYear=${gy.hebrewYear}`);
      gy.selected = (parseInt(gy.hebrewYear) === nowYear) ? true : false;
      results.push(gy);
   }

   //console.log(`selectedYear.hebrewYear=${selectedYear.hebrewYear}, selectedYear.selected=${selectedYear.selected}`);
   results.push(selectedYear);

   // create any columns in the table after the selected year
   for (let i = gregorianYear + 1; i <= gregorianYear + (11 - yearNum); i++) {
      gy = makeSolarCycleData(i, notesMap);
      //console.log(`iteration ${i}, gy.hebrewYear=${gy.hebrewYear}`);
      gy.selected = (parseInt(gy.hebrewYear) === nowYear) ? true : false;
      results.push(gy);
   }

   return results;
}
