import { List } from "antd-mobile";
import { PageType } from "../models/PageType";
import { useSelector, useDispatch } from "react-redux";
import { setStartPage } from "../features/settingsSlice";
import { CheckOutlined } from "@ant-design/icons";
import { saveSettingsRemoteOrLocal } from "../utils/SettingsUtils";
import { useLocalDataStore } from "../utils/UseLocalDataStore";

import "./StartPagePickerPage.css";

/* default data loader for this page */
export function loader() {
   console.log(`StartPagePickerPage: loader() called`);
   //T.B.D.
   return null;
}

export default function StartPagePickerPage() {
   const startPage = useSelector((state) => state.settings.startPage);
   const settings = useSelector((state) => state.settings);
   const online = useSelector((state) => state.mainPage.online);
   const currentUser = useSelector((state) => state.account);
   const dispatch = useDispatch();
   const db = useLocalDataStore();

   function onChoiceClick(choice) {
      dispatch(setStartPage(choice));

      // make copy of settings
      let newSettings = Object.assign({}, settings, {
         startPage: choice,
      });

      saveSettingsRemoteOrLocal(online, newSettings, currentUser, db);
   }

   return (
      <List className="StartPagePickerPage" header={<h4>HOME SCREEN</h4>}>
         <List.Item
            onClick={() => onChoiceClick(PageType.FAVORITES_PAGE)}
            arrow={
               startPage === PageType.FAVORITES_PAGE ? <CheckOutlined /> : null
            }
         >
            {PageType.FAVORITES_PAGE}
         </List.Item>
         <List.Item
            onClick={() => onChoiceClick(PageType.EXPLORER_PAGE)}
            arrow={
               startPage === PageType.EXPLORER_PAGE ? <CheckOutlined /> : null
            }
         >
            {PageType.EXPLORER_PAGE}
         </List.Item>
         <List.Item
            onClick={() => onChoiceClick(PageType.CATEGORIES_PAGE)}
            arrow={
               startPage === PageType.CATEGORIES_PAGE ? <CheckOutlined /> : null
            }
         >
            {PageType.CATEGORIES_PAGE}
         </List.Item>
      </List>
   );
}
