import zango from "zangodb";
// import ArticleUtils from "../utils/ArticleUtils";

class LocalDataStore {
   #db;

   constructor() {
      this.#db = null;
   }

   close() {
      if (this.#db._open) {
         this.#db.close();
      }
   }

   getDb() {
      const dbVersion = 3;
      if (this.#db == null || this.#db._open === false) {
         this.#db = new zango.Db("FieldApp", dbVersion, {
            user_profile: ["username", "userId"],
            category: { seq: true, slug: true, name: true, parent: true },
            article: ["seq", "slug", "name"],
            image: ["seq"],
            // recentSearch: ["slug", "timestamp"],
            relation: ["seq", "slug1", "slug2"],
            setting: { seq: true, userId: true },
         });

         this.#db.on("blocked", () => {
            console.log(
               "*** database is blocked. version cannot be upgraded without restart"
            );
         });

         //console.log("#db", this.#db);
      }
      return this.#db;
   }

   /* Returns a promise */
   deleteDatabase() {
      let p = new Promise((resolve, reject) => {
         try {
            this.getDb().drop(() => {
               resolve(true);
            });
         } catch (error) {
            reject(error);
         }
      });
      return p;
   }

   getCategoryTable() {
      return this.#db.collection("category");
   }

   // getRecentSearchTable() {
   //    return this.#db.collection("recentSearch");
   // }

   getArticleTable() {
      return this.#db.collection("article");
   }

   getImageTable() {
      return this.#db.collection("image");
   }

   getRelationTable() {
      return this.#db.collection("relation");
   }

   getUserProfileTable() {
      return this.#db.collection("user_profile");
   }

   getSettingTable() {
      return this.#db.collection("setting");
   }

   /* returns promise */
   getArticleArrayForCategory(slug, successHandler) {
      this.getDb();
      let article = this.getArticleTable();

      return new Promise(async (resolve, reject) => {
         let results = [];
         await article
            .find({ category: { $eq: slug } })
            .sort({ orderby: 1, name: 1 })
            .forEach((r) => {
               results.push(r);
            });
         if (successHandler) {
            successHandler(results);
         }
         resolve(results);
      });
   }

   async getArticle(slug, successHandler) {
      //console.log(`getArticle() slug=${slug}`);
      this.getDb();
      let article = this.getArticleTable();
      let ret;
      await article.findOne({ slug: { $eq: slug } }).then((rec) => {
         if (successHandler) {
            successHandler(rec);
         }
         ret = rec;
      });
      return ret;
   }

   /* returns promise */
   getArticleCount() {
      let p = new Promise(async (resolve, reject) => {
         try {
            this.getDb();
            let article = this.getArticleTable();
            article.find({}).toArray((error, docs) => {
               if (error) reject(error);
               resolve(docs.length);
            });
         } catch (error) {
            reject(error);
         }
      });
      return p;
   }

   /* returns promise */
   getArticlesAfterSeq(seq) {
      let p = new Promise(async (resolve, reject) => {
         try {
            this.getDb();
            let article = this.getArticleTable();
            article
               .find({ seq: { $gt: seq } })
               .sort({ seq: 1 })
               .toArray((error, docs) => {
                  if (error) reject(error);
                  resolve(docs);
               });
         } catch (error) {
            reject(error);
         }
      });
      return p;
   }

   /* returns promise */
   getArticleAtIndex(randomIndex) {
      let p = new Promise(async (resolve, reject) => {
         try {
            this.getDb();
            let article = this.getArticleTable();
            article
               .find({})
               .sort({ seq: 1 })
               .toArray((error, docs) => {
                  if (error) reject(error);
                  resolve(docs[randomIndex]);
               });
         } catch (error) {
            reject(error);
         }
      });
      return p;
   }

   /* returns promise */
   getArticleForImgSeq(imgSeq) {
      let p = new Promise(async (resolve, reject) => {
         try {
            this.getDb();
            let article = this.getArticleTable();
            article
               .find({ imageSeqArray: { $elemMatch: { seq: imgSeq } } })
               .sort({ seq: 1 })
               .toArray((error, docs) => {
                  if (error) reject(error);
                  if (docs && docs.length > 0) {
                     resolve(docs[0]);
                  } else {
                     resolve(undefined);
                  }
               });
         } catch (error) {
            reject(error);
         }
      });
      return p;
   }

   /* returns promise */
   getImageSeqAtIndex(randomIndex) {
      let p = new Promise(async (resolve, reject) => {
         try {
            this.getDb();
            let image = this.getImageTable();
            image
               .find({})
               .sort({ seq: 1 })
               .toArray((error, docs) => {
                  if (error) reject(error);
                  resolve(docs[randomIndex].seq);
               });
         } catch (error) {
            reject(error);
         }
      });
      return p;
   }

   /* returns promise */
   async getImageFromArticleName(articleName) {
      let article = await this.getArticleByName(articleName);
      let seq;
      if (
         article &&
         article.imageSeqArray &&
         article.imageSeqArray.length > 0
      ) {
         seq = article.imageSeqArray[0].seq;
      } else {
         console.log(
            `LocalDataStore: getImageFromArticleName(${articleName}) article not found`
         );
      }
      return this.getImage(seq);
   }

   async getArticleByAlias(alias, successHandler) {
     // console.log(`getArticleByAlias() alias=${alias}`);
      this.getDb();
      let article = this.getArticleTable();
      await article.findOne({ alias: { $eq: alias } }).then((rec) => {
         successHandler(rec);
      });
   }

   async getArticleByName(name, successHandler) {
      //console.log(`getArticleByName() name=${name}`);
      this.getDb();
      let article = this.getArticleTable();
      let p = article.findOne({ name: { $eq: name } });
      if (successHandler) {
         await p.then((rec) => {
            successHandler(rec);
         });
      } else {
         return p;
      }
   }

   /* returns a promise */
   async getCategory(slug, successHandler) {
      this.getDb();
      let category = this.getCategoryTable();
      return new Promise((resolve, reject) => {
         category.findOne({ slug: { $eq: slug } }).then((rec) => {
            if (successHandler) {
               successHandler(rec);
            }
            resolve(rec);
         });
      });
   }

   async getCategoriesBySlugArray(slugArray) {
      this.getDb();
      let category = this.getCategoryTable();
      let results = [];
      await category
         .find({ slug: { $in: slugArray } })
         .project({
            _seq: 0,
            seq: 0,
            ref: 0,
            status: 0,
            //_id: 0,  -- favorites editor page needs the ids
         })
         .sort({ name: 1 })
         .forEach((r) => {
            if (r) {
               results.push(r);
            }
         });
      return results;
   }

   /* Returns a count */
   async getDependencyCountForCategorySlug(slug) {
      this.getDb();
      let article = this.getArticleTable();
      return new Promise((resolve, reject) => {
         article
            .find({ category: { $eq: slug } })
            .project({ _id: 1 })
            .toArray()
            .then((result) => {
               let count = result.length;
               resolve(count);
            });
      });
   }

   /* Returns Promise */
   async findOneAndUpdateCategory(queryExpr, rec) {
      console.log("findAndUpdateCategory() expr=", queryExpr, rec);
      this.getDb();
      let category = this.getCategoryTable();
      await category.findOne(queryExpr).then((result) => {
         return category.update({ slug: { $eq: result.slug } }, rec);
      });
   }

   /* returns a promise */
   getMaxCategorySeq() {
      return new Promise((resolve, reject) => {
         this.getDb();
         let category = this.getCategoryTable();
         category
            .find()
            .sort({ seq: -1 })
            .limit(1)
            .toArray((error, docs) => {
               if (error) reject(error);
               if (typeof docs !== "undefined" && docs.length > 0) {
                  resolve(docs[0].seq);
               } else {
                  resolve(0);
               }
            });
      });
   }

   /* Returns a Promise */
   getMaxArticleSeq() {
      return new Promise((resolve, reject) => {
         this.getDb();
         let article = this.getArticleTable();
         article
            .find()
            .sort({ seq: -1 })
            .limit(1)
            .toArray((error, docs) => {
               if (error) reject(error);
               if (typeof docs !== "undefined" && docs.length > 0) {
                  resolve(docs[0].seq);
               } else {
                  resolve(0);
               }
            });
      });
   }

   /* Returns a Promise */
   getMaxImageSeq() {
      return new Promise((resolve, reject) => {
         this.getDb();
         let image = this.getImageTable();
         image
            .find()
            .sort({ seq: -1 })
            .limit(1)
            .toArray((error, docs) => {
               if (error) reject(error);
               if (typeof docs !== "undefined" && docs.length > 0) {
                  resolve(docs[0].seq);
               } else {
                  resolve(0);
               }
            });
      });
   }

   /* Returns a Promise */
   getMaxRelationSeq() {
      return new Promise((resolve, reject) => {
         this.getDb();
         let relation = this.getRelationTable();
         relation
            .find()
            .sort({ seq: -1 })
            .limit(1)
            .toArray((error, docs) => {
               if (error) reject(error);
               if (typeof docs !== "undefined" && docs.length > 0) {
                  resolve(docs[0].seq);
               } else {
                  resolve(0);
               }
            });
      });
   }

   /* Returns Promise */
   getMaxSettingSeq() {
      return new Promise((resolve, reject) => {
         this.getDb();
         let setting = this.getSettingTable();
         setting
            .find({ userId: { $ne: 0 } })
            .sort({ seq: -1 })
            .limit(1)
            .toArray((error, docs) => {
               if (error) reject(error);
               if (
                  !docs ||
                  typeof docs === "undefined" ||
                  docs === undefined ||
                  docs.length === 0 ||
                  docs[0].seq === undefined
               ) {
                  resolve(0);
               } else {
                  resolve(docs[0].seq);
               }
            });
      });
   }

   /* Returns a Promise */
   async insertRelationsAsync(relArray) {
      this.getDb();
      let relation = this.getRelationTable();
      return relation.insert(relArray);
   }

   async insertDummyRelations() {
      return this.insertRelationsAsync(this.relationArray);
   }

   /* Returns a Promise */
   async insertImagesMany(imageArray) {
      this.getDb();
      let image = this.getImageTable();
      return image.insert(imageArray);
   }

   /* Returns a Promise */
   async getImage(seq) {
      this.getDb();
      let image = this.getImageTable();
      return image.findOne({ seq: { $eq: seq } });
   }

   /* returns promise */
   getImageCount() {
      let p = new Promise(async (resolve, reject) => {
         try {
            this.getDb();
            let image = this.getImageTable();
            image.find({}).toArray((error, docs) => {
               if (error) reject(error);
               resolve(docs.length);
            });
         } catch (error) {
            reject(error);
         }
      });
      return p;
   }

   // async fillRecentSearchArray(handler) {
   //    this.getDb();
   //    let recentSearch = this.getRecentSearchTable();
   //    let recentSearchArray = [];
   //    await recentSearch
   //       .find()
   //       .sort({ timestamp: -1 })
   //       .hint("timestamp")
   //       .forEach((r) => {
   //          recentSearchArray.push(r);
   //       });
   //    handler(recentSearchArray);
   // }

   /* returns promise */
   async fillBrowseCategoriesArray(handler) {
      return new Promise(async (resolve, reject) => {
         try {
            this.getDb();
            let category = this.getCategoryTable();
            let browseCategoriesArray = [];
            await category
               .find()
               // not supported:  .filter({parent: {$exists: false}})
               .sort({ name: 1 })
               .hint("name")
               .forEach((r) => {
                  if (r.parent === undefined || r.parent === null) {
                     browseCategoriesArray.push(r);
                  }
               });
            if (handler) {
               handler(browseCategoriesArray);
            }
            resolve(browseCategoriesArray);
         } catch (error) {
            reject(error);
         }
      });
   }

   /* fetch for subcategories, returns promise */
   async getSubCategoriesForParent(parentCategorySlug, handler) {
      return new Promise(async (resolve, reject) => {
         try {
            this.getDb();
            let category = this.getCategoryTable();
            let results = [];
            await category
               .find()
               .sort({ name: 1 })
               .hint("name")
               .forEach((r) => {
                  if (r.parent && r.parent === parentCategorySlug) {
                     results.push(r);
                  }
               });
            if (handler) {
               handler(results);
            }
            resolve(results);
         } catch (error) {
            reject(error);
         }
      });
   }

   async fillRelationsArray(slug, handler) {
      this.getDb();
      let relation = this.getRelationTable();
      let relationsArray = [];
      await relation
         .find({ $or: [{ slug1: { $eq: slug } }, { slug2: { $eq: slug } }] })
         .sort({ slug1: 1, slug2: 1 })
         .forEach((r) => {
            relationsArray.push(r);
         });
      if (handler) {
         handler(relationsArray);
      }
      return relationsArray;
   }

   async queryArticleHeadings(
      slugArray,
      handler,
      showImages = false,
      showSummary = false
   ) {
      this.getDb();
      let article = this.getArticleTable();
      let headingsArray = [];

      let projections = { _seq: 0, seq: 0, text: 0 };
      if (showImages === false) projections.imageSeqArray = 0;
      if (showSummary === false) projections.summary = 0;
      //_id: 0,  -- favorites editor page needs the ids

      await article
         .find({ slug: { $in: slugArray } })
         .project(projections)
         .sort({ name: 1 })
         .forEach((r) => {
            if (r) {
               headingsArray.push(r);
            }
         });
      if (handler) {
         handler(headingsArray);
      }
      return headingsArray;
   }

   async appendCategoryNames(articleArray, handler) {
      this.getDb();
      let category = this.getCategoryTable();
      let categoryMap = new Map();

      articleArray.forEach((r) => {
         categoryMap.set(r.category, r.category);
      });

      let categoryArray = Array.from(categoryMap.keys());

      await category
         .find({ slug: { $in: categoryArray } })
         .project({ _seq: 0 })
         .sort({ name: 1 })
         .forEach((r) => {
            categoryMap.set(r.slug, r.name);
         });

      articleArray.forEach((a) => {
         a.categoryName = categoryMap.get(a.category);
      });

      handler(articleArray);
   }

   async fillQueryArticlesArray(query, limit, handler) {
      this.getDb();
      let article = this.getArticleTable();
      let results = [];
      const regx = new RegExp(query, "i");

      /*
      await article
         .find({ name: { $regex: /query/ } })
         .limit(limit)
         .sort({ name: 1 })
         .hint("name")
         .forEach((r) => {
               results.push(r);
         });
         */

      await article
         .find()
         .sort({ name: 1 })
         .hint("name")
         .forEach((r) => {
            if (regx.test(r.name) || regx.test(r.alias)) {
               if (results.length < limit) {
                  r.type = 'A';
                  results.push(r);
               }
            }
         });

      handler(results);
   }

   /* FIX need to try to upgrade to latest ZangoDB lib
      to get the fixes for regex so that we can properly
      query here
      */
   async fillQueryCategoriesArray(query, limit, handler) {
      this.getDb();
      let category = this.getCategoryTable();
      let results = [];
      const regx = new RegExp(query, "i");

      await category
         .find()
         .project({ _seq: 0, type: "C" })
         .sort({ name: 1 })
         .hint("name")
         .forEach((r) => {
            if (regx.test(r.name)) {
               if (results.length < limit) {
                  r.type = 'C';
                  results.push(r);
               }
            }
         });
      handler(results);
   }

   async fillAutoCompleteSearchResultArray(query, handler) {
      if (query.length === 0) {
         //console.log("empty query, returnnig []");
         handler([]);
      } else {
         //console.log("got a non-empty query >>>" + query + "<<<");
      }

      const limit = 5;

      const p1 = new Promise((resolve, reject) => {
         this.fillQueryArticlesArray(query, limit, (results1) => {
            resolve(results1);
         });
      });

      const p2 = new Promise((resolve, reject) => {
         this.fillQueryCategoriesArray(query, limit, (results2) => {
            resolve(results2);
         });
      });

      Promise.all([p1, p2]).then((values) => {
         handler([...values[0], ...values[1]]);
      });
   }

   // insertRecentSearch(rec) {
   //    this.getDb();
   //    let recentSearch = this.getRecentSearchTable();

   //    recentSearch.remove({ slug: { $eq: rec.slug } }, () => {
   //       let a = [
   //          {
   //             seq: 0,
   //             timestamp: ArticleUtils.getTimestampMillis(),
   //             type: rec.type,
   //             slug: rec.slug,
   //             name: rec.name,
   //          },
   //       ];
   //       recentSearch.insert(a);
   //    });
   // }

   // clearRecentSearch() {
   //    this.getDb();
   //    let recentSearch = this.getRecentSearchTable();
   //    recentSearch.remove({});
   // }

   /* Returns promise */
   insertOneCategory(rec) {
      // console.log("Inserting category: ", rec);
      this.getDb();
      let category = this.getCategoryTable();
      return category.insert(rec);
   }

   /* Returns promise */
   insertOneArticle(rec) {
      this.getDb();
      let article = this.getArticleTable();
      return article.insert(rec);
   }

   /* Returns promise */
   insertOneSetting(rec) {
      this.getDb();
      let setting = this.getSettingTable();
      return setting.insert(rec);
   }

   /* Returns promise */
   getSetting(userId) {
      this.getDb();
      let setting = this.getSettingTable();
      return setting.findOne({ userId: userId });
   }

   /* Returns promise */
   updateSetting(rec) {
      this.getDb();
      let setting = this.getSettingTable();
      return setting.update({ userId: rec.userId }, rec);
   }

   /* Returns promise */
   insertOrUpdateOneSetting(rec) {
      return new Promise(async (resolve, reject) => {
         try {
            this.getDb();
            let setting = this.getSettingTable();

            let result = await this.getSetting(rec.userId);

            if (result && result.userId === rec.userId) {
               await this.updateSetting(rec);
            } else {
               await setting.insert(rec);
            }

            resolve(rec);
         } catch (e) {
            reject(e);
         }
      });
   }

   /* Returns promise */
   deleteSettingsPriorTo(maxSettingSeqPlusOne, userId) {
      console.log(
         `Removing settings for userId: ${userId} prior to seq: ${maxSettingSeqPlusOne}`
      );
      this.getDb();
      let setting = this.getSettingTable();
      return setting.remove({
         $and: [
            { userId: { $eq: userId } },
            { seq: { $lt: maxSettingSeqPlusOne } },
         ],
      });
   }

   deleteSetting(seq) {
      console.log(`Removing setting for seq: ${seq}`);
      this.getDb();
      let setting = this.getSettingTable();
      return setting.remove({ seq: { $eq: seq } });
   }

   /* Returns promise */

   deleteSettingsPriorToId(userId, currentLocalId) {
      console.log(
         `Removing settings for userId: ${userId} prior to _id: ${currentLocalId}`
      );
      this.getDb();
      let setting = this.getSettingTable();
      return setting.remove({
         $and: [{ userId: { $eq: userId } }, { _id: { $lt: currentLocalId } }],
      });
   }

   /* Returns promise */
   deleteCategoriesPriorTo(maxCategorySeqPlusOne, slug) {
      console.log(
         `Removing categories for slug: ${slug} prior to seq: ${maxCategorySeqPlusOne}`
      );
      this.getDb();
      let category = this.getCategoryTable();
      return category.remove({
         $and: [
            { slug: { $eq: slug } },
            { seq: { $lt: maxCategorySeqPlusOne } },
         ],
      });
   }

   deleteCategory(seq) {
      console.log(`Removing category for seq: ${seq}`);
      this.getDb();
      let category = this.getCategoryTable();
      return category.remove({ seq: { $eq: seq } });
   }

   /* Returns promise */
   deleteImage(seq) {
      console.log(`Removing image for seq: ${seq}`);
      this.getDb();
      let image = this.getImageTable();
      return image.remove({ seq: { $eq: seq } });
   }

   /* Returns promise */
   deleteArticlesPriorTo(maxArticleSeqPlusOne, slug) {
      console.log(
         `Removing articles for slug: ${slug} prior to seq: ${maxArticleSeqPlusOne}`
      );
      this.getDb();
      let article = this.getArticleTable();
      return article.remove({
         $and: [
            { slug: { $eq: slug } },
            { seq: { $lt: maxArticleSeqPlusOne } },
         ],
      });
   }

   deleteArticle(seq) {
      console.log(`Removing article for seq ${seq}`);
      this.getDb();
      let article = this.getArticleTable();
      return article.remove({
         $and: [{ seq: { $eq: seq } }],
      });
   }

   /* Returns promise */
   deleteRelation(seq) {
      console.log(`Removing relation for seq: ${seq}`);
      this.getDb();
      let relation = this.getRelationTable();
      return relation.remove({ seq: { $eq: seq } });
   }

   /* Returns promise */
   deleteRelationBySlug(slug1) {
      console.log(`Removing relation for slug: ${slug1}`);
      this.getDb();
      let relation = this.getRelationTable();
      return relation.remove({ slug1: { $eq: slug1 } });
   }

   /* Returns a promise */
   // deleteAllRecentSearchForSlug(slug) {
   //    console.log(`Removing all relation for slug: ${slug}`);
   //    this.getDb();
   //    let recentSearch = this.getRecentSearchTable();
   //    return recentSearch.remove({ slug: { $eq: slug } });
   // }

   getUserProfile(userId) {
      this.getDb();
      let user_profile = this.getUserProfileTable();
      return user_profile.findOne({ userId: userId });
   }

   async updateOrInsertUserProfile(profile) {
      console.log(`Removing previous user_profile for ${profile.username}`);
      this.getDb();
      let user_profile = this.getUserProfileTable();

      await user_profile.remove({
         username: { $eq: profile.username },
      });
      let result2 = await user_profile.insert([profile]);

      return result2;
   }

   async queryAllArticleCategories() {
      this.getDb();
      let article = this.getArticleTable();
      let result1 = await article
         .find({})
         .project({
            _seq: 0,
            ref: 0,
            seq: 0,
            text: 0,
            status: 0,
            imageSeqArray: 0,
            type: "A",
         })
         .sort({ name: 1 })
         .toArray();
      let category = this.getCategoryTable();
      let result2 = await category
         .find({})
         .project({ _seq: 0, ref: 0, seq: 0, status: 0 })
         .sort({ name: 1 })
         .toArray();
      return result1.concat(result2);
   }
}

export default LocalDataStore;
