import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { CopyOutlined } from "@ant-design/icons";
import { CardType } from "../models/CardType";
import ArticleTextRich from "../components/ArticleTextRich";
import ArticleCallbacks from "../utils/ArticleCallbacks";
import Card from "./Card";
import "./ArticleOfDayCard.css";

export default function ArticleOfDayCard() {
   const navigate = useNavigate();
   const articleOfDay = useSelector((state) => state.cardExplorerPage.articleOfDay);

   function onMenuClick() {
      console.log("onMenuClick()");
      navigate("/settings/cardexplorer");
   }

   function onClickNavLink() {
      // ArticleCallbacks.handleOnArticleClick(null, articleOfDay.slug, db, dispatch, navigate);
      //    navigate("/article/" + articleOfDay.slug);
      ArticleCallbacks.handleOnArticleClick(articleOfDay.slug, navigate);
   }

   function createFeaturedImage(seq, url, filename) {
      return <img key="featuredImage" src={url} alt={filename}></img>;
   }

   function createImageFromSeqArray(article) {
      return article.imageSeqArray && article.imageSeqArray.length > 0
         ? createFeaturedImage(
              article.imageSeqArray[0].seq,
              article.imageSeqArray[0].base64data,
              article.imageSeqArray[0].filename
           )
         : "";
   }

   return (
      <Card
         key="ArticleOfMomentCard"
         className="ArticleOfMomentCard"
         headerIcon={<CopyOutlined className="headerIcon" />}
         titleText={CardType.ARTICLE_OF_MOMENT_CARD}
         onMenuIconClick={onMenuClick}
         onMoreClick={onClickNavLink}
      >
         <div className="Article">
            {createImageFromSeqArray(articleOfDay)}
            <div className="article-content">
               <h3>{articleOfDay.name}</h3>
               <p className="summary">{articleOfDay.summary}</p>
               <p className="bodyText">
                  <ArticleTextRich text={articleOfDay.text} />
               </p>
            </div>
         </div>
      </Card>
   );
}
