import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { CardType } from "../models/CardType";
import ArticleListItem from "../components/ArticleListItem";
import { PlusSquareOutlined } from "@ant-design/icons";
import ArticleCallbacks from "../utils/ArticleCallbacks";
import CategoryCallbacks from "../utils/CategoryCallbacks";
import Card from "./Card";
import "./WhatsNewCard.css";

export default function WhatsNewCard() {
   const navigate = useNavigate();
   const whatsNewArticleList = useSelector(
      (state) => state.cardExplorerPage.whatsNewArticleList
   );
   const ShowOnlyQuantity = 3;

   function onMenuClick() {
      console.log("onMenuClick()");
      navigate("/settings/cardexplorer");
   }

   function onClickNavLink() {
      //console.log("onClickNavLink()");
      navigate("/whatsnew");
   }

   async function onItemClick(e, item) {
      // console.log(`DEBUG: onItemClick: ${item.slug}`);

      if (item.type === "A") {
         ArticleCallbacks.handleOnArticleClick(item.slug, navigate);
      } else {
         CategoryCallbacks.handleOnCategoryClick(item.slug, navigate);
      }
   }

   function createCardItems(itemList, onItemClick) {
      let results = [];

      for (let i = 0; i < ShowOnlyQuantity && i < itemList.length; i++) {
         let item = itemList[i];
         results.push(
            <ArticleListItem
               key={item.slug}
               item={item}
               onClick={onItemClick}
               master={undefined}
               showCategoryName={item.type === "A"}
               showThumbnail={item.type === "A"}
               showSummary={item.type === "A"}
               showArrow={true}
            />
         );
      }

      return results;
   }

   return (
      <>
         {whatsNewArticleList && whatsNewArticleList.length > 0 ? (
            <Card
               key="WhatsNewCard"
               className="WhatsNewCard"
               headerIcon={<PlusSquareOutlined className="headerIcon" />}
               titleText={CardType.WHATS_NEW_CARD}
               onMenuIconClick={onMenuClick}
               onMoreClick={onClickNavLink}
            >
               {createCardItems(whatsNewArticleList, onItemClick)}
            </Card>
         ) : (
            ""
         )}
      </>
   );
}

