import axiosInstance from "./ThreadedAPI";
import {
   // setAccessToken,
   setSessionExpiredNotLoggedIn,
} from "../features/accountSlice";
import LocalStorage from "../utils/LocalStorage";

const setup = (store) => {
   /* this handles adding the accessToken to every remote API call */
   axiosInstance.interceptors.request.use(
      (config) => {

         //FIXME fixes a bug where axios is not honoring the per-invocation override of "accept" header
         if (config.url && config.url.includes("avatar")) {
            config.headers["accept"] = "image/jpeg";
         }

         if (config.url && !config.url.includes("/signin") && !config.url.includes("/refreshtoken") ) {
            const accessToken = LocalStorage.getAccessToken();
            if (accessToken) {
               config.headers["Authorization"] = "Bearer " + accessToken; // for Spring Boot back-end
               //config.headers["x-access-token"] = token; // for Node.js Express back-end
            }
         }
         return config;
      },
      (error) => {
         return Promise.reject(error);
      }
   );

   const { dispatch } = store;

   /* this handles checking the HTTP response code and automatically calling refreshToken when needed */
   axiosInstance.interceptors.response.use(
      (res) => {
         return res; //JSON.parse() ??? TODO
      },
      async (err) => {
         const originalConfig = err.config;
         if (originalConfig) {
            console.log(`error: originConfig.url=${originalConfig.url}`);
            if (
               originalConfig &&
               originalConfig.url !== "/api/auth/signin" &&
               err.response
            ) {
               // Access Token was expired
               if (err.response.status === 401) {
                  if (!originalConfig._retry) {
                     originalConfig._retry = true;

                     console.log("DEBUG: accessToken is expired. Need to refresh token");
                     try {
                        const refreshToken = LocalStorage.getRefreshToken();
                        if (!refreshToken) {
                           console.log("ERROR: refreshToken is missing");
                           Promise.reject(err);
                        }

                        console.log(`DEBUG: refreshToken=${refreshToken}`);

                        const rs = await axiosInstance.post(
                           "/api/auth/refreshtoken",
                           {
                              refreshToken: refreshToken,
                           }
                        );

                        const { accessToken } = rs.data;

                        console.log(`new access token = ${accessToken}`);

                        // dispatch(setAccessToken(accessToken));
                        LocalStorage.setAccessToken(accessToken);

                        return axiosInstance(originalConfig);
                     } catch (_error) {
                        console.log(
                           "AxiosInterceptor: refresh token failed more than once. Declaring session expired.",
                           _error
                        );
                        dispatch(setSessionExpiredNotLoggedIn());
                           return Promise.reject(_error);
                     }
                  } else {
                     // failed again
                     console.log(
                        "AxiosInterceptor: refresh token failed more than once. Declaring session expired."
                     );
                     dispatch(setSessionExpiredNotLoggedIn());
                  }
               }
            }
         }

         if (err.response && err.response.status) {
            console.log(
               `Received a non-refreshToken related error from API backend, status=${err.response.status}, statusText=${err.response.statusText}`
            );
         } else {
            console.log(
               "Received a non-refreshToken related error from API backend,",
               err
            );
         }

         return Promise.reject(err);
      }
   );
};

export default setup;
