import { createSlice } from "@reduxjs/toolkit";
import { ToolBarButtonType } from "../models/ToolBarButtonType";

export const MainPageInitialState = {
   appName: "Field App",
   appInitialized: false,
   online: false,
   splashScreenVisible: true,
   installAppVisible: false /* research what this is for, used by DefaultOutlet and MyMask */,
   waitingServiceWorker: undefined,
   topArticleSeq: undefined,
   showSettingsActionSheetMenu: false,
   shareMenu: {
      visible: false,
      title: "",
      url: "",
   },
   toolbar: {
      activeTab: ToolBarButtonType.EXPLORER,
      toolbarActionSheetMenuVisible: false,
      toolbarVisible: false,
   },
   mainSearch: {
      headerVisible: false /* this should be moved up */,
      searchVisible: false,
      searchValue: "",
      popupVisible: false,
      recentSearchPicklist: [],
      autoCompleteSearchResultsArray: [],
      pageActionSheetVisible: false /* this should be moved up */,
   },
   storage: {
      bytesUsed: 0,
      bytesTotal: 0,
      percentRemaining: 0,
   },
};

export const mainPageSlice = createSlice({
   name: "mainPage",
   initialState: MainPageInitialState,
   reducers: {
      reset: () => {
         return MainPageInitialState;
      },
      setAppInitialized: (state) => {
         state.appInitialized = true;
      },
      setTopArticleSeq: (state, action) => {
         state.topArticleSeq = action.payload;
      },
      updateOnline: (state, action) => {
         state.online = action.payload;
      },
      updateReady: (state, action) => {
         state.waitingServiceWorker = action.payload.waitingServiceWorker;
      },
      setSplashScreenVisible: (state, action) => {
         state.splashScreenVisible = action.payload;
      },
      setInstallAppVisible: (state, action) => {
         state.installAppVisible = action.payload;
      },
      updateShareMenu: (state, action) => {
         state.shareMenu.visible = action.payload.visible;
         state.shareMenu.title = action.payload.title;
         state.shareMenu.url = action.payload.url;
      },
      setToolbarActiveTab: (state, action) => {
         state.toolbar.activeTab = action.payload;
      },
      setToolbarActionSheetVisible: (state, action) => {
         state.toolbar.toolbarActionSheetMenuVisible = action.payload;
      },
      setToolbarVisible: (state, action) => {
         state.toolbar.toolbarVisible = action.payload;
      },
      setMainSearchVisible: (state, action) => {
         state.mainSearch.searchVisible = action.payload;
      },
      setHeaderVisible: (state, action) => {
         state.mainSearch.headerVisible = action.payload;
      },
      setRecentSearchPicklist: (state, action) => {
         state.mainSearch.searchValue = "";
         //state.mainSearch.popupVisible = action.payload.length > 0;
         state.mainSearch.recentSearchPicklist = action.payload;
         state.mainSearch.autoCompleteSearchResultsArray = []
      },
      setSearchValue: (state, action) => {
         state.mainSearch.searchValue = action.payload;
         state.mainSearch.popupVisible = action.payload.length > 0;

         // state.mainSearch.recentSearchPicklist =
         //    action.payload.length > 0 ? state.mainSearch.recentSearchPicklist : [];

         // state.mainSearch.autoCompleteSearchResultsArray =
         //    action.payload.length > 0
         //       ? state.mainSearch.autoCompleteSearchResultsArray
         //       : [];
      },
      setSearchPopupVisible: (state, action) => {
         state.mainSearch.searchValue = "";
         state.mainSearch.popupVisible = action.payload;
      },
      setAutoCompleteSearchResultsArray: (state, action) => {
         state.mainSearch.popupVisible = true;
         state.mainSearch.recentSearchPicklist = [];
         state.mainSearch.autoCompleteSearchResultsArray = action.payload;
      },
      setPageActionSheetVisible: (state, action) => {
         state.mainSearch.pageActionSheetVisible = action.payload;
      },
      // emptyrecentSearchPicklist: (state) => {
      //    state.mainSearch.searchValue = "";
      //    state.mainSearch.popupVisible = false;
      //    state.mainSearch.recentSearchPicklist = [];
      // },
      setStorage: (state, action) => {
         state.storage.bytesUsed = action.payload.bytesUsed;
         state.storage.bytesTotal = action.payload.bytesTotal;
         state.storage.percentRemaining = action.payload.percentRemaining;
      },
      setActiveToolbarTab: (state, action) => {
         state.toolbar.activeTab = action.payload;
      },
      setToolbarActionSheetMenuVisible: (state, action) => {
         state.toolbar.toolbarActionSheetMenuVisible = action.payload;
      },
      setShowSettingsActionSheetMenu: (state, action) => {
         state.showSettingsActionSheetMenu = action.payload;
      },
   },
});

export const {
   reset,
   setAppInitialized,
   updateOnline,
   updateReady,
   setTopArticleSeq,
   setSplashScreenVisible,
   setInstallAppVisible,
   updateShareMenu,
   setToolbarActiveTab,
   setToolbarActionSheetVisible,
   setToolbarVisible,
   setActiveToolbarTab,
   setToolbarActionSheetMenuVisible,
   setMainSearchVisible,
   setHeaderVisible,
   setRecentSearchPicklist,
   // prependTorecentSearchArray,
   setSearchValue,
   setSearchPopupVisible,
   setAutoCompleteSearchResultsArray,
   setPageActionSheetVisible,
   // emptyRecentSearchArray,
   setStorage,
   setShowSettingsActionSheetMenu,
} = mainPageSlice.actions;

export default mainPageSlice.reducer;
