import { EllipsisOutlined } from "@ant-design/icons";
import { Button } from "antd-mobile";
import "./Card.css";

export default function Card({
    className,
   headerIcon,
   titleText,
   subtitleText,
   onMenuIconClick,
   onMoreClick,
   children,
}) {
   return (
      <div className={className ? "Card "+className : "Card"}>
         <div className="header">
            <div className="headerIcon">{headerIcon}</div>
            <div className="title">
               <span className="heading">{titleText}</span>
               {subtitleText ? <span className="subheading">{subtitleText}</span> : null}
            </div>
            <Button className="menuButton" onClick={(e) => onMenuIconClick()}>
               <EllipsisOutlined rotate="90" />
            </Button>
         </div>
         <div className="body">{children}</div>
         <div className="footer">
            <button
               className="smoresButton"
               onClick={(e) => {
                  onMoreClick();
               }}
            >
               Click here for more
            </button>
         </div>
      </div>
   );
}
