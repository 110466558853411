import React from "react";
import ArticleUtils from "../utils/ArticleUtils";
import { v4 as uuidv4 } from "uuid";
import "./AlphabetList.css";

function renderListItems(onIndexClick, onTouchMove, includeNumbers) {
   let output = [];
   let ALPHABET_LIST_LETTERS = includeNumbers
      ? ArticleUtils.lettersAndNumbersArray()
      : ArticleUtils.alphabetArray();
      
   for (let i in ALPHABET_LIST_LETTERS) {
      output.push(
         <li
            key={uuidv4()}
            // onTouchMove={(e) => onTouchMove(e,ALPHABET_LIST_LETTERS[i])}
            onClick={(e) => onIndexClick(e, ALPHABET_LIST_LETTERS[i])}
         >
            {ALPHABET_LIST_LETTERS[i]}
         </li>
      );
   }
   return output;
}

function AlphabetList({ includeNumbers = false, onIndexClick, onTouchMove }) {
   //   const [touchRef, setTouchRef] = useRef();

   return (
      <div className="AlphabetList">
         <ul>{renderListItems(onIndexClick, onTouchMove, includeNumbers)}</ul>
      </div>
   );
}

export default AlphabetList;
