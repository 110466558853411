import { createSlice } from "@reduxjs/toolkit";
import { CardType } from "../models/CardType";
import { PageType } from "../models/PageType";

export const CardIdListInitialState = [
   { id: CardType.WHATS_NEW_CARD, on: true },
   { id: CardType.FAVORITES_CARD, on: true },
   { id: CardType.LOOKING_UP_CALENDAR_CARD, on: true },
   { id: CardType.SOLAR_CYCLE_CALENDAR_CARD, on: true },
   { id: CardType.MORNING_TEA_CARD, on: true },
   { id: CardType.BLUEARMY_RADIO_CARD, on: true },
   { id: CardType.ARTICLE_OF_MOMENT_CARD, on: true },
   { id: CardType.HELPFULS_CARD, on: true },
   { id: CardType.IMAGE_OF_MOMENT_CARD, on: true },
];

export const CardExplorerInitialState = {
   cardIdList: CardIdListInitialState,
};

export const RecentSearchArrayInitialState = [];

export const SettingsInitialState = {
   userId: undefined,
   fasterStartup: false,
   simplifiedUI: false,
   startPage: PageType.CATEGORIES_PAGE,
   favorites: [],
   cardExplorer: CardExplorerInitialState,
   recentSearchArray: RecentSearchArrayInitialState,
};

export const settingsSlice = createSlice({
   name: "settings",
   //REMINDER: that whenever we add/remove fields here,
   //we also need to do it in the react-server SettingsController as well.
   //for storage into the database !!
   initialState: SettingsInitialState,
   reducers: {
      reset: () => {
         return SettingsInitialState;
      },
      appendFavorite: (state, action) => {
         state.favorites = [...state.favorites, action.payload];
      },
      removeFavorite: (state, action) => {
         state.favorites = state.favorites.filter(
            (item) => item !== action.payload
         );
      },
      replaceFavoritesList: (state, action) => {
         state.favorites = action.payload;
      },

      replaceSettings: (state, action) => {
         return action.payload;
      },
      setCardExplorerItemVisible: (state, action) => {
         state.cardExplorer.cardIdList = state.cardExplorer.cardIdList.map(
            (item) =>
               item.id === action.payload.id
                  ? { id: item.id, on: action.payload.visible }
                  : item
         );
      },
      replaceCardExplorerIdList: (state, action) => {
         state.cardExplorer.cardIdList = action.payload;
      },
      setStartPage: (state, action) => {
         state.startPage = action.payload;
      },
      setRecentSearchArray: (state, action) => {
         state.recentSearchArray = action.payload;
      },
   },
});

export const {
   reset,
   appendFavorite,
   removeFavorite,
   replaceFavoritesList,
   replaceSettings,
   setCardExplorerItemVisible,
   replaceCardExplorerIdList,
   setStartPage,
   setRecentSearchArray,
} = settingsSlice.actions;

export default settingsSlice.reducer;
