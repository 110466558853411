import ArticleCallbacks from "./ArticleCallbacks";


const CategoryCallbacks = {

   handleOnCategoryClick(slug, navigate) {
      navigate("/category/" + slug);

      // return new Promise((resolve, reject) => {
      //    try {
      //       if (e) {
      //          e.preventDefault();
      //       }
      //       if (navigate) {
      //          navigate("/category/" + slug);
      //       }
      //       resolve("");
      //    } catch (error) {
      //       reject(error);
      //    }
      // });
   },

   async getDependencyCountForCategorySlug(slug, db) {
      return await db.getDependencyCountForCategorySlug(slug);
   },

   async loadCategoryDataPromise(slug, db) {
      return new Promise((resolve, reject) => {
         db.getCategory(slug, async (category) => {
            if (category) {
               let count = await db.getDependencyCountForCategorySlug(slug);
               category["hasDependents"] = count > 0;
               category["dependencyCount"] = count;
               resolve(category);
            } else {
               reject(`Category ${slug} not found`);
            }
         });
      });
   },

   async loadSubcategoriesForParent(slug, db, dispatch) {
      return new Promise((resolve, reject) => {
         db.getSubCategoriesForParent(slug, (subcategoryArray) => {
            console.log(
               `db.getSubCategoriesForParent() ${slug} returned with ${subcategoryArray.length} items`
            );
            // if (dispatch) {
            //    dispatch(setSubcategoryArray(subcategoryArray));
            // }
            resolve(subcategoryArray);
         });
      });
   },

   async loadArticleArrayForCategory(slug, db) {
      return new Promise((resolve, reject) => {
         db.getArticleArrayForCategory(slug, (articlesArray) => {
            //console.log("DEBUG: articlesArray:", articlesArray);

            let promises = [];

            articlesArray.forEach((article) => {
               promises.push(
                  ArticleCallbacks.fetchFeaturedImageData(article, db)
               );
            });

            Promise.all(promises).then((success) => {
               // if (dispatch) {
               //    dispatch(fetchCategoryArticleList(null /*category*/, articlesArray));
               // }
               resolve(articlesArray);
            });
         });
      });
   },

   async loadFullCategoryDataPromise(slug, db, dispatch) {
      console.log("loadFullCategoryDataPromise called! slug=" + slug);
      return new Promise((resolve, reject) => {
         try {
            let p1 = this.loadCategoryDataPromise(slug, db);
            let p2 = this.loadSubcategoriesForParent(slug, db, dispatch);
            let p3 = this.loadArticleArrayForCategory(slug, db, dispatch);

            Promise.all([p1, p2, p3]).then((success) => {
               resolve({ category: success[0], subCategoriesArray: success[1], articlesArray: success[2]});
            });
         } catch (error) {
            reject(error);
         }
      });
   },
};

export default CategoryCallbacks;
