import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CalendarOutlined } from "@ant-design/icons";
import { CardType } from "../models/CardType";
import { loadLookingUpCalendarPage } from "../utils/CardExplorerUtils";
import { useLocalDataStore } from "../utils/UseLocalDataStore";
import Card from "./Card";
import "./LookingUpCalendarCard.css";

export default function LookingUpCalendarCard() {
   const db = useLocalDataStore();
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const calendarCard = useSelector((state) => state.cardExplorerPage.calendarCard);

   function onMenuClick() {
      console.log("onMenuClick()");
      navigate("/settings/cardexplorer");
   }

   function onClickNavLink() {
      console.log("onClickNavLink()");
      loadLookingUpCalendarPage(db, dispatch, navigate);
   }

   return (
      <Card
         key="lookingUpCalendarCard"
         className="LookingUpCalendarCard"
         headerIcon={<CalendarOutlined className="headerIcon" />}
         titleText={CardType.LOOKING_UP_CALENDAR_CARD}
         subtitleText={calendarCard.hebdate}
         onMenuIconClick={onMenuClick}
         onMoreClick={onClickNavLink}
      >
         <img
            src={calendarCard.image ? calendarCard.image.base64data : undefined}
            alt={calendarCard.monthName}
         />
      </Card>
   );
}
