import React from "react";
import { Button } from "antd-mobile";
import "./CloseButtonX.css";

function CloseButtonX({ onClick }) {
   return (
      <Button
         type="link"
         className="close-button"
         onClick={(e) => onClick()}
      >
         &#x2715;
      </Button>
   );
}

export default CloseButtonX;
