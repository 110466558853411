import React, { useEffect, useState } from "react";
import SolarCycleCalendarColumn from "./SolarCycleCalendarColumn";
import "./SolarCycleCalendarWidget.css";

function SolarCycleCalendarWidget(props) {
   const svgRef = React.createRef();
   const [drawn,setDrawn] = useState(false);

   const calendarData = props.calendarData;
   const selectionRef = props.selectionRef;
   const widgetRef = props.widgetRef;

   const minify = props.minify;

   useEffect(() => {
      if (svgRef.current && !drawn) {
         let startPoint = [0, 1280];
         let endPoint = [1280, 1280];

         let originX = 0; /* X starting position */
         let originY = 90; /* Y starting position */

         let width = 1280;
         let amplitude = 60;
         let pointSpacing = 1;
         let angularFrequency = 0.005;
         let phaseAngle = width / 4;

         let origin = {
            //origin of axes
            x: originX,
            y: originY,
         };

         let points = [];
         points.push(startPoint);
         let x, y;
         for (let i = 0; i < width / pointSpacing; i++) {
            x = i * pointSpacing + origin.x;
            y =
               Math.sin(angularFrequency * (i + phaseAngle)) * amplitude +
               origin.y;
            points.push([x, y]);
         }
         points.push(endPoint);
         svgRef.current.setAttribute("points", points.join(" "));
         setDrawn(true);
      }
   }, [svgRef,drawn,setDrawn]);

   return (
      <div className="SolarCycleCalendarWidget"
         ref={(node) => {            
            if (node) {
                  if (widgetRef) {
                     widgetRef.current = node;
                  } else {
                  }
            }
         }}
           >
         <div className="barGraphGradient">
            <svg id="mySVG" width="1280" height="160">
               <defs>
                  <linearGradient
                     id="Gradient1"
                     gradientUnits="userSpaceOnUse"
                     gradientTransform="rotate(90 640 80)"
                  >
                     <stop className="stop2" offset="50%" />
                     <stop className="stop3" offset="56%" />
                  </linearGradient>
               </defs>
               <style type="text/css">
                  {`.stop2 { stop-color: rgba(9, 9, 121, 1); }`}
                  {`.stop3 { stop-color: rgba(0, 212, 255, 1); }`}
               </style>
               <polyline
                  id="sineWave"
                  stroke="red"
                  strokeWidth="0"
                  fill="url(#Gradient1)"
                  ref={(node) => {
                     if (node) svgRef.current = node;
                  }}
               ></polyline>
            </svg>
         </div>
         <div className="SolarCycleTableData">
            {calendarData.map((column) => (
               <SolarCycleCalendarColumn
                  selectionRef={selectionRef}
                  minify={minify}
                  key={column.solarCycle + "." + column.yearNum}
                  data={column}
               />
            ))}
         </div>
      </div>
   );
}

export default SolarCycleCalendarWidget;
