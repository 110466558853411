import React from "react";
import "./SolarCycleCalendarColumn.css";

function SolarCycleCalendarColumn(props) {
   const data = props.data;
   const selectionRef = props.selectionRef;
   const minify = props.minify;

   return (
      <div
         key={data.gregorianYearRange}
         className={
            "SolarCycleCalendarColumn" +
            (!minify && data.solarCycle % 2 === 0 ? " zebra-stripe" : "")
         }
         ref={(node) => {            
            if (node) {
               if (data.selected) {
                  if (selectionRef) {
                     selectionRef.current = node;
                  } else {
                  }
               }
            }
         }}
      >
         {(data.selected && minify) || (!minify && data.yearNum === 6) ? (
            <div
               className={
                  "solarCycleNum " + (data.selected ? "selection-bar" : "")
               }
            >
               {"Solar Cycle " + data.solarCycle}
            </div>
         ) : (
            ""
         )}
         <div className={"cycleYearNum " + (data.selected ? "selection" : "")}>
            <span className="yearLabel">Year&nbsp;</span>
            {data.yearNum}
         </div>
         <div
            className={
               "gregorianYearRange " + (data.selected ? "selection" : "")
            }
         >
            {data.yearRange}
         </div>
         <div
            className={
               "gregorianYearRange " + (data.selected ? "selection" : "")
            }
         >
            {data.hebrewYear}
         </div>
         <div className={"dimensionText " + (data.selected ? "selection" : "")}>
            <span key="d1">{data.dimensionHebrew}</span>
            <br />
            <span key="d2">({data.dimensionEnglish})</span>
         </div>
         {data.notes ? (
            <div className={"notes " + (data.selected ? "selection" : "")}>
               {data.notes}
            </div>
         ) : (
            <div className={"notes " + (data.selected ? "selection" : "")}>
               &nbsp;
            </div>
         )}
         {data.shmita ? (
            <div className={"notes " + (data.selected ? "selection" : "")}>
               Sh'mita
            </div>
         ) : (
            <div className={"notes " + (data.selected ? "selection" : "")}>
               &nbsp;
            </div>
         )}
         {data.jubilee ? (
            <div className={"notes " + (data.selected ? "selection" : "")}>
               Jubilee
            </div>
         ) : (
            <div className={"notes " + (data.selected ? "selection" : "")}>
               &nbsp;
            </div>
         )}
      </div>
   );
}

export default SolarCycleCalendarColumn;
