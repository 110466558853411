import React from "react";
import { useNavigate, useLoaderData } from "react-router-dom";
import { List } from "antd-mobile";
import ArticleListItem from "../components/ArticleListItem";
import ArticleCallbacks from "../utils/ArticleCallbacks";
import CategoryCallbacks from "../utils/CategoryCallbacks";
import {prepArticleList} from "../utils/CardExplorerUtils";
import { getDB } from "../utils/UseLocalDataStore";
import store from "../stores/store";

import "./WhatsNewPage.css";

/* default data loader for this page */
export async function loader() {
   console.log(`WhatsNewPage: loader() called`);
   const db = getDB();

   const topArticleSeq = store.getState().mainPage.topArticleSeq;

   // console.log(`WhatsNew topArticleSeq=${topArticleSeq}`,mainPage);

   const articleList = await db.getArticlesAfterSeq(topArticleSeq);

   // console.log("WhatsNew articleList=",articleList);

   prepArticleList(articleList, db, store.dispatch);

   // console.log(`DEBUG: articleList=`, articleList);

   return articleList ? articleList : [];
}

export default function WhatsNewPage() {
   const navigate = useNavigate();
   const whatsNewArticleList = useLoaderData();

   /* read-only view list item */
   function createListItemContent(whatsNewArticleList, onItemClick) {
      return whatsNewArticleList.map((item) => (
         <ArticleListItem
            key={item.slug}
            item={item}
            onClick={onItemClick}
            master={undefined}
            showCategoryName={item.type === "A"}
            showThumbnail={item.type === "A"}
            showSummary={item.type === "A"}
            showArrow={true}
         />
      ));
   }

   async function onItemClick(e, item) {
      console.log(`DEBUG: onItemClick: ${item.slug}`);

      if (item.type === "A") {
         ArticleCallbacks.handleOnArticleClick(item.slug, navigate);
      } else {
         CategoryCallbacks.handleOnCategoryClick(item.slug, navigate);
      }
   }
   return (
      <div className="WhatsNewPage">
         <List
            renderHeader={() => (
               <div className="list-header">
                  <h3>What's New</h3>
               </div>
            )}
            className="my-list"
         >
            {createListItemContent(whatsNewArticleList, onItemClick)}
         </List>
      </div>
   );
}
