import { createSlice } from "@reduxjs/toolkit";

export const AccountInitialState = {
   userId: 0,
   username: undefined,
   sessionExpired: undefined,
   loggedIn: false,
   base64data: undefined,
   roles: {},
   isEditor: false,
   isAdministrator: false,
   travelMode: null,

   covenantOrFirst: "",
   lastName: "",
   longDisplayName: "",

   //@deprecated-----------------------
   ttl: 0,
   //@deprecated-----------------------
};

export const accountSlice = createSlice({
   name: "account",
   initialState: AccountInitialState,
   reducers: {
      reset: () => {
         return AccountInitialState;
      },
      expireSession: (state) => {
         state.sessionExpired = true;
      },

      //@deprecated-----------------------
      updateAccount: (state, action) => {
         state.loggedIn = true;
         state.sessionExpired = false;
         state.roles = action.payload.roles;

         state.covenantOrFirst = action.payload.covenantOrFirst;
         state.lastName = action.payload.lastName;
         state.longDisplayName = action.payload.longDisplayName;
         state.base64data = action.payload.base64data;

         state.ttl = action.payload.ttl;

         state.isEditor =
            action.payload.roles != null &&
            undefined !==
               action.payload.roles.find((i) => "Field App Editor" === i);
         state.isAdministrator =
            action.payload.roles != null &&
            undefined !==
               action.payload.roles.find((i) => "Field App Admin" === i);
      },
      //@deprecated-----------------------

      refreshAccountDetails: (state, action) => {
         state.loggedIn = true;
         state.sessionExpired = false;
         state.roles = action.payload.roles;

         state.userId = action.payload.userId;
         state.username = action.payload.username;
         state.travelMode = action.payload.travelMode;

         state.covenantOrFirst = action.payload.covenantOrFirst;
         state.lastName = action.payload.lastName;
         state.longDisplayName = action.payload.longDisplayName;

         state.isEditor =
            action.payload.roles[50] &&
            action.payload.roles[50].isFieldAppEditor;
         state.isAdministrator =
            action.payload.roles[50] &&
            action.payload.roles[50].isFieldAppAdmin;
      },
      setAvatarData: (state, action) => {
         state.base64data = action.payload;
      },
      setSessionExpiredNotLoggedIn: (state) => {
         state.sessionExpired = true;
         state.loggedIn = false;
      },
      setSessionInfoFromLocalStorage: (state, action) => {
         state.userId = action.payload.userId;
         state.username = action.payload.username;
         state.sessionExpired = false;
         state.loggedIn = true;
      },
   },
});

export const {
   reset,
   updateAccount,
   expireSession,
   refreshAccountDetails,
   setAvatarData,
   setSessionExpiredNotLoggedIn,
   setSessionInfoFromLocalStorage,
} = accountSlice.actions;

export default accountSlice.reducer;
