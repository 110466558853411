import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { List, Switch } from "antd-mobile";
// import { MinusCircleFilled } from "@ant-design/icons";
import { ReactComponent as HamburglerIcon } from "../icons/hamburgler-icon.svg";
import { callSaveSettingAPI } from "../utils/BackendAPI";
import DbRefreshUtils from "../utils/DbRefreshUtils";
import { useLocalDataStore } from "../utils/UseLocalDataStore";
import {
   GUEST_USER_ID,
   updateLocalStoreSettings,
} from "../utils/SettingsUtils";
import {
   replaceSettings,
   replaceCardExplorerIdList,
} from "../features/settingsSlice";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import StrictModeDroppable from "../components/StrictModeDroppable";
import GuestActionSheetMenu from "../components/GuestActionSheetMenu";
import "./CardExplorerSettingsPage.css";

export function loader() {
   //TODO
   return null;
}

export default function CardExplorerSettingsPage() {
   const db = useLocalDataStore();
   const dispatch = useDispatch();
   const online = useSelector((state) => state.online);
   const currentUser = useSelector((state) => state.account);
   const settings = useSelector((state) => state.settings);

   const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
   };

   const onDragEnd = (result) => {
      if (!result.destination) return;

      console.log(`onDragEnd result=`, result);

      // what we need out of the result object is:
      // result.destination.index and
      // result.source.index is the old (pre-drag) index

      const newCardIdList = reorder(
         settings.cardExplorer.cardIdList,
         result.source.index,
         result.destination.index
      );

      // update Redux
      dispatch(replaceCardExplorerIdList(newCardIdList));

      if (currentUser.loggedIn) {
         callSaveSettingAPI(settings).then((x) => {
            DbRefreshUtils.processDbSync(db);
         });
      } else {
         // save settings as Guest

         let newSettings = Object.assign({}, settings, {
            userId: GUEST_USER_ID,
            cardExplorer: Object.assign({}, settings.cardExplorer, {
               cardIdList: newCardIdList,
            }),
         });

         dispatch(replaceSettings(newSettings));
         updateLocalStoreSettings(newSettings, db);
      }
   };

   function toggleOnOff(id, flag, settings, currentUser) {
      console.log(`toggleOnOff: id=${id}, flag=${flag}`);

      // make a copy of the settings
      let newSettings = Object.assign({}, settings, {
         cardExplorer: Object.assign({}, settings.cardExplorer, {
            cardIdList: settings.cardExplorer.cardIdList.map((item) =>
               item.id === id ? { id: item.id, on: flag } : item
            ),
         }),
      });

      if (currentUser.loggedIn) {
         // update mongodb
         callSaveSettingAPI(newSettings).then((x) => {
            // update LocalDataStore
            DbRefreshUtils.processDbSync(db, dispatch);
         });
      } else {
         // save settings as Guest
         newSettings = Object.assign({}, newSettings, {
            userId: GUEST_USER_ID,
         });

         // update LocalDataStore
         updateLocalStoreSettings(newSettings, db);
      }

      // update redux
      dispatch(replaceSettings(newSettings));
   }

   function makeEditRowContents(props) {
      return (
         <div className="editable-row">
            <span className="edit-text">{props.id}</span>
            <HamburglerIcon
               className={
                  props.disabled
                     ? "hamburgler-icon disabled"
                     : "hamburgler-icon"
               }
            />
            <Switch
               id={props.id}
               disabled={props.disabled}
               checked={props.visible}
               platform="ios"
               color="green"
               name={props.id}
               onChange={(flag) => {
                  props.toggleOnOff(
                     props.id,
                     flag,
                     props.settings,
                     props.currentUser
                  );
               }}
            />
         </div>
      );
   }

   /* edit list item */
   const DraggableListItem = (props) => {
      return (
         <Draggable key={props.id} draggableId={props.id} index={props.index}>
            {(provided, snapshot) => (
               <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={{
                     ...provided.draggableProps.style,
                     opacity: snapshot.isDragging ? 0.8 : 1,
                  }}
               >
                  {makeEditRowContents(props)}
               </div>
            )}
         </Draggable>
      );
   };

   function makeItemProps(
      item,
      toggleOnOff,
      settings,
      currentUser,
      onlineFlag,
      index
   ) {
      return {
         id: item.id,
         key: item.id,
         visible: item.on,
         // onItemDelete: onItemDelete,
         settings: settings,
         currentUser: currentUser,
         toggleOnOff: toggleOnOff,
         disabled: !onlineFlag && currentUser.loggedIn,
         index: index,
      };
   }

   function makeItemList(
      idList,
      toggleOnOff,
      settings,
      currentUser,
      onlineFlag
   ) {
      let itemList = idList.map((item, index) => (
         <DraggableListItem
            {...makeItemProps(
               item,
               toggleOnOff,
               settings,
               currentUser,
               onlineFlag,
               index
            )}
         />
      ));

      return itemList;
   }

   // console.log(
   //    `DEBUG: settings.userId=${settings.userId}, currentUser=`,
   //    currentUser
   // );

   return (
      <div className="CardExplorerSettingsPage">
         <List
            header={
               <div className="list-header">
                  <h2>Card Explorer Settings</h2>
                  <span className="instruction">
                     Click the flipswitches to enable or disable. Drag and drop
                     the list items to rearrange.
                  </span>
               </div>
            }
            className="CardExplorerSettingsList"
         >
            <DragDropContext onDragEnd={onDragEnd}>
               <StrictModeDroppable droppableId="droppable">
                  {(droppableProvided) => (
                     <div ref={droppableProvided.innerRef}>
                        {makeItemList(
                           settings.cardExplorer.cardIdList,
                           toggleOnOff,
                           settings,
                           currentUser,
                           online
                        )}
                        {droppableProvided.placeholder}
                     </div>
                  )}
               </StrictModeDroppable>
            </DragDropContext>
         </List>
         <GuestActionSheetMenu
            visible={
               currentUser.loggedIn === false && settings.userId === undefined
            }
         />
      </div>
   );
}
