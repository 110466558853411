import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CardType } from "../models/CardType";
import { ReactComponent as StarOutlined } from "../icons/sun-icon.svg";
import Card from "./Card";
import SolarCycleCalendarWidget from "./SolarCycleCalendarWidget";
import SolarCycleCalendarCallbacks from "../utils/SolarCycleCalendarCallbacks";

import "./SolarCycleCalendarCard.css";

export default function SolarCycleCalendarCard() {
   const [scrolled, setScrolled] = useState(false);
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const divRef = React.createRef();
   const scrollerRef = React.createRef();
   const widgetRef = React.createRef();
   const calendarData = useSelector(
      (state) => state.solarCycleCalendar.cardData
   );

   useEffect(() => {
      if (
         divRef.current &&
         scrollerRef.current &&
         widgetRef.current &&
         !scrolled
      ) {
         console.log("SolarCycleCalendarCard.useEffect() updating DOM");

         // this is the X position of the selected column within its frame
         let selectionLocX = divRef.current.getBoundingClientRect().x;

         let scrollerLocX = scrollerRef.current.getBoundingClientRect().x;

         // this is the width in px of the selected column
         let selectionLocWidthPx = divRef.current.getBoundingClientRect().width;

         // this is the width in px of the displaying viewport (i.e. the Card width)
         let scrollerWidthPx =
            scrollerRef.current.getBoundingClientRect().width;

         // new position that the scrollable area should move.
         let newPosX =
            selectionLocX -
            scrollerLocX -
            (scrollerWidthPx - selectionLocWidthPx) / 2;

         if (newPosX > 1) {
            widgetRef.current.style.marginLeft = "-" + newPosX + "px";
            setScrolled(true);
         }
         console.log(
            `SolarCycleCalendarPage.useEffect() selectLocWidthPx=${selectionLocWidthPx}, scrollerWidthPx=${scrollerWidthPx}, \nselectionLocX=${selectionLocX}, scrollerLocX=${scrollerLocX} \nnewPosX=${newPosX}`
         );
      }
   }, [scrolled, setScrolled, divRef, scrollerRef, widgetRef]);

   //onMenuClick, onClickNavLink

   function onMenuClick() {
      navigate("/settings/cardexplorer");
   }

   function onClickNavLink() {
      SolarCycleCalendarCallbacks.loadSolarCycleCalendarPage(
         new Date().getFullYear(),
         dispatch,
         navigate
      );
   }

   return (
      <Card
         key="SolarCycleCalendarCard"
         className="SolarCycleCalendarCard"
         headerIcon={<StarOutlined className="headerIcon" />}
         titleText={CardType.SOLAR_CYCLE_CALENDAR_CARD}
         onMenuIconClick={onMenuClick}
         onMoreClick={onClickNavLink}
      >
         <div
            className="calendarContent"
            ref={(node) => {
               if (node) scrollerRef.current = node;
            }}
         >
            <SolarCycleCalendarWidget
               calendarData={calendarData}
               minify={true}
               selectionRef={divRef}
               widgetRef={widgetRef}
            />
         </div>
      </Card>
   );
}

{
   /*

      <Card
         className="SolarCycleCalendarCard"
         title={
            <div className="title">
               <StarOutlined />
               <span className="heading">
                  {CardType.SOLAR_CYCLE_CALENDAR_CARD}
               </span>
            </div>
         }
         extra={
            <Button className="cardMenuButton" onClick={(e) => onMenuClick()}>
               <EllipsisOutlined rotate="90" />
            </Button>
         }
      >
         <div
            className="content calendarContent"
            ref={(node) => {
               if (node) scrollerRef.current = node;
            }}
         >
            <SolarCycleCalendarWidget
               calendarData={calendarData}
               minify={true}
               selectionRef={divRef}
               widgetRef={widgetRef}
            />
         </div>
         <div className="footer">
            <button
               className="smoresButton"
               onClick={(e) => {
                  onClickNavLink();
               }}
            >
               Click here for more
            </button>
         </div>
      </Card>

*/
}
