import React from "react";
import { NavLink } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

function ArticleItemLink({ type, slug, name, alias, onClick }) {
   function createCategoryLink(slug, categoryName, onCategoryLinkClick) {
      return onCategoryLinkClick ? (
         <NavLink
            key={uuidv4()}
            to={"/category/" + slug}
            onClick={(e) => {
               onCategoryLinkClick(e, slug);
            }}
         >
            {categoryName}
         </NavLink>
      ) : (
         <span>{categoryName}</span>
      );
   }

   function createArticleLink(slug, name, alias, onArticleLinkClick) {
      return onArticleLinkClick ? (
         <NavLink
            key={uuidv4()}
            to={"/article/" + slug}
            onClick={(e) => onArticleLinkClick(e, slug)}
         >
            {name} {alias && alias.length > 0 ? "(" + alias + ")" : ""}{" "}
         </NavLink>
      ) : (
         <span>
            {name} {alias && alias.length > 0 ? "(" + alias + ")" : ""}{" "}
         </span>
      );
   }

   function createImgLink(href, src) {
      return (
         <a href={href} target="_blank" rel="noopener noreferrer">
            <img
               className="article-info-button"
               src={require(`../images/${src}`)}
               alt="Click for More"
            />
         </a>
      );
   }

   function createItemLink(type, slug, name, alias, onClick) {
      if (type === "article") {
         return createArticleLink(slug, name, alias, onClick);
      } else if (type === "category") {
         return createCategoryLink(slug, name, onClick);
      } else if (type === "imglink") {
         return createImgLink(slug, name);
      }
   }

   return createItemLink(type, slug, name, alias, onClick);
}

export default ArticleItemLink;
