const _is_iphone = new RegExp("\\biPhone\\b|\\biPod\\b", "i").test(
   window.navigator.platform
);

const _is_safari =
   _is_iphone &&
   !!window.navigator.userAgent.match(/WebKit/i) &&
   !window.navigator.userAgent.match(/CriOS/i) &&
   !window.navigator.userAgent.match(/FxiOS/i) &&
   !window.navigator.userAgent.match(/OPiOS/i);

export function isIPhone() {
   return _is_iphone;
}

export function isSafariBrowser() {
   return _is_safari;
}

export function isAppInstalled() {
   return (
      navigator.standalone ||
      window.matchMedia("(display-mode: standalone)").matches
   );
}
