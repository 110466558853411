import { Button } from "antd-mobile";
import React from "react";
import "./ActionSheetMenu.css";

export default function ActionSheetMenu({
   title,
   className,
   dataSeed,
   children,
   visible,
   message,
   onCloseClick,
}) {
   return (
      <div
         className={className + " ActionSheetMenu"}
         style={{
            display: visible ? "block" : "none",
            zIndex: "6000",
         }}
      >
         <div
            role="document"
            className="action-sheet action-sheet-normal"
         >
            <div className="action-sheet-content">
               <div className="action-sheet-body">
                  <div data-seed={dataSeed}>
                     {title ? <h3 className="action-sheet-title">{title}</h3> : ""}
                     {message ? (
                        <div className="action-sheet-message">{message}</div>
                     ) : (
                        ""
                     )}
                     <div className="action-sheet-button-list" role="group">
                        {children}
                     </div>
                  </div>
               </div>
               <Button
                  onClick={(e) => {
                     onCloseClick(e);
                  }}
                  className="action-sheet-cancel-button"
               >
                  Close
               </Button>
            </div>
         </div>
         <div
            className="action-sheet-mask"
            onClick={(e) => {
               onCloseClick(e);
            }}
         ></div>
      </div>
   );
}
