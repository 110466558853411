//const axios = require("axios");
import Axios from "axios";

const timeoutS = 30;
const timeoutMS = timeoutS * 1000;

/* returns a Promise */
export async function callSaveRelationsAPI(relArray) {
   const url = "/api/relation/";
   console.log(`Calling callSaveRelationsAPI ... url=${url}`, relArray);
   return Axios.post(url, relArray);
}

export async function callSaveImagesAPI(newImagesArray) {
   const url = "/api/image/";
   console.log(`Calling callSaveImagesAPI ... url=${url}`);
   return Axios.post(url, newImagesArray);
}

export async function callDeleteImagesAPI(imageSeqArray) {
   if (imageSeqArray.length > 0) {
      const url = "/api/image/";
      console.log(`Calling callDeleteImagesAPI ... url=${url}`, imageSeqArray);
      return Axios.delete(url, { data: imageSeqArray });
   } else {
      return true;
   }
}

export async function callSaveArticleAPI(rec) {
   const url = "/api/article/";
   console.log(`Calling callSaveArticleAPI ... url=${url}`, rec);
   return Axios.post(url, rec);
}

export async function callSaveCategoryAPI(rec) {
   const url = "/api/category/";
   console.log(`Calling callSaveCategoryAPI ... url=${url}`, rec);
   return Axios.post(url, rec);
}

export async function callDeleteArticleAPI(slug) {
   const url = "/api/article/" + slug;
   console.log(`Calling callDeleteArticleAPI ... url=${url}`);
   return Axios.delete(url);
}

export async function callDeleteRelationsAPI(slug) {
   const url = "/api/relation/" + slug;
   console.log(`Calling callDeleteRelationsAPI ... url=${url}`);
   return Axios.delete(url);
}

export async function callDeleteRelationListAPI(relationArray) {
   const url = "/api/relation/";
   console.log(`Calling callDeleteRelationsAPI ... url=${url}`, relationArray);
   return Axios.delete(url, { data: relationArray });
}

export async function callDeleteCategoryAPI(slug) {
   const url = "/api/category/" + slug;
   console.log(`Calling callDeleteCategoryAPI ... url=${url}`);
   return Axios.delete(url);
}

/** deprecated */
export async function callLoginAPI(username, password) {
   const url = "/api/login/";
   console.log(`Calling callLoginAPI ... url=${url}`);
   return Axios.post(url, { username: username, password: password }, {timeout: timeoutMS });
}

/** TODO - need to add auth header once we've converted over */
export async function callResetPasswordAPI(email) {
   const url = "/api/login/resetPassword";
   console.log(`Calling callResetPasswordAPI email=${email}... url=${url}`);
   return Axios({
      method: "post",
      url,
      data: { email: email },
   });
}

export async function callGetLatestSettings(userId,maxSettingSeq) {
   const url = `/api/setting/latest/${userId}/${maxSettingSeq}`;
   console.log(`Calling api ... ${url}`);
   const { data: settingEvents } = await Axios.get(url);
   return settingEvents;
}

export async function callSaveSettingAPI(rec) {
   const url = "/api/setting/";
   delete rec._id; /*cleanup*/
   console.log(`Calling callSaveSettingAPI ... url=${url}`, rec);
   return Axios.post(url, rec);
}

export async function callDeleteSettingFavoriteAPI(slug) {
   const url = "/api/setting/favorites/" + slug;
   console.log(`Calling callDeleteSettingFavoriteAPI ... url=${url}`);
   return Axios.delete(url);
}

export async function callKeepaliveAPI() {
   //console.log("Calling health check...");
   return Axios.get("/api/health");
}

