import { Modal } from "antd-mobile";
import store from "../stores/store";
import { GUEST_USER_ID } from "../utils/SettingsUtils";
import { reset as resetAccount } from "../features/accountSlice";
import {
   replaceSettings,
   reset as resetSettings,
} from "../features/settingsSlice";
import LocalStorage from "../utils/LocalStorage";
import { getDB } from "../utils/UseLocalDataStore";

const alert = Modal.alert;

export default function confirmLogout() {
   const db = getDB();

   //      closeOnAction: true,



   return    alert({
      header: <h2 className="ConfirmLogoutTitle">Logout</h2>,
      content: "Are you sure you want to logout?",
      showCloseButton: true,
      confirmText: "YES",
      onConfirm: () => {
         console.log("LogoutConfirm onConfig");
         LocalStorage.removeCurrentSessionInfo();
         store.dispatch(resetAccount());

         db.getSetting(GUEST_USER_ID).then((settings) => {
            if (settings) {
               store.dispatch(replaceSettings(settings));
               // loadFavoritesPage(db);
            } else {
               store.dispatch(resetSettings());
               // store.dispatch(resetFavoritesPageToDefaults());
            }
         });
      },
   });;
}
