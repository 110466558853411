import React from "react";
import { Badge } from "antd-mobile";
import { StarOutlined, StarFilled } from "@ant-design/icons";
import "./ArticleListHeader.css";

export default function ArticlesListHeader({
   category,
   onStarClick,
   currentUser,
   online,
   settings,
}) {
   return (
      <div className="article-list-header">
         <h2>
            {category.name}{" "}
            <Badge content={category.dependencyCount} overflowCount={999} />
         </h2>
         <div
            onClick={(e) =>
               onStarClick(category.slug, settings, currentUser, online)
            }
         >
            {settings.favorites.includes(category.slug) ? (
               <StarFilled className="star-icon star-icon-filled" />
            ) : (
               <StarOutlined className="star-icon" />
            )}
         </div>
      </div>
   );
}
