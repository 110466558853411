import React from "react";
import ReactDOM from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import store from "./stores/store";
import createRoutes from "./routes/Routes";
import ArticleUtils from "./utils/ArticleUtils";
import LocalDataStore from "./utils/LocalDataStore";
import { setDB } from "./utils/UseLocalDataStore";
import setupInterceptors from "./services/SetupAxiosInterceptors";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import "./index.css";

// import { updateReady } from "./actions/actions";

if (navigator.storage && navigator.storage.persist) {
   navigator.storage.persist();
}

const lds = new LocalDataStore();
setDB(lds);
const startedTimestamp = ArticleUtils.getTimestampMillis();

const router = createBrowserRouter(createRoutes(startedTimestamp));

const root = ReactDOM.createRoot(document.getElementById("root"));

console.log(
   `appName=${process.env.REACT_APP_NAME} appVersion=${process.env.REACT_APP_VERSION}`
);

root.render(
   <React.StrictMode>
      <ReduxProvider store={store}>
         <RouterProvider router={router} />
      </ReduxProvider>
   </React.StrictMode>
);

setupInterceptors(store);

// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
   onUpdate: (registration) => {
      const waitingServiceWorker = registration.waiting;

      if (waitingServiceWorker) {
         waitingServiceWorker.addEventListener("statechange", (event) => {
            if (event.target.state === "activated") {
               window.location.reload();
            }
         });
         waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
      }
   },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
