import React from "react";
import { useSelector } from "react-redux";
import FavoritesCard from "../components/FavoritesCard";
import HelpfulsCard from "../components/HelpfulsCard";
import ArticleOfDayCard from "../components/ArticleOfDayCard";
import ImageOfDayCard from "../components/ImageOfDayCard";
import WhatsNewCard from "../components/WhatsNewCard";
import LookingUpCalendarCard from "../components/LookingUpCalendarCard";
import MorningTeaCard from "../components/MorningTeaCard";
import BlueArmyRadioCard from "../components/BlueArmyRadioCard";
import SolarCycleCalendarCard from "../components/SolarCycleCalendarCard";
import { CardType } from "../models/CardType";
import { isCardEnabled } from "../utils/SettingsUtils";

import "./CardExplorerPage.css";

/* default data loader for this page */
export function loader() {
   return null;
}

export default function CardExplorerPage() {
   const settings = useSelector((state) => state.settings);

   function getCardElement(id, settings) {
      switch (id) {
         case CardType.FAVORITES_CARD:
            return settings.favorites &&
               settings.favorites.length > 0 &&
               isCardEnabled(settings, CardType.FAVORITES_CARD) ? (
               <FavoritesCard key="favoritesCard" />
            ) : (
               ""
            );

         case CardType.LOOKING_UP_CALENDAR_CARD:
            return <LookingUpCalendarCard key={id} />;

         case CardType.SOLAR_CYCLE_CALENDAR_CARD:
            return <SolarCycleCalendarCard key={id} />;

         case CardType.HELPFULS_CARD:
            return <HelpfulsCard key={id} />;

         case CardType.MORNING_TEA_CARD:
            return <MorningTeaCard key={id} />;

         case CardType.BLUEARMY_RADIO_CARD:
            return <BlueArmyRadioCard key={id} />;

         case CardType.IMAGE_OF_MOMENT_CARD:
            return <ImageOfDayCard key={id} />;

         case CardType.WHATS_NEW_CARD:
            return <WhatsNewCard key={id} />;

         case CardType.ARTICLE_OF_MOMENT_CARD:
            return <ArticleOfDayCard key={id} />;

         default:
      }
   }

   return (
      <div className="CardExplorerPage">
         {settings.cardExplorer.cardIdList.map((card) => {
            return card.on ? getCardElement(card.id, settings) : "";
         })}
      </div>
   );
}
