import React from "react";
import StartupHandler from "../components/StartupHandler";
import App, { loader as appLoader } from "../components/App";

import ErrorPage from "../pages/ErrorPage";
import NoMatch from "../components/NoMatch";

import DefaultOutletPage, {
   loader as defaultOutletLoader,
} from "../pages/DefaultOutletPage";
import BrowseCategoriesPage, {
   loader as categoriesLoader,
} from "../pages/BrowseCategoriesPage";
import ArticlePage, { loader as articleLoader } from "../pages/ArticlePage";
import ArticleAudioPage, { loader as articleAudioLoader } from "../pages/ArticleAudioPage";
import CategoryPage, { loader as categoryLoader } from "../pages/CategoryPage";
import AdminArticlePage, {
   loader as adminArticleLoader,
} from "../pages/AdminArticlePage";
import AdminCategoryPage, {
   loader as adminCategoryLoader,
} from "../pages/AdminCategoryPage";
import MasterIndexPage, {
   loader as masterIndexLoader,
} from "../pages/MasterIndexPage";
import LoginPage, { action as loginAction } from "../pages/LoginPage";
import FavoritesPage, {
   loader as favoritesLoader,
} from "../pages/FavoritesPage";
import CardExplorerPage, {
   loader as cardExplorerLoader,
} from "../pages/CardExplorerPage";
import SettingsPage, { loader as settingsLoader } from "../pages/SettingsPage";
import HelpfulsPage, { loader as helpfulsLoader } from "../pages/HelpfulsPage";
import CardExplorerSettingsPage, {
   loader as cardExplorerSettingsLoader,
} from "../pages/CardExplorerSettingsPage";
import ResequenceArticlesPage, {
   loader as resequenceArticlesLoader,
} from "../pages/ResequenceArticlesPage";
import WhatsNewPage, { loader as whatsNewLoader } from "../pages/WhatsNewPage";
import FindYourAccountPage, {
   action as findYourAccountAction,
} from "../pages/FindYourAccountPage";
import SolarCycleCalendarPage, {
   loader as solarCycleLoader,
} from "../pages/SolarCycleCalendarPage";
import StartPagePickerPage, {
   loader as startPagePickerLoader,
} from "../pages/StartPagePickerPage";
import MainPage from "../pages/MainPage";
import LoginPageRedirector from "../components/LoginPageRedirector";

import "./RoutesPageTransitions.css";

//{ db, startedTimestamp }

//~~~ FIXME

export default function createRoutes(startedTimestamp) {
   return [
      {
         path: "/",
         element: (
            <App>
               <StartupHandler startedTimestamp={startedTimestamp}>
                  <MainPage />
               </StartupHandler>
               <LoginPageRedirector/>
            </App>
         ),
         errorElement: <ErrorPage />,
         loader: appLoader,
         children: [
            {
               errorElement: <ErrorPage />,
               children: [{ path: "*", element: <NoMatch /> }],
            },
            {
               index: true,
               element: <DefaultOutletPage />,
               loader: defaultOutletLoader,
            },
            {
               path: "/categories",
               element: <BrowseCategoriesPage />,
               loader: categoriesLoader,
            },
            {
               path: "/category/:slug",
               element: <CategoryPage />,
               loader: categoryLoader,
            },
            {
               path: "/cardexplorer",
               element: <CardExplorerPage />,
               loader: cardExplorerLoader,
            },
            {
               path: "/helpfuls",
               element: <HelpfulsPage />,
               loader: helpfulsLoader,
            },
            {
               path: "/solar-cycle-calendar",
               element: <SolarCycleCalendarPage />,
               loader: solarCycleLoader,
            },
            {
               path: "/article/:slug",
               element: <ArticlePage />,
               loader: articleLoader,
            },
            {
               path: "/article/:slug/audio",
               element: <ArticleAudioPage />,
               loader: articleAudioLoader,
            },
            {
               path: "/whatsnew",
               element: <WhatsNewPage />,
               loader: whatsNewLoader,
            },
            {
               path: "/master-index",
               element: <MasterIndexPage />,
               loader: masterIndexLoader,
            },
            {
               path: "/favorites",
               element: <FavoritesPage />,
               loader: favoritesLoader,
            },
            {
               path: "/forgot",
               element: <FindYourAccountPage />,
               action: findYourAccountAction,
            },
            {
               path: "/login",
               element: <LoginPage />,
               action: loginAction,
            },
            {
               path: "/settings/cardexplorer",
               element: <CardExplorerSettingsPage />,
               loader: cardExplorerSettingsLoader,
            },
            {
               path: "/admin/category/:slug/resequence",
               element: <ResequenceArticlesPage />,
               loader: resequenceArticlesLoader,
            },
            {
               path: "/settings",
               element: <SettingsPage />,
               loader: settingsLoader,
            },
            {
               path: "/settings/start-page-picker",
               element: <StartPagePickerPage />,
               loader: startPagePickerLoader,
            },
            {
               path: "/admin/category/new",
               element: <AdminCategoryPage />,
               loader: adminCategoryLoader,
            },
            {
               path: "/admin/category/:slug",
               element: <AdminCategoryPage />,
               loader: adminCategoryLoader,
            },
            {
               path: "/admin/category/:slug/new",
               element: <AdminCategoryPage />,
               loader: adminCategoryLoader,
            },
            {
               path: "/admin/article/:slug/new",
               element: <AdminArticlePage />,
               loader: adminArticleLoader,
            },
            {
               path: "/admin/article/:slug",
               element: <AdminArticlePage />,
               loader: adminArticleLoader,
            },
         ],
      },
   ];
}
