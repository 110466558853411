import React from "react";
import "./NoResults.css";

/* wave: &#x1f30a; lion:&#x1F981; printer:&#x1F5F3;  poopi:&#128169; puppy:&#x1F436; wave:&#x1f30a; volcano:&#x1f30b; */

function NoResults(props) {
   return (
      <div className="NoResults">
         <span role="img" aria-label="No Results">&#x1F937;</span>
         {props.instructions ? (
            <span className="instructions">{props.instructions}</span>
         ) : (
            ""
         )}
      </div>
   );
}

export default NoResults;
