import React from "react";
import WikiTextConverter from "../utils/WikiTextConverter";
import ArticleItemLink from "../components/ArticleItemLink";
import { v4 as uuidv4 } from "uuid";

function ArticleTextRich({ text, onArticleLinkClick, onCategoryLinkClick }) {
   let tableRows = null;
   let rowCells = null;
   let rowCount = 0;

   function renderParsedItem(r) {

      //console.log(`renderParsedItem r=${r}, r.type=${r.type}, r.value=${r.value}, isArray=${Array.isArray(r.value)}`);

      if (r.type === "text") {
         return (
            <span
               key={uuidv4()}
               dangerouslySetInnerHTML={{
                  __html: r.value,
               }}
            ></span>
         );
      } else if (
         r.type === "link" &&
         (r.href.startsWith("#") || r.href.startsWith("a#"))
      ) {
         let slug = r.href.split("#")[1];
         return (
            <ArticleItemLink
               key={slug}
               type="article"
               slug={slug}
               name={r.display}
               alias=""
               onClick={onArticleLinkClick}
            />
         );
      } else if (r.type === "link" && r.href.startsWith("c#")) {
         let slug = r.href.split("#")[1];
         return (
            <ArticleItemLink
               key={slug}
               type="category"
               slug={slug}
               name={r.display}
               alias={undefined}
               onClick={onCategoryLinkClick}
            />
         );
      } else if (r.type === "imglink") {
         let slug = r.href;
         return (
            <ArticleItemLink
               key={slug}
               type="imglink"
               slug={slug}
               name={r.img}
               alias={undefined}
               onClick={undefined}
            />
         );
      } else if (r.type === "openTag") {
         tableRows = [];
      } else if (r.type === "tableRow") {
         if (tableRows) {
            rowCells = [];
            tableRows.push(<tr key={rowCount}>{rowCells}</tr>);
            rowCount++;
         }
      } else if (r.type === "tableCell") {
         if (rowCells) {
            let resultArray = [];

            if (Array.isArray(r.value)) {
               resultArray = [];
               r.value.forEach((i) => {
                  resultArray.push(renderParsedItem(i));
               });
            } else {
              resultArray.push(<span key={uuidv4()} dangerouslySetInnerHTML={{  __html: r.value, }} ></span>);
            }

            rowCells.push(<td>{resultArray}</td>);
         }
      } else if (r.type === "closeTag") {
         if (tableRows && rowCells) {
            let table = (
               <table
                  className="wikitable"
                  style={{
                     textAlign: "left",
                     marginLeft: "auto",
                     marginRight: "auto",
                  }}
               >
                  <tbody>{tableRows}</tbody>
               </table>
            );
            elementArray.push(table);
            tableRows = null;
            rowCells = null;
         }
      } else {
         elementArray.push(
            <a
               key={r.href}
               href={r.href}
               target="_blank"
               rel="noopener noreferrer"
            >
               {r.display}
            </a>
         );
      }
   }

   let basicSubstitutions = WikiTextConverter.applyAllWikiTextTransformations(
      text
   );
   //console.log(basicSubstitutions);
   let recordArray = WikiTextConverter.parseIntoMetaArray(basicSubstitutions);
   let elementArray = [];
   let item;

   recordArray.forEach((r) => {
      item = renderParsedItem(r);
      if (item) {
         elementArray.push(item);
      }
   });

   return <React.Fragment>{elementArray}</React.Fragment>;
}

export default ArticleTextRich;
