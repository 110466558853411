import React from "react";
import { useLoaderData, useNavigate, NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "antd-mobile";
import ArticleTextRich from "../components/ArticleTextRich";
import ArticleItemLink from "../components/ArticleItemLink";
import { v4 as uuidv4 } from "uuid";
import { StarOutlined, StarFilled } from "@ant-design/icons";
import MediaPlayer from "../components/MediaPlayer";
import ArticleCallbacks from "../utils/ArticleCallbacks";
import CategoryCallbacks from "../utils/CategoryCallbacks";
import { toggleFavorite } from "../utils/SettingsUtils";
import { getDB, useLocalDataStore } from "../utils/UseLocalDataStore";
import NoResults from "../components/NoResults";
import "./ArticlePage.css";

/** loader function for this page */
export async function loader({ params }) {
   //console.log(`ArticlePage loader() called, slug=${params.slug}`);
   const db = getDB();

   //Note, this function should return the article data rather than put into Global State
   const results = await ArticleCallbacks.loadFullArticleDataPromise(
      params.slug,
      db
   );

   return [results.article, results.relationArray];
}

export default function ArticlePage() {
   let articleContent;

   const db = useLocalDataStore();
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const online = useSelector((state) => state.mainPage.online);
   const settings = useSelector((state) => state.settings);
   const currentUser = useSelector((state) => state.account);
   const [article, relationsArray] = useLoaderData();

   function onArticleLinkClick(e, slug) {
      //ArticleCallbacks.handleOnArticleClick(e, slug, db);
      // navigate("/article/" + slug);
      ArticleCallbacks.handleOnArticleClick(slug, navigate);
   }

   function onCategoryLinkClick(e, slug) {
      CategoryCallbacks.handleOnCategoryClick(slug, navigate);
      // navigate("/category/" + slug);
   }

   /* https://reactrouter.com/en/main/start/tutorial#mutations-without-navigation */
   function onStarClick(slug, settings, currentUser, online) {
      console.log(`onStarClick(): ${slug} called`);
      toggleFavorite(slug, settings, currentUser, db, dispatch, online);
   }

   function onImagePopupClose(tag) {
      console.log(`onImagePopupClose() tag=${tag}`);
      // if (tag.includes("image2")) {
      //    dispatch(toggleArticlePopupVisible("img2PopupVisible"));
      // } else if (tag.includes("image3")) {
      //    dispatch(toggleArticlePopupVisible("img3PopupVisible"));
      // }
   }

   function onImageClick(tag) {
      console.log(`onImageClick() tag=${tag}`);
      // if (tag.includes("image2")) {
      //    dispatch(toggleArticlePopupVisible("img2PopupVisible"));
      // } else if (tag.includes("image3")) {
      //    dispatch(toggleArticlePopupVisible("img3PopupVisible"));
      // }
   }

   function closest(el, selector) {
      const matchesSelector =
         el.matches ||
         el.webkitMatchesSelector ||
         el.mozMatchesSelector ||
         el.msMatchesSelector;
      while (el) {
         if (matchesSelector.call(el, selector)) {
            return el;
         }
         el = el.parentElement;
      }
      return null;
   }

   function onWrapTouchStart(e) {
      // fix touch to scroll background page on iOS
      if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
         return;
      }
      const pNode = closest(e.target, ".am-modal-content");
      if (!pNode) {
         e.preventDefault();
      }
   }

   function createImagePopup(img, visible, tag, onPopupClose) {
      return (
         <Modal
            className="ImagePopup"
            visible={visible}
            maskClosable={false}
            onClose={(e) => onPopupClose(tag)}
            title=""
            footer={[
               {
                  text: "✕",
                  onPress: () => {
                     onPopupClose(tag);
                  },
               },
            ]}
            wrapProps={{ onTouchStart: onWrapTouchStart }}
         >
            {img}
         </Modal>
      );
   }

   function createImage(seq, url, filename, className, onClick) {
      return (
         <img
            key={seq}
            src={url}
            alt={filename}
            className={className}
            onClick={onClick}
         ></img>
      );
   }

   function createFeaturedImage(article, onClick) {
      if (
         typeof article.imageSeqArray === "undefined" ||
         article.imageSeqArray === null
      ) {
         return "";
      } else if (article.imageSeqArray.length > 0) {
         return createImage(
            article.imageSeqArray[0].seq,
            article.imageSeqArray[0].base64data,
            article.imageSeqArray[0].filename,
            "featured-image",
            () => {
               onClick("img1");
            }
         );
      }
   }

   function createSupplementalImages(article, onClick, onImagePopupClose) {
      if (
         typeof article.imageSeqArray === "undefined" ||
         article.imageSeqArray === null
      ) {
         return "";
      } else if (article.imageSeqArray.length > 1) {
         //console.log(article.imageSeqArray);
         return (
            <div className="supplementalPhotos">
               {createImage(
                  article.imageSeqArray[1].seq,
                  article.imageSeqArray[1].base64data,
                  article.imageSeqArray[1].filename,
                  "",
                  (e) => {
                     onClick("image2Popup");
                  }
               )}
               {createImagePopup(
                  createImage(
                     article.imageSeqArray[1].seq,
                     article.imageSeqArray[1].base64data,
                     article.imageSeqArray[1].filename,
                     "",
                     (e) => {
                        onClick("image2Popup");
                     }
                  ),
                  article.img2PopupVisible,
                  "image2Popup",
                  onImagePopupClose
               )}

               {article.imageSeqArray.length > 2
                  ? createImage(
                       article.imageSeqArray[2].seq,
                       article.imageSeqArray[2].base64data,
                       article.imageSeqArray[2].filename,
                       "",
                       (e) => {
                          onClick("image3Popup");
                       }
                    )
                  : ""}
               {article.imageSeqArray.length > 2
                  ? createImagePopup(
                       createImage(
                          article.imageSeqArray[2].seq,
                          article.imageSeqArray[2].base64data,
                          article.imageSeqArray[2].filename,
                          "",
                          (e) => {
                             onClick("image3Popup");
                          }
                       ),
                       article.img3PopupVisible,
                       "image3Popup",
                       onImagePopupClose
                    )
                  : ""}
            </div>
         );
      }
   }

   function createCopyrightFooter() {
      return (
         <div className="copyrightFooter">
            © {new Date().getFullYear()} - Blue Flame 47, Inc. Copying,
            reprinting, or transferring this file for purposes excluding
            commercial gain or profit is{" "}
            <strong>permitted and encouraged</strong>. All rights reserved. This
            publication is protected by the copyright laws of the United States
            of America. This file may not be transferred, copied, or reprinted
            for commercial gain or profit. <u>www.blueflame47.com</u>
         </div>
      );
   }

   function ArticleBody({
      article,
      settings,
      onCategoryLinkClick,
      onStarClick,
      onImageClick,
   }) {
      return (
         <div className="article-body">
            {createFeaturedImage(article, onImageClick)}

            <div className="article-content">
               <h1 className="heading">
                  <span>
                     {article.name}{" "}
                     {article.alias && article.alias.length > 0
                        ? "(" + article.alias + ")"
                        : ""}
                  </span>
                  <div
                     onClick={(e) =>
                        onStarClick(article.slug, settings, currentUser, online)
                     }
                  >
                     {settings.favorites.includes(article.slug) ? (
                        <StarFilled className="star-icon star-icon-filled" />
                     ) : (
                        <StarOutlined className="star-icon" />
                     )}
                  </div>
               </h1>
               <p className="summary">{article.summary}</p>
               <p>
                  Category:{" "}
                  {article.category.length > 0 ? (
                     <ArticleItemLink
                        type="category"
                        slug={article.category}
                        name={article.categoryName}
                        onClick={onCategoryLinkClick}
                     />
                  ) : (
                     ""
                  )}
               </p>
               <div className="description">
                  <ArticleTextRich
                     text={article.text}
                     onArticleLinkClick={onArticleLinkClick}
                     onCategoryLinkClick={onCategoryLinkClick}
                  />
               </div>
            </div>
         </div>
      );
   }

   function createRelationItem(item, onArticleLinkClick) {
      return (
         <tr key={uuidv4()}>
            <td className="side">
               {article.category.length > 0 ? (
                  <ArticleItemLink
                     type="category"
                     slug={item.category}
                     name={item.categoryName}
                     alias={undefined}
                     onClick={onCategoryLinkClick}
                  />
               ) : (
                  ""
               )}
            </td>
            <td>
               <ArticleItemLink
                  type="article"
                  slug={item.otherSlug}
                  name={item.name}
                  alias={item.alias}
                  onClick={onArticleLinkClick}
               />
            </td>
         </tr>
      );
   }

   function createAudioPlayer(online) {
      return online &&
         article.audioUrlArray &&
         article.audioUrlArray.length > 0 ? (
         <div className="article-media-player audio-player">
            <h2>Listen to Audio</h2>

            <NavLink to="audio">Listen to this Chapter Audio Here</NavLink>

            {/* <MediaPlayer
               className="audio-player-wrapper"
               volume={0.3}
               controls={true}
               urlArray={article.audioUrlArray}
            /> */}
         </div>
      ) : null;
   }

   function createVideoPlayer(online) {
      return online &&
         article.videoUrlArray &&
         article.videoUrlArray.length > 0 ? (
         <div className="article-media-player">
            <h2>Watch Video</h2>
            <MediaPlayer
               className="video-player-wrapper"
               volume={0.3}
               light={true}
               controls={true}
               urlArray={article.videoUrlArray}
            />
         </div>
      ) : null;
   }

   function RelatedBox({ relationsArray, onArticleLinkClick }) {
      return relationsArray.length > 0 ? (
         <div className="related-box">
            <table>
               <tbody>
                  <tr>
                     <th colSpan="2">Related To</th>
                     <th></th>
                  </tr>
                  {relationsArray.map((r) =>
                     createRelationItem(r, onArticleLinkClick)
                  )}
               </tbody>
            </table>
         </div>
      ) : (
         ""
      );
   }

   if (article && article.slug) {
      articleContent = (
         <div className="article-page">
            <ArticleBody
               article={article}
               settings={settings}
               onCategoryLinkClick={onCategoryLinkClick}
               onStarClick={onStarClick}
               onImageClick={onImageClick}
            />
            {createSupplementalImages(article, onImageClick, onImagePopupClose)}
            {createAudioPlayer(online)}
            {createVideoPlayer(online)}
            <RelatedBox
               relationsArray={relationsArray}
               onArticleLinkClick={onArticleLinkClick}
               onCategoryLinkClick={onCategoryLinkClick}
            />
            {createCopyrightFooter()}
            <br />
            <br />
         </div>
      );
   } else {
      articleContent = (
         <div className="article-page">
            <NoResults
               instructions={<span>Sorry, that article does not exist</span>}
            />
         </div>
      );
   }

   return articleContent;
}
