import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FileImageOutlined } from "@ant-design/icons";
import { CardType } from "../models/CardType";
import ArticleCallbacks from "../utils/ArticleCallbacks";
import Card from "./Card";

import "./ImageOfDayCard.css";

export default function ImageOfDayCard() {
   const article = useSelector((state) => state.cardExplorerPage.imageOfDayArticle);
   const imgSeq = useSelector((state) => state.cardExplorerPage.imageOfDaySeq);
   const navigate = useNavigate();

   function onMenuClick() {
      //console.log("onMenuClick()");
      navigate("/settings/cardexplorer");
   }

   function onClickNavLink(e, slug) {
      //console.log(`onClickNavLink: slug=${slug}`);
      //ArticleCallbacks.handleOnArticleClick(null, article.slug, db, dispatch, navigate);
      // navigate("/article/" + article.slug);
      ArticleCallbacks.handleOnArticleClick(article.slug, navigate);
   }

   function createFeaturedImage(seq, url, filename) {
      return <img key="featuredImage" src={url} alt={filename}></img>;
   }

   function createImageFromSeqArray(article, imgSeq) {
      let img;
      if (article.imageSeqArray) {
         for (let i = 0; i < article.imageSeqArray.length; i++) {
            if (article.imageSeqArray[i].seq === imgSeq) {
               img = article.imageSeqArray[i];
               break;
            }
         }
      }
      return img
         ? createFeaturedImage(img.seq, img.base64data, img.filename)
         : "";
   }

   return (
      <Card
         key="ImageOfMomentCard"
         className="ImageOfMomentCard"
         headerIcon={<FileImageOutlined className="headerIcon" />}
         titleText={CardType.IMAGE_OF_MOMENT_CARD}
         onMenuIconClick={onMenuClick}
         onMoreClick={onClickNavLink}
      >
         <div>
            {createImageFromSeqArray(article, imgSeq)}
            <p className="summary">{article.summary}</p>
         </div>
      </Card>
   );
}

