import React, { useEffect, useState, useCallback } from "react";
import {
   FormOutlined,
   MinusCircleOutlined,
   PlusSquareOutlined,
   PrinterOutlined,
   StarFilled,
   StarOutlined,
   PartitionOutlined,
} from "@ant-design/icons";
import { ExclamationCircleFill } from "antd-mobile-icons";
import { Divider, Menu } from "antd";
import { v4 as uuidv4 } from "uuid";
import ActionSheetMenu from "./ActionSheetMenu";
import { ReactComponent as HamburglerIcon } from "../icons/hamburgler-icon.svg";
import { ReactComponent as ShareIcon } from "../icons/share-apple.svg";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toggleFavorite } from "../utils/SettingsUtils";
import DbRefreshUtils from "../utils/DbRefreshUtils";
import {
   callDeleteCategoryAPI,
   callDeleteArticleAPI,
   callDeleteRelationsAPI,
   callDeleteImagesAPI,
   callDeleteSettingFavoriteAPI,
} from "../utils/BackendAPI";
import CategoryCallbacks from "../utils/CategoryCallbacks";
import { Modal } from "antd-mobile";
import { Toast } from "antd-mobile";
import {
   setPageActionSheetVisible,
   updateShareMenu,
} from "../features/mainPageSlice";
import { useLocalDataStore } from "../utils/UseLocalDataStore";

import "./MainPageActionSheetMenu.css";

export default function PageActionSheetMenu({ title }) {
   const confirm = Modal.confirm;

   const location = useLocation();
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const db = useLocalDataStore();

   // const article = useSelector((state) => state.article);
   // const category = useSelector((state) => state.category);

   const settings = useSelector((state) => state.settings);
   const currentUser = useSelector((state) => state.account);
   const visible = useSelector(
      (state) => state.mainPage.mainSearch.pageActionSheetVisible
   );
   const online = useSelector((state) => state.mainPage.online);
   const appName = useSelector((state) => state.mainPage.appName);
   const [categoryHasDependents, setCategoryHasDependents] = useState(false);

   // console.log(
   //    `DEBUG: MainPageActionSheetMenu: location.pathname=${location.pathname}, currentUser.isEditor=${currentUser.isEditor}`
   // );

   // function getProbableSlug(url) {
   //    return url.substring(url.lastIndexOf("/") + 1);
   // }

   const getProbableSlug = useCallback((url) => {
      return url.substring(url.lastIndexOf("/") + 1)
   }, []);

   useEffect(() => {
      async function checkCategoryDependencies() {
         const slug = getProbableSlug(location.pathname);
         let count = await CategoryCallbacks.getDependencyCountForCategorySlug(
            slug,
            db
         );
         setCategoryHasDependents(count);
      }

      if (visible && location.pathname.startsWith("/category/")) {
         checkCategoryDependencies();
      }
   }, [visible, location, db, getProbableSlug, location.pathname]);

   function createDeleteAlert(item, onDelete) {
      confirm({
         header: (
            <ExclamationCircleFill
               style={{
                  fontSize: 64,
                  color: "var(--adm-color-warning)",
               }}
            />
         ),
         title: "Delete",
         content: `Are you sure you want to delete ${item.name}?`,
         confirmText: "Yes Delete",
         cancelText: "",
         showCloseButton: true,
         onConfirm: () => {
            onDelete(item);
         },
      });
   }

   function createCategoryItems() {
      let items = [];

      if (location.pathname === "/categories" && currentUser.isEditor) {
         items.push(
            <Menu.Item
               key={uuidv4()}
               disabled={!online}
               icon={<PlusSquareOutlined />}
               onClick={(e) => onNewTopCategoryClick()}
            >
               New Top Category
            </Menu.Item>
         );
      }

      if (location.pathname.startsWith("/category/") && currentUser.isEditor) {
         items.push(
            <Menu.Item
               key={uuidv4()}
               disabled={!online}
               icon={<FormOutlined />}
               onClick={(e) =>
                  onNewSubcategoryClick(
                     e,
                     location.pathname /*~~~, category.slug*/
                  )
               }
            >
               New Subcategory
            </Menu.Item>
         );

         items.push(
            <Menu.Item
               key={uuidv4()}
               disabled={!online}
               icon={<FormOutlined />}
               onClick={(e) =>
                  onCategoryEditClick(e, location.pathname /*category.slug*/)
               }
            >
               Edit Category
            </Menu.Item>
         );
      }

      if (
         location.pathname.startsWith("/category/") &&
         categoryHasDependents &&
         currentUser.isEditor
      ) {
         items.push(
            <Menu.Item
               key={uuidv4()}
               disabled={!online}
               icon={
                  <HamburglerIcon
                     className={
                        online ? "hamburgler-icon" : "hamburgler-icon disabled"
                     }
                  />
               }
               onClick={(e) =>
                  onCategoryResequence(location.pathname /*~~~ category*/)
               }
            >
               Resequence Category Articles
            </Menu.Item>
         );
      }

      if (
         location.pathname.startsWith("/category/") &&
         !categoryHasDependents &&
         currentUser.isAdministrator
      ) {
         items.push(
            <Menu.Item
               key={uuidv4()}
               disabled={!online}
               icon={<MinusCircleOutlined />}
               onClick={(e) => onCategoryDelete(location.pathname)}
            >
               Delete Category
            </Menu.Item>
         );
      }

      return items;
   }

   function loadCategoryItems() {
      let items = createCategoryItems();
      if (items && items.length > 0) {
         return [<Divider key="divider2" />, ...items];
      } else {
         return [];
      }
   }

   function loadFavoritesItems() {
      let items = [];

      const url = location.pathname;
      const slug = getProbableSlug(url);

      //console.log(`DEBUG: loadFavoritesItems: parsed slug=${slug}`);

      if (slug) {
         if (
            (location.pathname.startsWith("/article/") &&
               !settings.favorites.includes(slug)) ||
            (location.pathname.startsWith("/category/") &&
               !settings.favorites.includes(slug))
         ) {
            items.push(
               <Menu.Item
                  key={uuidv4()}
                  disabled={!online}
                  icon={<StarOutlined />}
                  onClick={(e) => {
                     onFavoritesClick(slug, currentUser, settings, online);
                  }}
               >
                  Add to Favorites
               </Menu.Item>
            );
         }

         if (
            (location.pathname.startsWith("/article/") &&
               settings.favorites.includes(slug)) ||
            (location.pathname.startsWith("/category/") &&
               settings.favorites.includes(slug))
         ) {
            items.push(
               <Menu.Item
                  key={uuidv4()}
                  disabled={!online}
                  icon={<StarFilled />}
                  onClick={(e) => {
                     onFavoritesClick(slug, currentUser, settings, online);
                  }}
               >
                  Remove from Favorites
               </Menu.Item>
            );
         }
      }

      return items;
   }

   function createArticleItems() {
      let items = [];

      //~~~      if (category && category.slug) {
      if (location.pathname.startsWith("/category/") && currentUser.isEditor) {
         items.push(
            <Menu.Item
               key={uuidv4()}
               disabled={!online}
               icon={<PlusSquareOutlined />}
               onClick={(e) => onNewArticleClick(location.pathname)}
            >
               New Article
            </Menu.Item>
         );
      }
      //~~~      }

         if (
            location.pathname.startsWith("/article/") &&
            currentUser.isEditor
         ) {
            items.push(
               <Menu.Item
                  key={uuidv4()}
                  disabled={!online}
                  icon={<FormOutlined />}
                  onClick={(e) => onArticleEditClick(e, location.pathname)}
               >
                  Edit Article
               </Menu.Item>
            );
         }

         if (
            location.pathname.startsWith("/article/") &&
            currentUser.isAdministrator
         ) {
            items.push(
               <Menu.Item
                  key={uuidv4()}
                  disabled={!online}
                  icon={<MinusCircleOutlined />}
                  onClick={(e) => onArticleDelete(location.pathname)}
               >
                  Delete Article
               </Menu.Item>
            );
         }
      return items;
   }

   function loadArticleItems() {
      let items = createArticleItems();
      if (items && items.length > 0) {
         return [<Divider key="divider3" />, ...items];
      } else {
         return [];
      }
   }

   function onPageActionClose(e) {
      //console.log("Page action sheet closed");
      dispatch(setPageActionSheetVisible(false));
   }
   function onPrintPage(e) {
      dispatch(setPageActionSheetVisible(false));
      setTimeout(() => {
         window.print();
      }, 400);
   }
   function onNewTopCategoryClick() {
      console.log("onNewTopCategoryClick");
      dispatch(setPageActionSheetVisible(false));
      //dispatch(updateCategoryEditorTopCategory(true));
      navigate("/admin/category/new");
   }
   function onNewSubcategoryClick(e, url) {
      const categorySlug = getProbableSlug(url);

      console.log(`onNewSubcategoryClick: parent=${categorySlug}`);
      dispatch(setPageActionSheetVisible(false));
      //dispatch(updateCategoryEditorTopCategory(false));
      //dispatch(setCategoryEditorParentSlug(categorySlug));
      navigate("/admin/category/" + categorySlug + "/new");
   }
   function onNewArticleClick(url) {
      // e.preventDefault();
      const categorySlug = getProbableSlug(url);
      console.log(`onNewArticleClick, category=${categorySlug}`);
      dispatch(setPageActionSheetVisible(false));
      //dispatch(resetArticleFormToDefaults());
      navigate(`/admin/article/${categorySlug}/new`);
   }
   function onCategoryEditClick(e, url) {
      //console.log("onCategoryEditClick() slug=", slug);
      console.log(`onCategoryEditClick() url=${url}`);

      dispatch(setPageActionSheetVisible(false));
      navigate("/admin" + url);

      // db.getCategory(slug, (category) => {
      //    db.getDependencyCountForCategorySlug(slug)
      //       .then((count) => {
      //          let hasDependents = count > 0;
      //          dispatch(
      //             updateCategoryFormValues(
      //                EditorMode.EDIT,
      //                category.slug,
      //                category.name,
      //                category.seq,
      //                "",
      //                "",
      //                hasDependents,
      //                category.imageUrlArray,
      //                category.parent
      //             )
      //          );
      //          navigate("/admin/category/" + slug);
      //       })
      //       .catch((error) => {
      //          console.log("Error handling category edit click: ", error);
      //       });
      // });
   }
   function onCategoryResequence(url) {
      //console.log(`onCategoryResequence() url=${url}`);
      dispatch(setPageActionSheetVisible(false));
      navigate("/admin" + url + "/resequence");
   }

   async function onCategoryDelete(url) {
      console.log(`onCategoryDelete() called, url=${url}`);

      dispatch(setPageActionSheetVisible(false));
      const slug = getProbableSlug(url);
      const category = await db.getCategory(slug);

      createDeleteAlert(category, async (category) => {
         Toast.show({ content: "Deleting...", duration: 30000, icon: "loading" });

         let response = await callDeleteCategoryAPI(category.slug);
         try {
            console.log(
               `Response from DELETE CATEGORY ${category.slug}`,
               response.data
            );

            let result = await callDeleteSettingFavoriteAPI(category.slug);
            console.log(
               `Response from DELETE FAVORITE ${category.slug}`,
               result.data
            );

            // resync because there could be add and remove
            DbRefreshUtils.processDbSync(db)
               .then((result) => {
                  console.log("Result from forced DbRefreshUtils: ", result);

                  navigate(-1);

                  // db.fillBrowseCategoriesArray((browseCategoriesArray) => {
                  //    Toast.clear();

                  //    dispatch(fetchCategoriesList(browseCategoriesArray));
                  //    navigate(-1);
                  //    dispatch(resetCategoryFormToDefaults());
                  // });
               })
               .catch((error) => {
                  console.log("Error resyncing with DB API backend: ", error);
               });
         } catch (error) {
            console.log(error);
         }
      });
   }

   function onArticleEditClick(e, url) {
      //console.log("edit article clicked!");

      dispatch(setPageActionSheetVisible(false));
      // ArticleCallbacks.loadFullArticleData(
      //    slug,
      //    db,
      //    (article, relationArray) => {
      //       let imageUrlArray = [];
      //       if (
      //          article.imageSeqArray != null &&
      //          article.imageSeqArray.length > 0
      //       ) {
      //          article.imageSeqArray.forEach((i) => {
      //             imageUrlArray.push({
      //                seq: i.seq,
      //                url: i.base64data,
      //                orientation: 1,
      //                filename: i.filename,
      //                file: null,
      //             });
      //          });
      //       }

      //       let audioString = "";
      //       article.audioUrlArray.forEach((u) => {
      //          audioString = audioString + "\n" + u;
      //       });

      //       let videoString = "";
      //       article.videoUrlArray.forEach((u) => {
      //          videoString = videoString + "\n" + u;
      //       });

      //       audioString = audioString.trim();
      //       videoString = videoString.trim();

      // dispatch(
      //    updateArticleFormValuesAll(
      //       EditorMode.EDIT,
      //       article,
      //       imageUrlArray,
      //       relationArray,
      //       audioString,
      //       videoString
      //    )
      // ); 
      const slug = getProbableSlug(url);
      navigate(`/admin/article/${slug}`);
   }

   async function onArticleDelete(url) {
      //console.log("onDelete() called, slug=" + article.slug);

      dispatch(setPageActionSheetVisible(false));
      const slug = getProbableSlug(url);
      const article = await db.getArticle(slug);
      createDeleteAlert(article, (article) => {
         Toast.show({ content: "Deleting...", duration: 30000, icon: "loading" });

         let promises = [];

         promises.push(callDeleteImagesAPI(article.imageSeqArray));
         promises.push(callDeleteArticleAPI(article.slug));
         promises.push(callDeleteRelationsAPI(article.slug));
         promises.push(callDeleteSettingFavoriteAPI(article.slug));

         Promise.all(promises).then((r) => {
            //console.log("DEBUG: delete article promises have resolved. Resyncing db now...");

            // resync because there could be add and remove
            DbRefreshUtils.processDbSync(db).then(async (result) => {
               // CategoryCallbacks.loadFullCategoryDataPromise(
               //    article.category,
               //    db
               // );

               // CategoryCallbacks.handleOnCategoryClick(
               //    null,
               //    article.category,
               //    ownProps.db,
               //    dispatch,
               //    null
               // );

               Toast.clear();

               //console.log("Result from forced DbRefreshUtils: ", result);
               navigate(-1);
               //dispatch(resetArticleFormToDefaults());
            });
         }).catch((err)=>{
            Toast.show({ content: err, duration: 4000, icon: "fail" });
         });
      });
   }
   async function onMasterIndexClick(e) {
      dispatch(setPageActionSheetVisible(false));
      Toast.show({ content: "Retrieving...", duration: 30000, icon: "loading" });

      navigate("/master-index/");
   }

   function onFavoritesClick(slug, currentUser, settings, online) {
      toggleFavorite(slug, settings, currentUser, db, dispatch, online);
      dispatch(setPageActionSheetVisible(false));
   }

   async function onSharePage() {
      //console.log("onSharePage clicked");
      dispatch(setPageActionSheetVisible(false));

      const fullUrl = window.location.href;

      let title;
      const slug = getProbableSlug(fullUrl);

      if (fullUrl.includes("article")) {
         const article = await db.getArticle(slug);
         title = article.name;
      } else if (fullUrl.includes("category")) {
         const category = await db.getCategory(slug);
         title = category.name;
      } else {
         title = appName;
      }

      console.log(`DEBUG: onSharePage: fullUrl=${fullUrl}, slug=${slug}, title=${title}`);

      if (navigator && navigator.share) {
         try {
            Toast.show({
               content: "Share successful!",
               duration: 4000,
               icon: "loading",
            });

            await navigator.share({
               title: title,
               text: title,
               url: fullUrl,
            });
         } catch (err) {
            if (!err.includes("AbortError")) {
               Toast.show({
                  content: `Share failed ${err}`,
                  duration: 5000,
                  icon: "fail",
               });
            } else {
               console.log("Share link failed!", err);
            }
         }
      } else {
         dispatch(updateShareMenu({ visible: true, title: title, url: fullUrl }));
      }
   }

   return (
      <ActionSheetMenu
         title={title}
         className="MainPageActionSheetMenu"
         visible={visible}
         onCloseClick={onPageActionClose}
      >
         {/* <p className="title">Page Actions</p> */}
         <Divider key="divider1" />
         <Menu>
            <Menu.Item
               key={uuidv4()}
               icon={<PrinterOutlined />}
               onClick={(e) => onPrintPage(e)}
            >
               Print
            </Menu.Item>

            <Menu.Item
               key={uuidv4()}
               icon={<ShareIcon className="ShareIcon" />}
               onClick={(e) => onSharePage(location.pathname)}
            >
               Share
            </Menu.Item>

            <Menu.Item
               key={uuidv4()}
               icon={<PartitionOutlined />}
               onClick={(e) => onMasterIndexClick(e)}
            >
               Master Index
            </Menu.Item>

            {loadFavoritesItems()}

            {loadCategoryItems()}

            {loadArticleItems()}
         </Menu>
      </ActionSheetMenu>
   );
}
