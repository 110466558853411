import React, {useState} from "react";
import { useNavigate, useLoaderData } from "react-router-dom";
import { List } from "antd-mobile";
import AlphabetList from "../components/AlphabetList";
import ArticleListItem from "../components/ArticleListItem";
import ArticleUtils from "../utils/ArticleUtils";
import ArticleCallbacks from "../utils/ArticleCallbacks";
import CategoryCallbacks from "../utils/CategoryCallbacks";
import NoResults from "../components/NoResults";
import {getDB} from "../utils/UseLocalDataStore";
import { Toast } from "antd-mobile";

import "./MasterIndexPage.css";

// const sample = {
//    "A":[
//       {slug:"aleph", name:"Aleph", type:"A", alias:""},
//       {slug:"aloof", name:"Aloof", type:"A", alias:""},
//       {slug:"alottment", name:"Alottment", type:"A", alias:""},
//    ],
//    "B":[
//       {slug:"brontes", name:"Brontes", type:"A", alias:""},
//       {slug:"books", name:"Books", type:"C", alias:""},
//       {slug:"baristers", name:"Baristers", type:"A", alias:"Jennifer"},
//    ],
//    "C":[]
// };

/* default data loader for this page */
export async function loader() {
   console.log(`MasterIndexPage: loader() called`);

   const db = getDB();
   let flat = await db.queryAllArticleCategories();
   let masterIndexMap = ArticleUtils.createMasterIndexMap(flat);

   Toast.clear();

   return masterIndexMap;
}

export default function MasterIndexPage() {
   const navigate = useNavigate();

   const masterIndexMap = useLoaderData();
   const [filter, setFilter] = useState("");
   const [magLensYCoordPx,setMagLensYCoordPx] = useState(10000);


   // const masterIndexMap = useSelector(
   //    (state) => state.masterIndexPage.masterIndexMap
   // );
   
   // const filter = useSelector((state) => state.masterIndexPage.filter);
   
   // const magLensYCoordPx = useSelector(
   //    (state) => state.masterIndexPage.magLensYCoordPx
   // );

   const buzz_duration_ms = 100;
   const alph = ArticleUtils.lettersAndNumbersArray();
   const lensRef = React.createRef();
   const refMap = alph.reduce((acc, value) => {
      acc[value] = React.createRef();
      return acc;
   }, {});

   // const scrollToRef = (ref) => {
   //       //document.getElementsByClassName('am-drawer-content').scrollTop = ref.current.offsetTop;
   // }

   function onIndexClick(e, letter) {
      console.log("onIndexClick", letter);
      e.preventDefault();
      e.stopPropagation();
      e.bubbles = false;

      setFilter(letter);
      setMagLensYCoordPx(e.target.getBoundingClientRect().top);

      // // dispatch(
      // //    applyMasterIndexFilter({
      // //       filter: letter,
      // //       magLensYCoordPx: e.target.getBoundingClientRect().top,
      // //    })
      // );

      if (window.navigator.vibrate) {
         window.navigator.vibrate(buzz_duration_ms);
      }
   }

   function onItemClick(e, item, master) {
      console.log("onItemClick", item);
      e.preventDefault();
      e.stopPropagation();
      e.bubbles = false;
      if (item.type === "A") {
         ArticleCallbacks.handleOnArticleClick(item.slug, navigate);
         //navigate("/article/" + item.slug);
      } else {
         CategoryCallbacks.handleOnCategoryClick(item.slug, navigate);
         //navigate("/category/" + item.slug);
      }
   }

   function IndexList({
      letter,
      content,
      reference,
      onItemClick,
      warnOnEmpty = true,
   }) {
      return (
         <>
            <span key={`hidden-${letter}`} ref={reference} className="hidden"></span>
            <List key={letter} header={letter}>
               {content &&
                  content.map((r) => (
                     <ArticleListItem
                        key={r.slug}
                        item={r}
                        onClick={onItemClick}
                        master={undefined}
                        showCategoryName={false}
                        showThumbnail={false}
                        showSummary={true}
                        showArrow={false}
                     />
                  ))}

               {/* <SimpleArticleListItem
                     key={uuidv4()}
                     item={r}
                     master={undefined}
                     onClick={onItemClick}
                     showCategoryName={false}
                  /> */}

               {(content === undefined || content.length === 0) && warnOnEmpty
                  ? ({
                       /* <Empty description="No items have been created yet" /> */
                    },
                    (
                       <NoResults instructions="No items have been created yet" />
                    ))
                  : ""}
            </List>
         </>
      );
   }

   return (
      <div className="MasterIndexPage">
         <div
            className="mag-lens"
            ref={lensRef}
            style={{ display: "none", top: magLensYCoordPx + "px" }}
         >
            {filter}
         </div>
         <AlphabetList
            includeNumbers={true}
            onIndexClick={(e, letter) => {
               onIndexClick(e, letter);
               lensRef.current.style.display = "block";
            }}
            // onTouchMove={(e, letter) => {
            //    onTouchMove(e, letter);
            //    lensRef.current.textContent=letter;
            //    lensRef.current.style.top = e.target.getBoundingClientRect().top + "px";
            //    // scrollToRef(refMap[letter]);
            // }}
         />

         {filter ? (
            <IndexList
               letter={filter}
               content={masterIndexMap[filter]}
               reference={refMap[filter]}
               onItemClick={onItemClick}
            />
         ) : (
            alph.map((a) => (
               <IndexList
                  key={`index-${a}`}
                  letter={a}
                  content={masterIndexMap[a]}
                  reference={refMap[a]}
                  onItemClick={onItemClick}
                  warnOnEmpty={false}
               />
            ))
         )}
      </div>
   );
}
