class ShmitaCalendar {
    static #epochYearGregorian =  1965;
    static #ShmitaYearCount = 7;
 
    static isGregorianYearShmita(gregorianYear) {
       if (gregorianYear >= this.#epochYearGregorian) {
          return (
             (gregorianYear - this.#epochYearGregorian) %
                this.#ShmitaYearCount ===
             0
          );
       } else {
          if (this.#epochYearGregorian > gregorianYear) {
             return (
                (this.#epochYearGregorian - gregorianYear) %
                   this.#ShmitaYearCount ===
                0
             );
          }
       }
    }
 }
 
 export default ShmitaCalendar;
 