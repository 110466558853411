import { createSlice } from "@reduxjs/toolkit";

export const CalendarCardInitialState = {
    hebdate: undefined,
    image: undefined,
    monthName: "",
 };
 
export const CardExplorerPageInitialState = {
//    img2PopupVisible: undefined,
//    img3PopupVisible: undefined,
   articleOfDay: {},
   imageOfDayArticle: {},
   imageOfDaySeq: undefined,
   whatsNewArticleList: [],
   calendarCard: CalendarCardInitialState,
   favoritesItemList: [],
};

export const cardExplorerPageSlice = createSlice({
   name: "cardExplorerPage",
   initialState: CardExplorerPageInitialState,
   reducers: {
      reset: () => {
         return CardExplorerPageInitialState;
      },
    //   toggleArticlePopupVisible: (state, action) => {
    //      state.img2PopupVisible =
    //         action.payload.variable === "img2PopupVisible"
    //            ? !state.img2PopupVisible
    //            : state.img2PopupVisible;

    //      state.img3PopupVisible =
    //         action.payload.variable === "img3PopupVisible"
    //            ? !state.img3PopupVisible
    //            : state.img3PopupVisible;
    //   },
      setArticleOfDay: (state, action) => {
         state.articleOfDay = action.payload;
      },
      setImageOfDayArticle: (state, action) => {
         state.imageOfDayArticle = action.payload.article;
         state.imageOfDaySeq = action.payload.seq;
      },
      setWhatsNewArticleList: (state, action) => {
        state.whatsNewArticleList = action.payload;
     },
     setCalendarCard: (state, action) => {
        state.calendarCard.hebdate = action.payload.hebdate;
        state.calendarCard.image = action.payload.image;
        state.calendarCard.monthName = action.payload.monthName;
     },
     setFavoritesCardData: (state, action) => {
        state.favoritesItemList = action.payload;
     },

  },
});

export const {
   reset,
//    toggleArticlePopupVisible,
   setArticleOfDay,
   setImageOfDayArticle,
   setWhatsNewArticleList,
   setCalendarCard,
   setFavoritesCardData,
} = cardExplorerPageSlice.actions;

export default cardExplorerPageSlice.reducer;
