const DimensionMap = {
    1: { "hebrew":"Orek", "meaning":"Length", },
    2: { "hebrew":"Gobahh", "meaning":"Height", },
    3: { "hebrew":"Rochab", "meaning":"Depth", },
    4: { "hebrew":"Az", "meaning":"Time", },
    5: { "hebrew":"Yehovah", "meaning":"The Lord", },
    6: { "hebrew":"Chokmah", "meaning":"Wisdom", },
    7: { "hebrew":"Biynah", "meaning":"Understanding", },
    8: { "hebrew":"Etsah", "meaning":"Counsel", },
    9: { "hebrew":"G'buwrah", "meaning":"Might", },
    10: { "hebrew":"Da'ath", "meaning":"Knowledge", },
    11: { "hebrew":"Yir'ah", "meaning":"Fear of the Lord", },
    12: { "hebrew":"Zacar", "meaning":"Remembrance", },
};

export default DimensionMap;